import { useEffect, useState } from 'react';

import { TextField, Grid } from '@mui/material';
import { useField } from 'formik';

import { useAppSelector } from 'src/utils/hooks';

import TooltipReusable from 'src/components/TooltipReusable';
import QuestionLayout from './QuestionLayout';

interface Props {
  question: Question;
}

export default function TextQuestion({ question }: Props) {
  const [orderIbanField] = useField('orderIban');
  const step = useAppSelector((state) => state?.stepper.steps);

  const [{ value, ...field }, meta, helper] = useField(
    question.key.replace(/\./g, '&').replace(/\[\]/g, '$')
  );

  const [isDisabled, setIsDisabled] = useState(question.readonly);

  useEffect(() => {
    if (step == 1 && question.key === 'orderBankName') {
      const isForeignIban = orderIbanField.value.substring(0, 2) !== 'DE';
      const isEmptyIban = orderIbanField.value.length === 0;

      if (isForeignIban) {
        setIsDisabled(isEmptyIban);
        if (!isEmptyIban) {
          helper.setTouched(false);
          helper.setValue('');
        }
      } else {
        setIsDisabled(true);
      }
    }
  }, [orderIbanField.value]);

  return (
    <QuestionLayout question={question} meta={meta}>
      <Grid item minWidth={'100%'}>
        <TextField
          error={meta.touched && Boolean(meta.error)}
          value={value || question?.answer?.value}
          {...field}
          hiddenLabel
          fullWidth
          disabled={isDisabled}
          variant="filled"
        />
      </Grid>
      <Grid item paddingLeft={'20px'}>
        {question.answer.prefilled && !question.readonly && (
          <TooltipReusable
            title={[
              'Diese Angabe wurde aus Ihren vorherigen Angaben übernommen. Bitte überprüfen Sie diese auf Korrektheit.',
            ]}
            iconFlag={false}
          />
        )}
      </Grid>
    </QuestionLayout>
  );
}
