import React, { useEffect, useState } from 'react';

import {
  FormControlLabel,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Box,
  Grid,
  Tooltip,
  IconButton,
  Rating,
  CircularProgress,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import CustomInfoIcon from 'src/asserts/CustomInfo';

import {
  selectOption,
  unselectOption,
} from '../../store/reducers/contribution.reducer';
import {
  addInsruanceForRiskCheck,
  clearAllSelectedInsurancesForPerson,
  removeInsuranceForRiskCheck,
} from 'src/store/reducers/riskCheck.reducer';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';

import { formatNumber } from 'src/utils/numberFormater';
import mandants from 'src/config/mandant';

interface InsuranceInfoToolTipContentProps {
  title: string[];
  tarrifId: string;
  personIdx: number;
}
const InsuranceInfoToolTipContent = ({
  title,
  tarrifId,
  personIdx,
}: InsuranceInfoToolTipContentProps) => {
  const persons = useAppSelector((state) => state.contribution.persons);
  const ratingValues: RatingValues = {
    premium: 5,
    comfort: 4,
    base: 3,
    none: 0,
  };

  const ratingText = {
    premium: 'Premium',
    comfort: 'Komfort',
    base: 'Basis',
    none: 'Nicht bewertet',
  };

  return (
    <>
      {personIdx !== undefined &&
        tarrifId &&
        persons[personIdx].ratings[tarrifId] !== null &&
        persons[personIdx]?.ratings[tarrifId]?.map(
          (rating: any, index: number) => (
            <Grid container key={index}>
              {index === 0 && (
                <Typography variant="h6" color="primary" paddingBottom={1}>
                  Tarifqualität
                </Typography>
              )}
              <Grid item xs={7}>
                {rating?.questionSmallTitle}
              </Grid>
              <Grid item xs={3}>
                <Rating
                  name="read-only"
                  readOnly
                  value={
                    ratingValues[
                      rating?.selectedAnswer
                        ?.answerId as keyof typeof ratingValues
                    ]
                  }
                />
              </Grid>
              <Grid item xs={2}>
                {
                  ratingText[
                    rating?.selectedAnswer?.answerId as keyof typeof ratingText
                  ]
                }
              </Grid>
            </Grid>
          )
        )}
      {personIdx !== undefined && persons[personIdx].loading && (
        <Box display="flex" justifyContent="center" paddingY={3}>
          <CircularProgress />
        </Box>
      )}
      {personIdx !== undefined && tarrifId && (
        <Typography variant="h6" color="primary" paddingTop={1}>
          Leistungskurzbeschreibung
        </Typography>
      )}
      <ul style={{ padding: '0', listStyle: 'none' }}>
        {title.map((item, index) => (
          <li key={index}>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: item
                  .replace(/\n/g, '<br>')
                  .replace(/"/g, '&quot;')
                  .replace(/'/g, '&#039;'),
              }}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

interface InsuranceInfoToolTipProps extends InsuranceInfoToolTipContentProps {}

export const InsuranceInfoToolTip: React.FC<InsuranceInfoToolTipProps> = ({
  title,
  tarrifId,
  personIdx,
}) => {
  return (
    <Tooltip
      title={
        <InsuranceInfoToolTipContent
          title={title}
          tarrifId={tarrifId}
          personIdx={personIdx}
        />
      }
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '1em',
            padding: '30px',
            minWidth: '500px',
            borderRadius: '0px',
            color: 'gray',
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            marginBottom: '50px',
            overflowWrap: 'break-word',
            fontWeight: '500px',
            lineHeight: 1.5,
          },
        },
      }}
    >
      <IconButton>
        <CustomInfoIcon
          sx={{
            color: (theme) =>
              theme.palette?.customInfoIconColor?.main || 'primary.main',
          }}
          fill={(theme) => theme.palette?.customInfoIconColor?.text || 'white'}
        />
      </IconButton>
    </Tooltip>
  );
};

interface Props {
  expanded: boolean;
  array: {
    title: string;
    total: number;
    options: ContributionOption[];
  };
  idx: number;
  personIdx: number;
  optionsLenght: number;
  changeDetected?: any;
  setIsChangeDetected?: any;
}
export default function InsuranceInfo({
  array,
  expanded,
  idx,
  personIdx,
  optionsLenght,
  changeDetected,
  setIsChangeDetected,
}: Props) {
  const dispatch = useAppDispatch();
  const [expand, setexpand] = useState<boolean>(expanded);
  const [numComponents, setNumComponents] = useState<number[]>([1]);
  const { mandant } = useAppSelector((state) => state.config);
  const exclusiveSelectionPerCategory: boolean =
    mandants[mandant as string]?.exclusiveSelectionPerCategory;

  useEffect(() => {
    if (!changeDetected.change) return;

    // Clear previous selections in Redux before processing new ones
    dispatch(
      clearAllSelectedInsurancesForPerson({
        personID: personIdx,
      })
    );

    array.options.forEach((item, index) => {
      if (item.selected) {
        dispatch(
          removeInsuranceForRiskCheck({
            id: item?.id,
            personID: personIdx,
          })
        );

        dispatch(
          unselectOption({
            personIdx: personIdx,
            optionIdx: index,
            resIdx: idx,
            amount: item?.fee,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (optionsLenght === 1) {
      dispatch(
        selectOption({
          personIdx,
          optionIdx: 0,
          resIdx: idx,
          amount: array?.options[0]?.fee,
        })
      );
      dispatch(
        addInsruanceForRiskCheck({
          id: array?.options[0]?.id,
          personID: personIdx,
          option: {
            ...array?.options[0],
            orderAcceptedBenefitExclusion: null,
            orderAcceptedAdditionalFeeReason: null,
            orderAcceptedAdditionalFee: null,
            orderAcceptedDifferentFee: null,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleChange =
    () => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setexpand(newExpanded);
    };

  const isdisabled = (item: ContributionOption) => {
    if (!exclusiveSelectionPerCategory) {
      return false;
    }

    const hasAnotherSelectedOption = array.options.some(
      (option) => option.selected && option.id !== item.id
    );
    return hasAnotherSelectedOption;
  };

  return (
    <Accordion
      expanded={expand}
      onChange={handleChange()}
      sx={{
        boxShadow: 'none',
        margin: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
      >
        <Typography sx={{ color: 'primary.main' }} variant="h5">
          {array?.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <FormGroup sx={{ flexWrap: 'nowrap' }}>
          {array?.options?.map((item: ContributionOption, index: number) => {
            return (
              <FormControlLabel
                sx={{
                  margin: 0,
                  background: index % 2 ? 'transparent' : 'rgba(0, 0, 0, 0.06)',
                }}
                disableTypography
                key={item?.id}
                control={
                  <Checkbox
                    value={item?.displayString}
                    checked={item?.selected}
                    disabled={isdisabled(item)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        dispatch(
                          selectOption({
                            personIdx,
                            optionIdx: index,
                            resIdx: idx,
                            amount: item?.fee,
                          })
                        );
                        dispatch(
                          addInsruanceForRiskCheck({
                            id: item?.id,
                            personID: personIdx,
                            option: {
                              ...item,
                              orderAcceptedBenefitExclusion: null,
                              orderAcceptedAdditionalFeeReason: null,
                              orderAcceptedAdditionalFee: null,
                              orderAcceptedDifferentFee: null,
                            },
                          })
                        );
                        setIsChangeDetected({
                          change: false,
                          personId: personIdx,
                        });
                      } else {
                        dispatch(
                          unselectOption({
                            personIdx,
                            optionIdx: index,
                            resIdx: idx,
                            amount: item?.fee,
                          })
                        );
                        dispatch(
                          removeInsuranceForRiskCheck({
                            id: item?.id,
                            personID: personIdx,
                          })
                        );
                      }
                    }}
                  />
                }
                label={
                  <Grid container>
                    <Grid
                      container
                      item
                      sm={12}
                      xs={10}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item sm={4} xs={12} paddingY={1}>
                        {item?.insuranceName}
                      </Grid>
                      <Grid item sm={3} xs={12} paddingY={1}>
                        {item?.displayString}
                      </Grid>

                      <Grid item sm={2} display={{ xs: 'none', sm: 'initial' }}>
                        <InsuranceInfoToolTip
                          tarrifId={item?.tarrifId}
                          personIdx={personIdx}
                          key={item?.id}
                          title={Object.values(item?.descriptions)}
                        />
                      </Grid>

                      <Grid
                        item
                        sm={3}
                        xs={12}
                        paddingY={1}
                        fontStyle={'italic'}
                      >
                        {formatNumber(item?.fee)} EUR / Monat
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      justifyContent={'center'}
                      alignItems={'center'}
                      display={{ sm: 'none', xs: 'flex' }}
                    >
                      <InsuranceInfoToolTip
                        tarrifId={item?.tarrifId}
                        personIdx={personIdx}
                        key={item?.id}
                        title={Object.values(item?.descriptions)}
                      />
                    </Grid>
                  </Grid>
                }
              />
            );
          })}
          <Box>
            <Typography component={'span'} variant={'subtitle1'}>
              Beitrag ausgewählte(r) Tarif(e): {formatNumber(array?.total)} EUR
              / Monat
            </Typography>
          </Box>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
}
