interface bankTypes {
  [key: string]: string;
}
const BANK: bankTypes = {
  '10000000': 'Bundesbank',
  '10010010': 'Postbank Ndl der Deutsche Bank',
  '10010123': 'OLINDA Zweigniederlassung Deutschland',
  '10010200': 'Treezor, Berlin',
  '10010300': 'Klarna Bank German Branch',
  '10010424': 'Aareal Bank',
  '10010500': 'Noelse Pay',
  '10010700': 'Financiere des Paiements Electroniques NL Deutschland',
  '10011001': 'N26 Bank',
  '10014000': 'Swan Zweigniederlassung Deutschland',
  '10014001': 'Swan Zweigniederlassung Deutschland (Geschäftsfeld)',
  '10017997': 'Holvi Payment Services Zweigniederlassung Deutschland',
  '10020200': 'BHF-BANK',
  '10020500': 'Bank für Sozialwirtschaft',
  '10020510': 'Bank für Sozialwirtschaft',
  '10020520': 'Bank für Sozialwirtschaft',
  '10020890': 'UniCredit Bank - HypoVereinsbank',
  '10030200': 'Berlin Hyp',
  '10030400': 'ABK Allgemeine Beamten Bank',
  '10030500': 'M.M. Warburg & Co (vormals Bankhaus Löbbecke)',
  '10030600': 'North Channel Bank',
  '10030700': 'Eurocity Bank (Gf P2)',
  '10030730': 'Eurocity Bank Gf GAA',
  '10031000': 'EIS Einlagensicherungsbank',
  '10033300': 'Santander Consumer Bank',
  '10040000': 'Commerzbank, Filiale Berlin 1',
  '10040005': 'Commerzbank, Filiale Berlin 3',
  '10040010': 'Commerzbank, CC SP',
  '10040048': 'Commerzbank GF-B48',
  '10040060': 'Commerzbank Gf 160',
  '10040061': 'Commerzbank Gf 161',
  '10040062': 'Commerzbank CC',
  '10040063': 'Commerzbank CC',
  '10040085': 'Commerzbank, Gf Web-K',
  '10045050': 'Commerzbank Service-BZ',
  '10050000': 'Landesbank Berlin - Berliner Sparkasse',
  '10050005': 'Landesbank Berlin - E 1 -',
  '10050006': 'Landesbank Berlin - E 2 -',
  '10050007': 'Landesbank Berlin - E 3 -',
  '10050008': 'Landesbank Berlin - E 4 -',
  '10050020': 'S-Kreditpartner, Berlin',
  '10050500': 'LBS Ost Berlin',
  '10050999': 'DekaBank',
  '10060198': 'Pax-Bank',
  '10061006': 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
  '10070000': 'Deutsche Bank Gf P2',
  '10070024': 'Deutsche Bank',
  '10070100': 'Deutsche Bank',
  '10070124': 'Deutsche Bank',
  '10070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '10070397': 'Postbank/DSL Ndl der Deutsche Bank',
  '10070398': 'Postbank/DSL Ndl der Deutsche Bank',
  '10070399': 'Postbank/DSL Ndl der Deutsche Bank',
  '10070848': 'Deutsche Bank',
  '10071324': 'Postbank/DSL Ndl der Deutsche Bank',
  '10072324': 'Postbank/DSL Ndl der Deutsche Bank',
  '10077777': 'norisbank',
  '10080000': 'Commerzbank vormals Dresdner Bank Filiale Berlin I',
  '10080005': 'Commerzbank vormals Dresdner Bank Zw A',
  '10080006': 'Commerzbank vormals Dresdner Bank Zw B',
  '10080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '10080057': 'Commerzbank vormals Dresdner Bank Gf ZW 57',
  '10080085': 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 3',
  '10080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '10080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '10080088': 'Commerzbank vormals Dresdner Bank IBLZ',
  '10080089': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
  '10080900': 'Commerzbank vormals Dresdner Bank Filiale Berlin III',
  '10089260': 'Commerzbank vormals Dresdner Bank ITGK',
  '10089999': 'Commerzbank vormals Dresdner Bank ITGK 2',
  '10090000': 'Volksbank Potsdam Zndl d Berliner Volksbank',
  '10090300': 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
  '10090900': 'PSD Bank Berlin-Brandenburg (Gf P2)',
  '10110300': 'Westend Bank',
  '10110400': 'Investitionsbank Berlin',
  '10110600': 'Quirin Privatbank',
  '10120100': 'Weberbank',
  '10130600': 'Isbank Fil Berlin',
  '10130800': 'flatexDEGIRO Bank',
  '10220500': 'Lloyds Bank',
  '10220600': 'Sydbank Filiale Berlin',
  '10310600': 'Tradegate Wertpapierhandelsbank Berlin',
  '10310666': 'Tradegate Wertpapierhandelsbank, GF DIR',
  '11010100': 'solarisBank',
  '11010101': 'solarisBank Gf (S)',
  '11010111': 'solarisBank Gf (PNTA)',
  '12016836': 'KfW Kreditanstalt für Wiederaufbau',
  '12030000': 'Deutsche Kreditbank (Gf P2)',
  '12030900': 'Merck Finck A Quintet Private Bank',
  '12040000': 'Commerzbank Filiale Berlin 2',
  '12050555': 'ZVA Norddeutsche Landesbank Gf SA',
  '12060000': 'DZ BANK',
  '12070000': 'Deutsche Bank',
  '12070024': 'Deutsche Bank',
  '12070070': 'Deutsche Bank',
  '12070088': 'Deutsche Bank',
  '12070400': 'Postbank/DSL Ndl der Deutsche Bank',
  '12070424': 'Postbank/DSL Ndl der Deutsche Bank',
  '12080000': 'Commerzbank vormals Dresdner Bank Filiale Berlin II',
  '12096597': 'Sparda-Bank Berlin (Gf P2)',
  '13000000': 'Bundesbank',
  '13040000': 'Commerzbank',
  '13050000': 'Ostseesparkasse Rostock',
  '13051042': 'Sparkasse Vorpommern auf Rügen',
  '13061008': 'Volksbank Wolgast (Gf P2)',
  '13061078': 'Volks- und Raiffeisenbank (Gf P2)',
  '13061088': 'Raiffeisenbank Wismar -alt-',
  '13061128': 'Raiffeisenbank (Gf P2)',
  '13070000': 'Deutsche Bank',
  '13070024': 'Deutsche Bank',
  '13070405': 'Postbank/DSL Ndl der Deutsche Bank',
  '13070424': 'Postbank/DSL Ndl der Deutsche Bank',
  '13080000': 'Commerzbank vormals Dresdner Bank',
  '13090000': 'Rostocker Volks- und Raiffeisenbank (Gf P2)',
  '13091054': 'Pommersche Volksbank (Gf P2)',
  '13091084': 'Volksbank Wismar -alt-',
  '14040000': 'Commerzbank',
  '14051000': 'Sparkasse Mecklenburg-Nordwest',
  '14051362': 'Sparkasse Parchim-Lübz',
  '14051462': 'Sparkasse Schwerin -alt-',
  '14052000': 'Sparkasse Mecklenburg-Schwerin',
  '14061308': 'VR Bank Mecklenburg (Gf P2)',
  '14061438': 'Raiffeisen-Volksbank -alt-',
  '14080000': 'Commerzbank vormals Dresdner Bank',
  '14080011': 'Commerzbank vormals Dresdner Bank Zw W',
  '14091464': 'VR-Bank Schwerin',
  '15000000': 'Bundesbank',
  '15040068': 'Commerzbank',
  '15050100': 'Müritz-Sparkasse',
  '15050200': 'Sparkasse Neubrandenburg-Demmin',
  '15050400': 'Sparkasse Uecker-Randow Gs',
  '15050500': 'Sparkasse Vorpommern',
  '15051732': 'Sparkasse Mecklenburg-Strelitz Gs',
  '15061618': 'Raiffeisenbank Mecklenburger Seenplatte (Gf P2)',
  '15061638': 'Volksbank Vorpommern (Gf P2)',
  '15061698': 'Raiffeisenbank Malchin (Gf P2)',
  '15080000': 'Commerzbank vormals Dresdner Bank',
  '15091674': 'Volksbank Demmin (Gf P2)',
  '15091704': 'VR-Bank Uckermark-Randow (Gf P2)',
  '16010300': 'Investitionsbank des Landes Brandenburg',
  '16020086': 'UniCredit Bank - HypoVereinsbank',
  '16040000': 'Commerzbank',
  '16050000': 'Mittelbrandenburgische Sparkasse in Potsdam',
  '16050101': 'Sparkasse Prignitz',
  '16050202': 'Sparkasse Ostprignitz-Ruppin',
  '16050500': 'LBS Ostdeutsche Landesbausparkasse',
  '16060122': 'Volks- und Raiffeisenbank Prignitz (Gf P2)',
  '16061938': 'Raiffeisenbank Ostprignitz-Ruppin (Gf P2)',
  '16062008': 'VR-Bank Fläming-Elsterland (Gf P2)',
  '16062073': 'Brandenburger Bank (Gf P2)',
  '16080000': 'Commerzbank vormals Dresdner Bank',
  '16091994': 'Volksbank Rathenow (Gf P2)',
  '17020086': 'UniCredit Bank - HypoVereinsbank',
  '17040000': 'Commerzbank',
  '17052000': 'Sparkasse Barnim',
  '17052302': 'Stadtsparkasse Schwedt',
  '17054040': 'Kreissparkasse Märkisch-Oderland',
  '17055050': 'Sparkasse Oder-Spree',
  '17056060': 'Sparkasse Uckermark Gs',
  '17062428': 'Raiffeisenbank-Volksbank Oder-Spree (Gf P2)',
  '17080000': 'Commerzbank vormals Dresdner Bank',
  '17092404': 'VR Bank Fürstenwalde Seelow Wriezen (Gf P2)',
  '18020086': 'UniCredit Bank - HypoVereinsbank',
  '18040000': 'Commerzbank',
  '18050000': 'Sparkasse Spree-Neiße',
  '18051000': 'Sparkasse Elbe-Elster',
  '18055000': 'Sparkasse Niederlausitz',
  '18062678': 'VR Bank Lausitz (Gf P2)',
  '18062758': 'VR Bank Forst -alt-',
  '18080000': 'Commerzbank vormals Dresdner Bank',
  '18092684': 'Spreewaldbank (Gf P2)',
  '18092744': 'Volksbank Spree-Neiße (Gf P2)',
  '20000000': 'Bundesbank',
  '20010020': 'Postbank Ndl der Deutsche Bank',
  '20010424': 'Aareal Bank ehem. Filiale Hamburg',
  '20020200': 'Skandinaviska Enskilda Banken (publ) Hamburg Branch',
  '20020500': 'Jyske Bank Fil Hamburg',
  '20020900': 'Signal Iduna Bauspar',
  '20030000': 'UniCredit Bank - HypoVereinsbank',
  '20030133': 'Varengold Bank',
  '20030300': 'DONNER & REUSCHEL',
  '20030301': 'Donner + Reuschel GF NL LUX',
  '20030400': 'Marcard, Stein & Co Bankiers',
  '20030600': 'Sydbank Fil Hamburg',
  '20030700': 'Merck Finck A Quintet Private Bank',
  '20040000': 'Commerzbank',
  '20040005': 'Commerzbank, Filiale Hamburg 2',
  '20040020': 'Commerzbank, CC SP',
  '20040040': 'Commerzbank GF RME',
  '20040048': 'Commerzbank GF-H48',
  '20040050': 'Commerzbank GF COC',
  '20040060': 'Commerzbank Gf 260',
  '20040061': 'Commerzbank Gf 261',
  '20040062': 'Commerzbank CC',
  '20040063': 'Commerzbank CC',
  '20041111': 'Commerzbank - GF comdirect',
  '20041133': 'Commerzbank - GF comdirect',
  '20041144': 'Commerzbank - GF comdirect',
  '20041155': 'Commerzbank - GF comdirect',
  '20041166': 'Commerzbank - GF comdirect',
  '20041177': 'Commerzbank - GF comdirect',
  '20041188': 'Commerzbank - GF comdirect',
  '20041199': 'Commerzbank - GF comdirect',
  '20050000': 'Hamburg Commercial Bank, ehemals HSH Nordbank Hamburg',
  '20050550': 'Hamburger Sparkasse',
  '20060000': 'DZ BANK',
  '20069111': 'Norderstedter Bank (Gf P2)',
  '20069125': 'Kaltenkirchener Bank (Gf P2)',
  '20069130': 'Raiffeisenbank (Gf P2)',
  '20069144': 'Raiffeisenbank (Gf P2)',
  '20069177': 'Raiffeisenbank Südstormarn Mölln (Gf P2)',
  '20069232': 'Raiffeisenbank (Gf P2)',
  '20069641': 'Raiffeisenbank Owschlag (Gf P2)',
  '20069780': 'Volksbank Ahlerstedt (Gf P2)',
  '20069782': 'Volksbank Geest (Gf P2)',
  '20069786': 'Volksbank Kehdingen Zndl. der Ostfriesischen VB (Gf P2)',
  '20069800': 'Spar- und Kreditbank Hammah',
  '20069812': 'Volksbank Fredenbeck (Gf P2)',
  '20069815': 'Volksbank (Gf P2)',
  '20069861': 'Raiffeisenbank (Gf P2)',
  '20069882': 'Raiffeisenbank Travemünde (Gf P2)',
  '20069965': 'Volksbank Winsener Marsch (Gf P2)',
  '20069989': 'Volksbank Wulfsen (Gf P2)',
  '20070000': 'Deutsche Bank',
  '20070024': 'Deutsche Bank',
  '20070404': 'Postbank/DSL Ndl der Deutsche Bank',
  '20070424': 'Postbank/DSL Ndl der Deutsche Bank',
  '20080000': 'Commerzbank vormals Dresdner Bank',
  '20080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '20080057': 'Commerzbank vormals Dresdner Bank Gf ZW 57',
  '20080085': 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 2',
  '20080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
  '20080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '20080088': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '20080089': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
  '20080091': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
  '20080092': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
  '20080093': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
  '20080094': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
  '20080095': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
  '20089200': 'Commerzbank vormals Dresdner Bank ITGK',
  '20090400': 'DZ HYP',
  '20090500': 'Augsburger Aktienbank (netbank)',
  '20090700': 'Edekabank (Gf P2)',
  '20090745': 'EBANK Gf Cash',
  '20090900': 'PSD Bank Nord (Gf P2)',
  '20110022': 'Postbank Ndl der Deutsche Bank',
  '20110700': 'MUFG Bank (Europe) Germany Branch',
  '20110800': 'Bank of China Fil Hamburg',
  '20120000': 'Joh. Berenberg, Gossler & Co',
  '20120100': 'M.M. Warburg & CO',
  '20120200': 'BHF-BANK',
  '20120400': 'start:bausparkasse',
  '20120520': 'Bank für Sozialwirtschaft',
  '20120600': 'Goyer & Göppel',
  '20120700': 'Hanseatic Bank',
  '20130400': 'GRENKE BANK',
  '20130600': 'Barclays Bank Ireland Hamburg Branch (Gf P2)',
  '20133300': 'Santander Consumer Bank',
  '20190003': 'Hamburger Volksbank (Gf P2)',
  '20190077': 'Hamburger Volksbank (Gf GAA)',
  '20190109': 'Volksbank Raiffeisenbank (Gf P2)',
  '20190206': 'Volksbank Hamburg Ost-West -alt- (Gf P2)',
  '20190301': 'Vierländer Volksbank (Gf P2)',
  '20190800': 'MKB Mittelstandskreditbank (Gf P2)',
  '20210200': 'Bank Melli Iran',
  '20210300': 'Bank Saderat Iran',
  '20220100': 'DNB Bank ASA - Filiale Deutschland',
  '20220400': 'Warburg, M.M. - Hypothekenbank',
  '20220800': 'Banking Circle - German Branch',
  '20230300': 'Otto M. Schröder Bank',
  '20230600': 'Isbank Hamburg',
  '20230800': 'Sutor Bank',
  '20310300': 'Europäisch-Iranische Handelsbank',
  '20690500': 'Sparda-Bank Hamburg (Gf P2)',
  '20730001': 'UniCredit Bank - HVB Settlement EAC01',
  '20730002': 'UniCredit Bank - HVB Settlement EAC02',
  '20730003': 'UniCredit Bank - HVB Settlement EAC03',
  '20730004': 'UniCredit Bank - HVB Settlement EAC04',
  '20730005': 'UniCredit Bank - HVB Settlement EAC05',
  '20730006': 'UniCredit Bank - HVB Settlement EAC06',
  '20730007': 'UniCredit Bank - HVB Settlement EAC07',
  '20730008': 'UniCredit Bank - HVB Settlement EAC08',
  '20730009': 'UniCredit Bank - HVB Settlement EAC09',
  '20730010': 'UniCredit Bank - HVB Settlement EAC10',
  '20730011': 'UniCredit Bank - HVB Settlement EAC11',
  '20730012': 'UniCredit Bank - HVB Settlement EAC12',
  '20730013': 'UniCredit Bank - HVB Settlement EAC13',
  '20730014': 'UniCredit Bank - HVB Settlement EAC14',
  '20730015': 'UniCredit Bank - HVB Settlement EAC15',
  '20730016': 'UniCredit Bank - HVB Settlement EAC16',
  '20730017': 'UniCredit Bank - HVB Settlement EAC17',
  '20730018': 'UniCredit Bank - HVB Settlement EAC18',
  '20730019': 'UniCredit Bank - HVB Settlement EAC19',
  '20730020': 'UniCredit Bank - HVB Settlement EAC20',
  '20730021': 'UniCredit Bank - HVB Settlement EAC21',
  '20730022': 'UniCredit Bank - HVB Settlement EAC22',
  '20730023': 'UniCredit Bank - HVB Settlement EAC23',
  '20730024': 'UniCredit Bank - HVB Settlement EAC24',
  '20730025': 'UniCredit Bank - HVB Settlement EAC25',
  '20730026': 'UniCredit Bank - HVB Settlement EAC26',
  '20730027': 'UniCredit Bank - HVB Settlement EAC27',
  '20730028': 'UniCredit Bank - HVB Settlement EAC28',
  '20730029': 'UniCredit Bank - HVB Settlement EAC29',
  '20730030': 'UniCredit Bank - HVB Settlement EAC30',
  '20730031': 'UniCredit Bank - HVB Settlement EAC31',
  '20730032': 'UniCredit Bank - HVB Settlement EAC32',
  '20730033': 'UniCredit Bank - HVB Settlement EAC33',
  '20730034': 'UniCredit Bank - HVB Settlement EAC34',
  '20730035': 'UniCredit Bank - HVB Settlement EAC35',
  '20730036': 'UniCredit Bank - HVB Settlement EAC36',
  '20730037': 'UniCredit Bank - HVB Settlement EAC37',
  '20730038': 'UniCredit Bank - HVB Settlement EAC38',
  '20730039': 'UniCredit Bank - HVB Settlement EAC39',
  '20730040': 'UniCredit Bank - HVB Settlement EAC40',
  '20730041': 'UniCredit Bank - HVB Settlement EAC41',
  '20730042': 'UniCredit Bank - HVB Settlement EAC42',
  '20730043': 'UniCredit Bank - HVB Settlement EAC43',
  '20730044': 'UniCredit Bank - HVB Settlement EAC44',
  '20730045': 'UniCredit Bank - HVB Settlement EAC45',
  '20730046': 'UniCredit Bank - HVB Settlement EAC46',
  '20730047': 'UniCredit Bank - HVB Settlement EAC47',
  '20730048': 'UniCredit Bank - HVB Settlement EAC48',
  '20730049': 'UniCredit Bank - HVB Settlement EAC49',
  '20730050': 'UniCredit Bank - HVB Settlement EAC50',
  '20730052': 'UniCredit Bank - HVB Settlement EAC52',
  '20730055': 'UniCredit Bank - HVB Settlement EAC55',
  '20730056': 'UniCredit Bank - HVB Settlement EAC56',
  '20730057': 'UniCredit Bank - HVB Settlement EAC57',
  '20730058': 'UniCredit Bank - HVB Settlement EAC58',
  '20730059': 'UniCredit Bank - HVB Settlement EAC59',
  '20730060': 'UniCredit Bank - HVB Settlement EAC60',
  '20730061': 'UniCredit Bank - HVB Settlement EAC61',
  '20730062': 'UniCredit Bank - HVB Settlement EAC62',
  '20730063': 'UniCredit Bank - HVB Settlement EAC63',
  '20730064': 'UniCredit Bank - HVB Settlement EAC64',
  '20730065': 'UniCredit Bank - HVB Settlement EAC65',
  '20730066': 'UniCredit Bank - HVB Settlement EAC66',
  '20730067': 'UniCredit Bank - HVB Settlement EAC67',
  '20730068': 'UniCredit Bank - HVB Settlement EAC68',
  '20730069': 'UniCredit Bank - HVB Settlement EAC69',
  '20730070': 'UniCredit Bank - HVB Settlement EAC70',
  '20730071': 'UniCredit Bank - HVB Settlement EAC71',
  '20730072': 'UniCredit Bank - HVB Settlement EAC72',
  '20730073': 'UniCredit Bank - HVB Settlement EAC73',
  '20730074': 'UniCredit Bank - HVB Settlement EAC74',
  '20730075': 'UniCredit Bank - HVB Settlement EAC75',
  '20730076': 'UniCredit Bank - HVB Settlement EAC76',
  '20730077': 'UniCredit Bank - HVB Settlement EAC77',
  '20730078': 'UniCredit Bank - HVB Settlement EAC78',
  '20730079': 'UniCredit Bank - HVB Settlement EAC79',
  '20730080': 'UniCredit Bank - HVB Settlement EAC80',
  '20730081': 'UniCredit Bank - HVB Settlement EAC81',
  '20730082': 'UniCredit Bank - HVB Settlement EAC82',
  '20730083': 'UniCredit Bank - HVB Settlement EAC83',
  '20730084': 'UniCredit Bank - HVB Settlement EAC84',
  '20730085': 'UniCredit Bank - HVB Settlement EAC85',
  '20730086': 'UniCredit Bank - HVB Settlement EAC86',
  '20730087': 'UniCredit Bank - HVB Settlement EAC87',
  '20730088': 'UniCredit Bank - HVB Settlement EAC88',
  '20730089': 'UniCredit Bank - HVB Settlement EAC89',
  '20730090': 'UniCredit Bank - HVB Settlement EAC90',
  '20730091': 'UniCredit Bank - HVB Settlement EAC91',
  '20730092': 'UniCredit Bank - HVB Settlement EAC92',
  '20730093': 'UniCredit Bank - HVB Settlement EAC93',
  '20730094': 'UniCredit Bank - HVB Settlement EAC94',
  '20730095': 'UniCredit Bank - HVB Settlement EAC95',
  '20730096': 'UniCredit Bank - HVB Settlement EAC96',
  '20730097': 'UniCredit Bank - HVB Settlement EAC97',
  '20730098': 'UniCredit Bank - HVB Settlement EAC98',
  '20730099': 'UniCredit Bank - HVB Settlement EAC99',
  '20750000': 'Sparkasse Harburg-Buxtehude',
  '21000000': 'Bundesbank eh Kiel',
  '21020600': 'Sydbank Filiale Kiel',
  '21040010': 'Commerzbank',
  '21042076': 'Commerzbank',
  '21050000': 'Hamburg Commercial Bank, ehemals HSH Nordbank Hamburg',
  '21050170': 'Förde Sparkasse',
  '21051275': 'Bordesholmer Sparkasse',
  '21051580': 'Sparkasse Kreis Plön -alt-',
  '21052090': 'Sparkasse Eckernförde -alt-',
  '21070020': 'Deutsche Bank',
  '21070024': 'Deutsche Bank',
  '21080050': 'Commerzbank vormals Dresdner Bank',
  '21089201': 'Commerzbank vormals Dresdner Bank ITGK',
  '21090007': 'Kieler Volksbank (Gf P2)',
  '21090099': 'Kieler Volksbank (Gf GAA)',
  '21090900': 'PSD Bank Kiel (Gf P2)',
  '21092023': 'Eckernförder Bank Volksbank-Raiffeisenbank (Gf P2)',
  '21240040': 'Commerzbank',
  '21241540': 'Commerzbank',
  '21261089': 'Raiffeisenbank -alt-',
  '21261227': 'Raiffbk Kl-Kummerfeld -alt-',
  '21270020': 'Deutsche Bank',
  '21270024': 'Deutsche Bank',
  '21280002': 'Commerzbank vormals Dresdner Bank',
  '21290016': 'VR Bank Neumünster (Gf P2)',
  '21340010': 'Commerzbank',
  '21352240': 'Sparkasse Holstein',
  '21390008': 'VR Bank zwischen den Meeren (Gf P2)',
  '21392218': 'Volksbank Eutin Raiffeisenbank (Gf P2)',
  '21440045': 'Commerzbank',
  '21450000': 'Sparkasse Mittelholstein',
  '21451205': 'Sparkasse Büdelsdorf -alt-',
  '21452030': 'Sparkasse Hohenwestedt',
  '21463603': 'Volksbank-Raiffeisenbank im Kreis Rendsburg (Gf P2)',
  '21464671': 'Raiffeisenbank Todenbüttel',
  '21480003': 'Commerzbank vormals Dresdner Bank',
  '21510600': 'Sydbank Filiale Flensburg',
  '21520100': 'Union-Bank Flensburg (Gf P2)',
  '21540060': 'Commerzbank',
  '21565316': 'Raiffeisenbank (Gf P2)',
  '21570011': 'Deutsche Bank',
  '21570024': 'Deutsche Bank',
  '21570202': 'Postbank/DSL Ndl der Deutsche Bank',
  '21570224': 'Postbank/DSL Ndl der Deutsche Bank',
  '21580000': 'Commerzbank vormals Dresdner Bank',
  '21661719': 'VR Bank Flensburg-Schleswig (Gf P2)',
  '21690020': 'VR Bank Schleswig-Mittelholstein (Gf P2)',
  '21740043': 'Commerzbank',
  '21741674': 'Commerzbank',
  '21741825': 'Commerzbank',
  '21750000': 'Nord-Ostsee Sparkasse',
  '21751230': 'Spar- und Leihkasse zu Bredstedt',
  '21762550': 'VR Bank Westküste (Gf P2)',
  '21763542': 'VR Bank Nord (Gf P2)',
  '21770011': 'Deutsche Bank',
  '21770024': 'Deutsche Bank',
  '21791805': 'Sylter Bank (Gf P2)',
  '21791906': 'Vereinigte VR Bank (Gf P2)',
  '21840078': 'Commerzbank',
  '21841328': 'Commerzbank',
  '21852310': 'Sparkasse Hennstedt-Wesselburen -alt-',
  '21860418': 'Raiffeisenbank Heide (Gf P2)',
  '21890022': 'Dithmarscher Volks- und Raiffeisenbank (Gf P2)',
  '22140028': 'Commerzbank',
  '22141028': 'Commerzbank',
  '22141428': 'Commerzbank',
  '22141628': 'Commerzbank',
  '22150000': 'Sparkasse Elmshorn',
  '22151730': 'Stadtsparkasse Wedel',
  '22163114': 'Raiffeisenbank Elbmarsch Heist',
  '22180000': 'Commerzbank vormals Dresdner Bank',
  '22181400': 'Commerzbank vormals Dresdner Bank',
  '22190030': 'Volksbank Elmshorn (Gf P2)',
  '22191405': 'VR Bank in Holstein (Gf P2)',
  '22240073': 'Commerzbank',
  '22250020': 'Sparkasse Westholstein',
  '22280000': 'Commerzbank vormals Dresdner Bank',
  '22290031': 'Volksbank Raiffeisenbank Itzehoe Norderstedt Hohenwestedt',
  '23000000': 'Bundesbank eh Lübeck',
  '23040022': 'Commerzbank',
  '23050000': 'Hamburg Commercial Bank, ehemals HSH Nordbank Hamburg',
  '23050101': 'Sparkasse zu Lübeck',
  '23051030': 'Sparkasse Südholstein',
  '23052750': 'Kreissparkasse Herzogtum Lauenburg',
  '23061220': 'Raiffeisenbank Leezen (Gf P2)',
  '23062124': 'Raiffeisenbank (Gf P2)',
  '23063129': 'Raiffeisenbank Lauenburg',
  '23064107': 'Raiffeisenbank Büchen',
  '23070203': 'Postbank/DSL Ndl der Deutsche Bank',
  '23070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '23070700': 'Deutsche Bank',
  '23070710': 'Deutsche Bank',
  '23080040': 'Commerzbank vormals Dresdner Bank',
  '23089201': 'Commerzbank vormals Dresdner Bank ITGK',
  '23090142': 'Volksbank Lübeck (Gf P2)',
  '24040000': 'Commerzbank',
  '24050110': 'Sparkasse Lüneburg',
  '24060300': 'Volksbank Lüneburger Heide (Gf P2)',
  '24061392': 'Volksbank Bleckede-Dahlenburg -alt-',
  '24070024': 'Deutsche Bank',
  '24070075': 'Deutsche Bank',
  '24070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '24070368': 'Postbank/DSL Ndl der Deutsche Bank',
  '24080000': 'Commerzbank vormals Dresdner Bank',
  '24090041': 'Volksbank Lüneburg (Gf P2)',
  '24121000': 'Ritterschaftliches Kreditinstitut Stade',
  '24140041': 'Commerzbank',
  '24150001': 'Stadtsparkasse Cuxhaven',
  '24151005': 'Sparkasse Stade-Altes Land',
  '24151116': 'Kreissparkasse Stade',
  '24151235': 'Sparkasse Rotenburg Osterholz',
  '24161594': 'Zevener Volksbank (Gf P2)',
  '24162898': 'Spar- u Darlehnskasse Börde Lamstedt-Hechthausen (Gf P2)',
  '24180000': 'Commerzbank vormals Dresdner Bank',
  '24180001': 'Commerzbank vormals Dresdner Bank',
  '24191015': 'Volksbank Stade-Cuxhaven (Gf P2)',
  '25000000': 'Bundesbank',
  '25010030': 'Postbank Ndl der Deutsche Bank',
  '25010424': 'Aareal Bank ehem. Filiale Hannover',
  '25010600': 'NORD/LB vormals Deutsche Hypothekenbank',
  '25010900': 'Calenberger Kreditverein',
  '25020200': 'BHF-BANK',
  '25020600': 'Santander Consumer Bank',
  '25040060': 'Commerzbank CC',
  '25040061': 'Commerzbank CC',
  '25040066': 'Commerzbank',
  '25050000': 'Norddeutsche Landesbank Girozentrale',
  '25050055': 'ZVA Norddeutsche Landesbank SH',
  '25050066': 'ZVA Norddeutsche Landesbank Gf MV',
  '25050180': 'Sparkasse Hannover',
  '25050299': 'Sparkasse Hannover -alt-',
  '25055500': 'LBS-Norddeutsche Landesbausparkasse',
  '25060000': 'DZ BANK',
  '25060180': 'M.M. Warburg & Co (vormals Bankhaus Hallbaum)',
  '25069168': 'Volks- und Raiffeisenbank Leinebergland (Gf P2)',
  '25069262': 'Raiffeisen-Volksbank Neustadt (Gf P2)',
  '25069270': 'Volksbank Aller-Oker (Gf P2)',
  '25069503': 'Volksbank Diepholz-Barnstorf (Gf P2)',
  '25070024': 'Deutsche Bank',
  '25070066': 'Deutsche Bank',
  '25070070': 'Deutsche Bank',
  '25070077': 'Deutsche Bank',
  '25070084': 'Deutsche Bank',
  '25070086': 'Deutsche Bank',
  '25070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '25070370': 'Postbank/DSL Ndl der Deutsche Bank',
  '25080020': 'Commerzbank vormals Dresdner Bank',
  '25080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '25089220': 'Commerzbank vormals Dresdner Bank ITGK',
  '25090300': 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
  '25090500': 'Sparda-Bank Hannover (Gf P2)',
  '25090900': 'PSD Bank (Gf P2)',
  '25120510': 'Bank für Sozialwirtschaft',
  '25151270': 'Stadtsparkasse Barsinghausen',
  '25151371': 'Stadtsparkasse Burgdorf',
  '25152375': 'Kreissparkasse Fallingbostel in Walsrode',
  '25152490': 'Stadtsparkasse Wunstorf',
  '25190001': 'Hannoversche Volksbank (Gf P2)',
  '25190088': 'Hannoversche Volksbank GS nur für GAA',
  '25193331': 'Volksbank (Gf P2)',
  '25250001': 'Kreissparkasse Peine -alt-',
  '25260010': 'Volksbank Peine (Gf P2)',
  '25410200': 'BHW Bausparkasse',
  '25440047': 'Commerzbank',
  '25450001': 'Stadtsparkasse Hameln -alt-',
  '25450110': 'Sparkasse Hameln-Weserbergland',
  '25451345': 'Stadtsparkasse Bad Pyrmont',
  '25462160': 'Volksbank Hameln-Stadthagen (Gf P2)',
  '25462680': 'Volksbank im Wesertal (Gf P2)',
  '25470024': 'Deutsche Bank',
  '25470073': 'Deutsche Bank',
  '25471024': 'Deutsche Bank',
  '25471073': 'Deutsche Bank',
  '25480021': 'Commerzbank vormals Dresdner Bank',
  '25491273': 'Volksbank Aerzen (Gf P2)',
  '25541426': 'Commerzbank',
  '25551480': 'Sparkasse Schaumburg',
  '25591413': 'Volksbank in Schaumburg und Nienburg (Gf P2)',
  '25621327': 'Oldenburgische Landesbank AG',
  '25641302': 'Commerzbank',
  '25650106': 'Sparkasse Nienburg',
  '25651325': 'Kreissparkasse Grafschaft Diepholz',
  '25662540': 'Volksbank (Gf P2)',
  '25663584': 'Volksbank Aller-Weser (Gf P2)',
  '25690009': 'Volksbank Nienburg (Gf P2)',
  '25691633': 'Volksbank Niedersachsen-Mitte (Gf P2)',
  '25691699': 'Volksbank Niedersachsen-Mitte (Gf GAA)',
  '25740061': 'Commerzbank',
  '25750001': 'Sparkasse Celle -alt-',
  '25761894': 'Volksbank Wittingen-Klötze (Gf P2)',
  '25770024': 'Deutsche Bank',
  '25770069': 'Deutsche Bank',
  '25780022': 'Commerzbank vormals Dresdner Bank',
  '25791516': 'Volksbank Hankensbüttel-Wahrenholz (Gf P2)',
  '25791635': 'Volksbank Südheide - Isenhagener Land - Altmark (Gf P2)',
  '25840048': 'Commerzbank',
  '25841403': 'Commerzbank',
  '25841708': 'Commerzbank',
  '25850110': 'Sparkasse Uelzen Lüchow-Dannenberg',
  '25851660': 'Kreissparkasse Soltau',
  '25861990': 'Volksbank Clenze-Hitzacker (Gf P2)',
  '25862292': 'Volksbank Uelzen-Salzwedel (Gf P2)',
  '25863489': 'VR PLUS Altmark-Wendland (Gf P2)',
  '25891636': 'Volksbank Lüneburger Heide (Gf P2)',
  '25940033': 'Commerzbank',
  '25950001': 'Stadtsparkasse Hildesheim -alt-',
  '25950130': 'Sparkasse Hildesheim Goslar Peine',
  '25970024': 'Deutsche Bank',
  '25970074': 'Deutsche Bank',
  '25971024': 'Deutsche Bank',
  '25971071': 'Deutsche Bank',
  '25980027': 'Commerzbank vormals Dresdner Bank',
  '25990011': 'Volksbank Hildesheim (Gf P2)',
  '25991528': 'Volksbank Hildesheimer Börde (Gf P2)',
  '26000000': 'Bundesbank',
  '26040030': 'Commerzbank',
  '26050001': 'Sparkasse Göttingen',
  '26051260': 'Sparkasse Duderstadt',
  '26051450': 'Kreis- und Stadtsparkasse Münden -alt-',
  '26061291': 'Volksbank Mitte (Gf P2)',
  '26061556': 'Volksbank (Gf P2)',
  '26062433': 'VR-Bank in Südniedersachsen (Gf P2)',
  '26070024': 'Deutsche Bank',
  '26070072': 'Deutsche Bank',
  '26080024': 'Commerzbank vormals Dresdner Bank',
  '26090050': 'Volksbank (Gf P2)',
  '26240039': 'Commerzbank',
  '26250001': 'Kreis-Sparkasse Northeim',
  '26251425': 'Sparkasse Einbeck',
  '26261492': 'Volksbank Einbeck (Gf P2)',
  '26261693': 'Volksbank Solling Zw Harste',
  '26271424': 'Deutsche Bank',
  '26271471': 'Deutsche Bank',
  '26280020': 'Commerzbank vormals Dresdner Bank',
  '26281420': 'Commerzbank vormals Dresdner Bank',
  '26340056': 'Commerzbank',
  '26341072': 'Commerzbank',
  '26350001': 'Stadtsparkasse Osterode -alt-',
  '26351015': 'Sparkasse Osterode am Harz',
  '26351445': 'Stadtsparkasse Bad Sachsa -alt-',
  '26500000': 'Bundesbank',
  '26520017': 'Oldenburgische Landesbank AG',
  '26521703': 'Oldenburgische Landesbank AG',
  '26522319': 'Oldenburgische Landesbank AG',
  '26540070': 'Commerzbank',
  '26550105': 'Sparkasse Osnabrück',
  '26551540': 'Kreissparkasse Bersenbrück',
  '26552286': 'Kreissparkasse Melle',
  '26562490': 'Volksbank Bad Laer-Borgloh-Hilter-Melle (Gf P2)',
  '26563960': 'Volksbank Bramgau-Wittlage (GF P2)',
  '26565928': 'Volksbank GMHütte-Hagen-Bissendorf (Gf P2)',
  '26566939': 'Volksbank Osnabrücker Nordland (Gf P2)',
  '26567943': 'VR-Bank Osnabrücker Nordland (Gf P2)',
  '26570024': 'Deutsche Bank',
  '26570090': 'Deutsche Bank',
  '26580070': 'Commerzbank vormals Dresdner Bank',
  '26589210': 'Commerzbank vormals Dresdner Bank ITGK',
  '26590025': 'Vereinigte Volksbank Bramgau Osnabrück Wittlage (Gf P2)',
  '26620010': 'Oldenburgische Landesbank AG',
  '26621413': 'Oldenburgische Landesbank AG',
  '26640049': 'Commerzbank',
  '26650001': 'Sparkasse Emsland',
  '26660060': 'Emsländische Volksbank (Gf P2)',
  '26661380': 'Volksbank Haselünne (Gf P2)',
  '26661494': 'Emsländische Volksbank Meppen (Gf P2)',
  '26662932': 'Volksbank (Gf P2)',
  '26691213': 'Volksbank Haren Fil d Ostfriesischen VB',
  '26720028': 'Oldenburgische Landesbank AG',
  '26740044': 'Commerzbank',
  '26750001': 'Kreissparkasse Grafschaft Bentheim zu Nordhorn',
  '26760005': 'Raiffeisen- und Volksbank Nordhorn -alt-',
  '26770024': 'Deutsche Bank',
  '26770095': 'Deutsche Bank',
  '26840032': 'Commerzbank',
  '26850001': 'Sparkasse Goslar/Harz -alt-',
  '26851410': 'Kreissparkasse Clausthal-Zellerfeld -alt-',
  '26851620': 'Sparkasse Salzgitter -alt-',
  '26870024': 'Deutsche Bank',
  '26870032': 'Deutsche Bank',
  '26880063': 'Commerzbank vormals Dresdner Bank',
  '26890019': 'Volksbank Nordharz (Gf P2)',
  '26891484': 'Volksbank im Harz (Gf P2)',
  '26941053': 'Commerzbank Wolfsburg',
  '26951311': 'Sparkasse Celle-Gifhorn-Wolfsburg',
  '26971024': 'Deutsche Bank',
  '26971038': 'Deutsche Bank',
  '26981062': 'Commerzbank vormals Dresdner Bank',
  '26989221': 'Commerzbank vormals Dresdner Bank ITGK',
  '26991066': 'Volksbank Braunschweig Wolfsburg (Gf P2)',
  '27020000': 'Volkswagen Bank',
  '27020001': 'Audi Bank Zndl d Volkswagen Bank',
  '27020003': 'Skoda Bank',
  '27020004': 'AutoEuropa Bank',
  '27020800': 'Seat Bank Zndl d Volkswagen Bank',
  '27032500': 'Bankhaus C. L. Seeliger',
  '27040080': 'Commerzbank',
  '27062290': 'Volksbank Börßum-Hornburg (Gf P2)',
  '27070024': 'Deutsche Bank',
  '27070030': 'Deutsche Bank',
  '27070031': 'Deutsche Bank',
  '27070034': 'Deutsche Bank',
  '27070041': 'Deutsche Bank',
  '27070042': 'Deutsche Bank',
  '27070043': 'Deutsche Bank',
  '27070079': 'Deutsche Bank',
  '27070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '27070369': 'Postbank/DSL Ndl der Deutsche Bank',
  '27072524': 'Deutsche Bank',
  '27072537': 'Deutsche Bank',
  '27072724': 'Deutsche Bank',
  '27072736': 'Deutsche Bank',
  '27080060': 'Commerzbank vorm. Dresdner Bk Salzgitter-Bad u -Lebenstedt',
  '27089221': 'Commerzbank vormals Dresdner Bank ITGK',
  '27090900': 'PSD Bank Braunschweig',
  '27092555': 'Volksbank (Gf P2)',
  '27131300': 'Bankhaus Rautenschlein',
  '27240004': 'Commerzbank',
  '27290087': 'Volksbank Weserbergland Holzminden',
  '27893359': 'Volksbank Braunlage Zw Walkenried',
  '27893760': 'Volksbank (Gf P2)',
  '28000000': 'Bundesbank',
  '28020050': 'Oldenburgische Landesbank AG',
  '28021002': 'Oldenburgische Landesbank AG',
  '28021301': 'Oldenburgische Landesbank AG',
  '28021504': 'Oldenburgische Landesbank AG',
  '28021623': 'Oldenburgische Landesbank AG',
  '28021705': 'Oldenburgische Landesbank AG Delmenhorst',
  '28021906': 'Oldenburgische Landesbank AG',
  '28022015': 'Oldenburgische Landesbank AG',
  '28022412': 'Oldenburgische Landesbank AG',
  '28022511': 'Oldenburgische Landesbank AG',
  '28022620': 'Oldenburgische Landesbank AG',
  '28022822': 'Oldenburgische Landesbank AG',
  '28023224': 'Oldenburgische Landesbank AG',
  '28023325': 'Oldenburgische Landesbank AG',
  '28030300': 'Oldenburgische Landesbank (vormals W. Fortmann & Söhne)',
  '28040046': 'Commerzbank',
  '28042865': 'Commerzbank',
  '28050100': 'Landessparkasse zu Oldenburg',
  '28060228': 'Raiffeisenbank Oldenburg (Gf P2)',
  '28061410': 'Raiffeisenbank Wesermarsch-Süd (Gf P2)',
  '28061501': 'VR-Bank in Südoldenburg (Gf P2)',
  '28061679': 'Volksbank Dammer Berge (Gf P2)',
  '28061822': 'Oldenburger Volksbank (Gf P2)',
  '28062165': 'Raiffeisenbank Rastede (Gf P2)',
  '28062249': 'Vereinigte Volksbank (Gf P2)',
  '28062299': 'Vereinigte Volksbank GAA',
  '28062560': 'Volksbank Lohne-Mühlen (Gf P2)',
  '28062740': 'Volksbank Bookholzberg-Lemwerder (Gf P2)',
  '28062913': 'Volksbank Bösel (Gf P2)',
  '28063253': 'Volksbank Westerstede (Gf P2)',
  '28063526': 'Volksbank Essen-Cappeln (Gf P2)',
  '28063607': 'Volksbank Bakum (Gf P2)',
  '28064179': 'Volksbank Vechta (Gf P2)',
  '28064241': 'Raiffeisen-Volksbank Varel-Nordenham',
  '28065061': 'Volksbank Löningen (Gf P2)',
  '28065108': 'VR-Bank Dinklage-Steinfeld (Gf P2)',
  '28065286': 'Raiffeisenbank Scharrel (Gf P2)',
  '28066103': 'Volksbank Visbek (Gf P2)',
  '28066214': 'VR Bank Oldenburg Land (Gf P2)',
  '28066620': 'Volksbank (Gf P2)',
  '28067068': 'Volksbank Neuenkirchen-Vörden (Gf P2)',
  '28067170': 'Volksbank Delmenhorst Schierbrok (Gf P2)',
  '28067257': 'Volksbank Lastrup (Gf P2)',
  '28068218': 'Raiffeisenbank Butjadingen-Abbehausen (Gf P2)',
  '28069052': 'Raiffeisenbank Strücklingen-Idafehn (Gf P2)',
  '28069092': 'VR Bank Oldenburg Land West (Gf P2)',
  '28069109': 'Volksbank Emstek (Gf P2)',
  '28069128': 'Raiffeisenbank Garrel (Gf P2)',
  '28069138': 'VR Bank Oldenburg Land West (Gf P2)',
  '28069293': 'Volksbank Obergrafschaft -alt- (Gf P2)',
  '28069381': 'Hümmlinger Volksbank (Gf P2)',
  '28069706': 'Volksbank Nordhümmling (Gf P2)',
  '28069755': 'Raiffeisenbank Oldersum',
  '28069773': 'Raiffeisenbank Wiesedermeer-Wiesede-Marcardsm (Gf P2)',
  '28069878': 'Raiffeisenbank Ems-Vechte (Gf P2)',
  '28069926': 'Volksbank Niedergrafschaft (Gf P2)',
  '28069935': 'Raiffeisenbank Lorup (Gf P2)',
  '28069956': 'Grafschafter Volksbank (Gf P2)',
  '28069991': 'Volksbank Emstal (Gf P2)',
  '28069994': 'Volksbank Süd-Emsland (Gf P2)',
  '28070024': 'Deutsche Bank',
  '28070057': 'Deutsche Bank',
  '28220026': 'Oldenburgische Landesbank AG',
  '28222208': 'Oldenburgische Landesbank AG',
  '28222621': 'Oldenburgische Landesbank Gs Jaderberg',
  '28240023': 'Commerzbank',
  '28250110': 'Sparkasse Wilhelmshaven',
  '28252760': 'Kreissparkasse Wittmund',
  '28262254': 'Volksbank Jever (Gf P2)',
  '28262673': 'Raiffeisen-Volksbank Varel-Nordenham (Gf P2)',
  '28270024': 'Deutsche Bank',
  '28270056': 'Deutsche Bank',
  '28280012': 'Commerzbank vormals Dresdner Bank',
  '28290063': 'Volksbank Wilhelmshaven (Gf P2)',
  '28291551': 'Volksbank Esens (Gf P2)',
  '28320014': 'Oldenburgische Landesbank AG',
  '28321816': 'Oldenburgische Landesbank AG',
  '28350000': 'Sparkasse Aurich-Norden',
  '28361592': 'Raiffeisen-Volksbank Fresena (Gf P2)',
  '28420007': 'Oldenburgische Landesbank AG',
  '28421030': 'Oldenburgische Landesbank AG',
  '28440037': 'Commerzbank',
  '28450000': 'Sparkasse Emden',
  '28470024': 'Deutsche Bank',
  '28470091': 'Deutsche Bank',
  '28520009': 'Oldenburgische Landesbank AG',
  '28521518': 'Oldenburgische Landesbank AG',
  '28540034': 'Commerzbank',
  '28550000': 'Sparkasse LeerWittmund',
  '28562297': 'Raiffeisen-Volksbank (Gf P2)',
  '28562716': 'Raiffeisenbank Flachsmeer (Gf P2)',
  '28562863': 'Raiffeisenbank Moormerland',
  '28563749': 'Raiffeisenbank Moormerland',
  '28570024': 'Deutsche Bank',
  '28570092': 'Deutsche Bank',
  '28590075': 'Ostfriesische Volksbank Leer (Gf P2)',
  '28591579': 'Volksbank Papenburg Fil d. Ostfries. VB Leer',
  '28591654': 'Volksbank Westrhauderfehn Zw Ostrhauderfehn',
  '29000000': 'Bundesbank eh Bremen',
  '29020000': 'Bankhaus Neelmeyer',
  '29020200': 'Greensill Bank',
  '29030400': 'M.M. Warburg & Co (vormals Bankhaus Carl F. Plump & CO)',
  '29040060': 'Commerzbank CC',
  '29040061': 'Commerzbank CC',
  '29040090': 'Commerzbank',
  '29050000': 'Norddeutsche Landesbank - Girozentrale -',
  '29050101': 'Sparkasse Bremen Gf P2',
  '29070024': 'Deutsche Bank',
  '29070050': 'Deutsche Bank',
  '29070051': 'Deutsche Bank',
  '29070052': 'Deutsche Bank',
  '29070058': 'Deutsche Bank',
  '29070059': 'Deutsche Bank',
  '29070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '29070367': 'Postbank/DSL Ndl der Deutsche Bank',
  '29080010': 'Commerzbank vormals Dresdner Bank',
  '29089210': 'Commerzbank vormals Bremer Bank (Dresdner Bank) ITGK',
  '29090900': 'PSD Bank Nord',
  '29121731': 'Oldenburgische Landesbank AG Syke',
  '29151700': 'Kreissparkasse Syke',
  '29152300': 'Kreissparkasse Osterholz -alt-',
  '29152550': 'Zweckverbandssparkasse Scheeßel',
  '29152670': 'Kreissparkasse Verden',
  '29162394': 'Volksbank (Gf P2)',
  '29162453': 'Volksbank Schwanewede (Gf P2)',
  '29162697': 'Volksbank Aller-Weser (Gf P2)',
  '29165545': 'Volksbank Oyten (Gf P2)',
  '29165681': 'Volksbank Sottrum (Gf P2)',
  '29166568': 'Volksbank (Gf P2)',
  '29167624': 'Volksbank Syke (Gf P2)',
  '29172624': 'Deutsche Bank',
  '29172655': 'Deutsche Bank',
  '29190024': 'Volksbank Rotenburg Hzw d Bremischen VB',
  '29190330': 'Volksbank Bremen-Nord (Gf P2)',
  '29190399': 'Volksbank Bremen-Nord (Gf GAA)',
  '29240024': 'Commerzbank',
  '29250000': 'Weser-Elbe Sparkasse',
  '29250150': 'Kreissparkasse Wesermünde-Hadeln -alt-',
  '29262722': 'Volksbank Geeste-Nord (Gf P2)',
  '29265747': 'Volksbank Bremerhaven-Cuxland (Gf P2)',
  '29280011': 'Commerzbank vormals Dresdner Bank',
  '29290034': 'Volksbank Bremerhaven-Wesermünde -alt- (Gf P2)',
  '30000000': 'Bundesbank eh Düsseldorf',
  '30010400': 'IKB Deutsche Industriebank',
  '30010444': 'IKB Privatkunden - IKB Deutsche Industriebank',
  '30010700': 'MUFG Bank (Europe) Germany Branch',
  '30018800': 'VIVA PAYMENT SERVICES - Zweigniederlassung Deutschland',
  '30020500': 'BHF-BANK',
  '30020700': 'Mizuho Bank Filiale Düsseldorf',
  '30020900': 'TARGOBANK',
  '30022000': 'NRW.BANK',
  '30030100': 'S Broker Wiesbaden',
  '30030500': 'Bank11direkt',
  '30030600': 'ETRIS Bank',
  '30030880': 'HSBC Trinkaus & Burkhardt',
  '30030889': 'HSBC Trinkaus VAC',
  '30030900': 'Merck Finck A Quintet Private Bank',
  '30040000': 'Commerzbank Fil Büderich',
  '30040005': 'Commerzbank, Filiale Düsseldorf 2',
  '30040048': 'Commerzbank GF-D48',
  '30040060': 'Commerzbank Gf 660',
  '30040061': 'Commerzbank Gf 661',
  '30040062': 'Commerzbank CC',
  '30040063': 'Commerzbank CC',
  '30040099': 'Commerzbank Gf Comp. Center STS',
  '30050000': 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
  '30050110': 'Stadtsparkasse Düsseldorf',
  '30060010': 'DZ BANK (Gf P2)',
  '30060601': 'apoBank (Gf P3)',
  '30060992': 'PSD Bank Rhein-Ruhr (Gf P2)',
  '30070010': 'Deutsche Bank',
  '30070024': 'Deutsche Bank',
  '30070207': 'Postbank/DSL Ndl der Deutsche Bank',
  '30070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '30080000': 'Commerzbank vormals Dresdner Bank Fil Büderich',
  '30080005': 'Commerzbank vormals Dresdner Bank Zw 05',
  '30080022': 'Commerzbank vormals Dresdner Bank Ztv 22',
  '30080038': 'Commerzbank vormals Dresdner Bank Zw 38',
  '30080041': 'Commerzbank vormals Dresdner Bank Zw 41',
  '30080053': 'Commerzbank vormals Dresdner Bank Zw 53',
  '30080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '30080057': 'Commerzbank vormals Dresdner Bank Gf ZW 57',
  '30080061': 'Commerzbank vormals Dresdner Bank Zw 61',
  '30080074': 'Commerzbank vormals Dresdner Bank Zw 74',
  '30080080': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
  '30080081': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '30080082': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '30080083': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
  '30080084': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
  '30080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
  '30080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
  '30080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
  '30080088': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
  '30080089': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 12',
  '30080095': 'Commerzbank vormals Dresdner Bank Zw 95',
  '30089300': 'Commerzbank vormals Dresdner Bank ITGK I',
  '30089302': 'Commerzbank vormals Dresdner Bank ITGK II',
  '30110300': 'Sumitomo Mitsui Banking Corporation',
  '30130100': 'Demir-Halk Bank (Nederland)',
  '30130200': 'GarantiBank International',
  '30130600': 'Isbank Fil Düsseldorf',
  '30150001': 'Helaba Düsseldorf Gf Verrechnung FI-Dus',
  '30150200': 'Kreissparkasse Düsseldorf',
  '30160213': 'Volksbank Düsseldorf Neuss Zw Ratingen',
  '30160266': 'Volksbank Düsseldorf Neuss (Gf GAA)',
  '30220190': 'UniCredit Bank - HypoVereinsbank',
  '30330800': 'flatexDEGIRO Bank',
  '30351220': 'Stadt-Sparkasse Haan',
  '30520000': 'RCI Banque Niederlassung Deutschland',
  '30520037': 'RCI Banque Direkt',
  '30524400': 'KBC Bank Ndl Deutschland',
  '30530000': 'Bankhaus Werhahn (Gf P2)',
  '30530500': 'Bank11 für Privatkunden und Handel, Neuss',
  '30550000': 'Sparkasse Neuss',
  '30551240': 'Stadtsparkasse Kaarst-Büttgen -alt-',
  '30560090': 'Volksbank Neuss -alt-',
  '30560548': 'VR Bank Zw Neurath',
  '30560591': 'VR Bank (Gf GAA)',
  '31010833': 'Santander Consumer Bank Gf P3',
  '31040015': 'Commerzbank Fil Nettetal-Lobberich',
  '31040060': 'Commerzbank CC',
  '31040061': 'Commerzbank CC',
  '31050000': 'Stadtsparkasse Mönchengladbach',
  '31060181': 'Gladbacher Bank von 1922 (Gf P2)',
  '31060517': 'Volksbank Mönchengladbach (Gf P2)',
  '31062154': 'Volksbank Brüggen-Nettetal Zw',
  '31062553': 'Volksbank Schwalmtal (Gf P2)',
  '31070001': 'Deutsche Bank',
  '31070024': 'Deutsche Bank',
  '31070206': 'Postbank/DSL Ndl der Deutsche Bank',
  '31070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '31080015': 'Commerzbank vormals Dresdner Bank',
  '31080061': 'Commerzbank vormals Dresdner Bank Zw 61',
  '31251220': 'Kreissparkasse Heinsberg in Erkelenz',
  '31261282': 'Volksbank Erkelenz (Gf P2)',
  '31263359': 'Raiffeisenbank Erkelenz (Gf P2)',
  '31460290': 'Volksbank Viersen (Gf P2)',
  '31470004': 'Deutsche Bank',
  '31470024': 'Deutsche Bank',
  '32040024': 'Commerzbank Fil Osterath',
  '32050000': 'Sparkasse Krefeld',
  '32051996': 'Sparkasse der Stadt Straelen -alt-',
  '32060362': 'Volksbank Krefeld (Gf P2)',
  '32061384': 'Volksbank an der Niers (Gf P2)',
  '32061414': 'Volksbank Kempen-Grefrath Zw',
  '32070024': 'Deutsche Bank',
  '32070080': 'Deutsche Bank',
  '32080010': 'Commerzbank vormals Dresdner Bank',
  '32250050': 'Verbandssparkasse Goch -alt-',
  '32440023': 'Commerzbank',
  '32450000': 'Sparkasse Rhein-Maas',
  '32460422': 'Volksbank Kleverland Zw',
  '32470024': 'Deutsche Bank',
  '32470077': 'Deutsche Bank',
  '33020000': 'akf bank',
  '33020190': 'UniCredit Bank - HypoVereinsbank',
  '33030000': 'GEFA BANK',
  '33040001': 'Commerzbank Langenberg',
  '33040310': 'Commerzbank Zw 117',
  '33050000': 'Stadtsparkasse Wuppertal',
  '33060098': 'Credit- und Volksbank Wuppertal (Gf P2)',
  '33060592': 'Sparda-Bank West',
  '33070024': 'Deutsche Bank',
  '33070090': 'Deutsche Bank',
  '33080001': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '33080030': 'Commerzbank vormals Dresdner Bank',
  '33080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '33080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
  '33080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '33080088': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '33440035': 'Commerzbank',
  '33450000': 'Sparkasse Hilden-Ratingen-Velbert',
  '33451220': 'Sparkasse Heiligenhaus -alt-',
  '34040049': 'Commerzbank',
  '34050000': 'Stadtsparkasse Remscheid',
  '34051350': 'Sparkasse Radevormwald-Hückeswagen',
  '34051570': 'Stadtsparkasse Wermelskirchen',
  '34060094': 'Volksbank im Bergischen Land (Gf P2)',
  '34070024': 'Deutsche Bank',
  '34070093': 'Deutsche Bank',
  '34080031': 'Commerzbank vormals Dresdner Bank',
  '34240050': 'Commerzbank',
  '34250000': 'Stadt-Sparkasse Solingen',
  '34270024': 'Deutsche Bank',
  '34270094': 'Deutsche Bank',
  '34280032': 'Commerzbank vormals Dresdner Bank',
  '35040038': 'Commerzbank',
  '35040085': 'Commerzbank, Gf Web-K',
  '35050000': 'Sparkasse Duisburg',
  '35060190': 'LKG Sachsen Zndl der Bank für Kirche u. Diakonie - KD-Bank',
  '35060199': 'Bank für Kirche und Diakonie - KD-Bank (Gf GAA RB)',
  '35060386': 'Volksbank Rhein-Ruhr (Gf P2)',
  '35070024': 'Deutsche Bank',
  '35070030': 'Deutsche Bank',
  '35080070': 'Commerzbank vormals Dresdner Bank',
  '35080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '35080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '35080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
  '35080088': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '35080089': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '35090300': 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
  '35251000': 'Sparkasse Dinslaken-Voerde-Hünxe -alt-',
  '35261248': 'Volksbank Dinslaken (Gf P2)',
  '35450000': 'Sparkasse am Niederrhein',
  '35451460': 'Sparkasse Neukirchen-Vluyn -alt-',
  '35451775': 'Sparkasse Rheinberg -alt-',
  '35461106': 'Volksbank Niederrhein Zw',
  '35640064': 'Commerzbank Fil Du-Walsum',
  '35650000': 'Niederrheinische Sparkasse RheinLippe',
  '35660501': 'Volksbank Rhein-Lippe (GAA)',
  '35660599': 'Volksbank Rhein-Lippe (Gf P2)',
  '35850000': 'Stadtsparkasse Emmerich-Rees -alt-',
  '35860245': 'Volksbank Emmerich-Rees (Gf P2)',
  '36000000': 'Bundesbank eh Essen',
  '36010043': 'Postbank Ndl der Deutsche Bank',
  '36010424': 'Aareal Bank',
  '36020030': 'National-Bank Essen',
  '36020186': 'UniCredit Bank - HypoVereinsbank',
  '36033300': 'Santander Consumer Bank',
  '36040039': 'Commerzbank Fil Marl-Hüls',
  '36040060': 'Commerzbank CC',
  '36040061': 'Commerzbank CC',
  '36040085': 'Commerzbank, Gf Web-K',
  '36050105': 'Sparkasse Essen',
  '36060192': 'Pax-Bank',
  '36060295': 'Bank im Bistum Essen (Gf P2)',
  '36060488': 'GENO BANK ESSEN Zw. Niederwenigern',
  '36060591': 'Sparda-Bank West',
  '36070024': 'Deutsche Bank',
  '36070050': 'Deutsche Bank',
  '36070208': 'Postbank/DSL Ndl der Deutsche Bank',
  '36070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '36080080': 'Commerzbank vormals Dresdner Bank',
  '36080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK  2',
  '36089321': 'Commerzbank vormals Dresdner Bank ITGK',
  '36240045': 'Commerzbank',
  '36250000': 'Sparkasse Mülheim an der Ruhr',
  '36270024': 'Deutsche Bank',
  '36270048': 'Deutsche Bank',
  '36280071': 'Commerzbank vormals Dresdner Bank',
  '36540046': 'Commerzbank',
  '36550000': 'Stadtsparkasse Oberhausen',
  '36570024': 'Deutsche Bank',
  '36570049': 'Deutsche Bank',
  '36580072': 'Commerzbank vormals Dresdner Bank',
  '37000000': 'Bundesbank',
  '37010050': 'Postbank Ndl der Deutsche Bank',
  '37010600': 'BNP Paribas Niederlassung Deutschland',
  '37010699': 'BNP Paribas Niederlassung Deutschland',
  '37011000': 'Postbank Ndl der Deutsche Bank',
  '37019000': 'bunq Niederlassung Deutschland',
  '37020090': 'UniCredit Bank - HypoVereinsbank',
  '37020200': 'Fondsdepot Bank',
  '37020400': 'TOYOTA Kreditbank',
  '37020500': 'Bank für Sozialwirtschaft (Gf P2)',
  '37020600': 'Santander Consumer Bank',
  '37020900': 'Ford Bank Ndl. der FCE Bank',
  '37021500': 'Ford Bank',
  '37021548': 'Ford Bank (GF EG)',
  '37027000': 'Santander Consumer Bank',
  '37030700': 'abcbank',
  '37030800': 'Isbank Fil Köln',
  '37040037': 'Commerzbank, CC SP',
  '37040044': 'Commerzbank Fil Opladen',
  '37040048': 'Commerzbank',
  '37040060': 'Commerzbank CC',
  '37040061': 'Commerzbank CC',
  '37050198': 'Sparkasse KölnBonn',
  '37050299': 'Kreissparkasse Köln',
  '37060120': 'Pax-Bank Gf MHD',
  '37060193': 'Pax-Bank (Gf P2)',
  '37060194': 'Pax-Bank GF GAA',
  '37060590': 'Sparda-Bank West',
  '37060993': 'PSD Bank West (Gf P2)',
  '37062124': 'Bensberger Bank (Gf P2)',
  '37062365': 'Volksbank Rhein-Erft-Köln (Gf P2)',
  '37062600': 'VR Bank Bergisch Gladbach-Leverkusen (GF P2)',
  '37063367': 'Raiffeisenbank Fischenich-Kendenich (Gf P2)',
  '37069101': 'Spar- und Darlehnskasse Aegidienberg (Gf P2)',
  '37069103': 'Raiffeisenbank Aldenhoven Zw',
  '37069125': 'Volksbank Berg (Gf P2)',
  '37069153': 'Spar- und Darlehnskasse Brachelen (Gf P2)',
  '37069164': 'Volksbank Meerbusch (Gf P2)',
  '37069252': 'Volksbank Erft (Gf P2)',
  '37069302': 'Raiffeisenbank Geilenkirchen Zw Uetterath',
  '37069303': 'Volksbank Gemünd-Kall',
  '37069306': 'Raiffeisenbank Grevenbroich (Gf P2)',
  '37069322': 'Raiffeisenbank Gymnich (Gf P2)',
  '37069330': 'Volksbank Haaren (Gf P2)',
  '37069331': 'Raiffeisenbank von 1895 Zw Horrem (Gf P2)',
  '37069342': 'Volksbank Heimbach (Gf P2)',
  '37069354': 'Raiffeisenbank Selfkant',
  '37069355': 'Spar- und Darlehnskasse Hoengen (Gf P2)',
  '37069381': 'VR-Bank Rur-Wurm (Gf P2)',
  '37069401': 'Raiffeisenbank Junkersdorf (Gf P2)',
  '37069405': 'Raiffeisenbank Kaarst (Gf P2)',
  '37069412': 'Volksbank Heinsberg (Gf P2)',
  '37069427': 'Volksbank Dünnwald-Holweide (Gf P2)',
  '37069429': 'Volksbank Köln-Nord (Gf P2)',
  '37069472': 'Raiffeisenbk Erftstadt -alt-',
  '37069520': 'VR-Bank Bonn Rhein-Sieg (Gf P2)',
  '37069521': 'Raiffeisenbank Rhein-Berg (Gf P2)',
  '37069524': 'Raiffeisenbank Much-Ruppichteroth (Gf P2)',
  '37069627': 'Raiffeisenbank Voreifel (Gf P2)',
  '37069639': 'Rosbacher Raiffeisenbank (Gf P2)',
  '37069642': 'Raiffeisenbank Eifel Gs Roetgen',
  '37069707': 'Raiffeisenbank Sankt Augustin (Gf P2)',
  '37069720': 'VR-Bank Nordeifel (Gf P2)',
  '37069805': 'Volksbank Wachtberg (Gf P2)',
  '37069833': 'Raiffeisenbk Wesseling -alt-',
  '37069840': 'Volksbank Wipperfürth-Lindlar (Gf P2)',
  '37069991': 'Brühler Bank (Gf P2)',
  '37070000': 'Deutsche Bank',
  '37070024': 'Deutsche Bank',
  '37070060': 'Deutsche Bank',
  '37070209': 'Postbank/DSL Ndl der Deutsche Bank',
  '37070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '37080040': 'Commerzbank vormals Dresdner Bank',
  '37080085': 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 1',
  '37080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '37080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '37080088': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
  '37080089': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
  '37080090': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
  '37080091': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
  '37080092': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
  '37080093': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
  '37080094': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 12',
  '37080095': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 13',
  '37080096': 'Commerzbank vormals Dresdner Bank Zw 96',
  '37080097': 'Commerzbank vormals Dresdner Bank Zw 97',
  '37080098': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 14',
  '37080099': 'Commerzbank vormals Dresdner Bank Zw 99',
  '37089340': 'Commerzbank vormals Dresdner Bank ITGK I',
  '37089342': 'Commerzbank vormals Dresdner Bank ITGK II',
  '37160087': 'Kölner Bank (Gf P2)',
  '37161289': 'VR-Bank Rhein-Erft (Gf P2)',
  '37540050': 'Commerzbank',
  '37551020': 'Stadt-Sparkasse Leichlingen',
  '37551440': 'Sparkasse Leverkusen',
  '37551780': 'Stadt-Sparkasse Langenfeld',
  '37560092': 'Volksbank Rhein-Wupper Zw Leichlingen',
  '37570024': 'Deutsche Bank',
  '37570064': 'Deutsche Bank',
  '38010053': 'Postbank Ndl der Deutsche Bank',
  '38010700': 'DSL Bank Ndl der Deutsche Bank',
  '38010900': 'KfW Ndl Bonn',
  '38010999': 'KfW Ausbildungsförderung Bonn',
  '38011000': 'VÖB-ZVD Processing',
  '38011001': 'VÖB-ZVD Processing',
  '38011002': 'VÖB-ZVD Processing',
  '38011003': 'VÖB-ZVD Processing',
  '38011004': 'VÖB-ZVD Processing',
  '38011005': 'VÖB-ZVD Processing',
  '38011006': 'VÖB-ZVD Processing',
  '38011007': 'VÖB-ZVD Processing',
  '38011008': 'VÖB-ZVD Processing',
  '38020090': 'UniCredit Bank - HypoVereinsbank',
  '38040007': 'Commerzbank',
  '38050000': 'Sparkasse Bonn -alt-',
  '38051290': 'Stadtsparkasse Bad Honnef',
  '38060186': 'Volksbank Köln Bonn (Gf P2)',
  '38070024': 'Deutsche Bank',
  '38070059': 'Deutsche Bank',
  '38070408': 'Postbank/DSL Ndl der Deutsche Bank',
  '38070424': 'Postbank/DSL Ndl der Deutsche Bank',
  '38070724': 'Deutsche Bank',
  '38077724': 'Deutsche Bank',
  '38080055': 'Commerzbank vormals Dresdner Bank',
  '38160220': 'VR-Bank Bonn (Gf P2)',
  '38250110': 'Kreissparkasse Euskirchen',
  '38260082': 'Volksbank Euskirchen (Gf P2)',
  '38440016': 'Commerzbank',
  '38450000': 'Sparkasse Gummersbach',
  '38452490': 'Sparkasse der Homburgischen Gemeinden',
  '38462135': 'Volksbank Oberberg (Gf P2)',
  '38470024': 'Deutsche Bank',
  '38470091': 'Deutsche Bank',
  '38621500': 'Steyler Bank (Gf P2)',
  '38650000': 'Kreissparkasse Siegburg',
  '38651390': 'Sparkasse Hennef',
  '39020000': 'Aachener Bausparkasse',
  '39040013': 'Commerzbank',
  '39050000': 'Sparkasse Aachen',
  '39060180': 'Aachener Bank (Gf P2)',
  '39061981': 'Heinsberger Volksbank (Gf P2)',
  '39070020': 'Deutsche Bank',
  '39070024': 'Deutsche Bank',
  '39070210': 'Postbank/DSL Ndl der Deutsche Bank',
  '39070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '39080005': 'Commerzbank vormals Dresdner Bank Fil Kohlscheid',
  '39080098': 'Commerzbank vormals Dresdner Bank Zw 98',
  '39080099': 'Commerzbank vormals Dresdner Bank Zw 99',
  '39160191': 'Pax-Bank',
  '39161490': 'Volksbank Aachen Süd',
  '39162980': 'VR-Bank (Gf P2)',
  '39362254': 'Raiffeisen-Bank Eschweiler (Gf P2)',
  '39540052': 'Commerzbank',
  '39550110': 'Sparkasse Düren',
  '39560201': 'Volksbank Düren Zw Schlich',
  '39570024': 'Deutsche Bank',
  '39570061': 'Deutsche Bank',
  '39580041': 'Commerzbank vormals Dresdner Bank',
  '40022000': 'NRW.BANK',
  '40030000': 'Münsterländische Bank Thie & Co (Gf P3)',
  '40040028': 'Commerzbank',
  '40050000': 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
  '40050150': 'Sparkasse Münsterland Ost',
  '40055555': 'LBS Westdeutsche Landesbausparkasse',
  '40060000': 'DZ BANK (Gf P2)',
  '40060265': 'DKM Darlehnskasse Münster (Gf P2)',
  '40060300': 'DZ HYP - Münster (Gf P2)',
  '40060560': 'Sparda-Bank West',
  '40061238': 'Volksbank Greven (Gf P2)',
  '40069226': 'Volksbank Lette-Darup-Rorup (Gf P2)',
  '40069266': 'Volksbank im Hochsauerland (Gf P2)',
  '40069283': 'Volksbank Schlangen (Gf P2)',
  '40069348': 'Volksbank Medebach (Gf P2)',
  '40069362': 'Volksbank (Gf P2)',
  '40069363': 'Volksbank Schermbeck (Gf P2)',
  '40069371': 'Volksbank Thülen (Gf P2)',
  '40069408': 'Volksbank Baumberge (Gf P2)',
  '40069477': 'Volksbank Wulfen -alt-',
  '40069546': 'Volksbank Senden (Gf P2)',
  '40069600': 'Volksbank Amelsbüren (Gf P2)',
  '40069601': 'Volksbank Ascheberg-Herbern (Gf P2)',
  '40069606': 'Volksbank Erle (Gf P2)',
  '40069622': 'Volksbank Seppenrade (Gf P2)',
  '40069709': 'Volksbank in der Hohen Mark (Gf P2)',
  '40069716': 'Volksbank Südkirchen-Capelle-Nordkirchen (Gf P2)',
  '40070024': 'Deutsche Bank',
  '40070080': 'Deutsche Bank',
  '40070211': 'Postbank/DSL Ndl der Deutsche Bank',
  '40070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '40080040': 'Commerzbank vormals Dresdner Bank',
  '40080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '40090900': 'PSD Bank Westfalen-Lippe (Gf P2)',
  '40150001': 'Helaba Düsseldorf Gf Verrechnung FI-Münster',
  '40153768': 'Verbundsparkasse Emsdetten Ochtrup',
  '40154006': 'Sparkasse Gronau',
  '40154476': 'Stadtsparkasse Lengerich',
  '40154530': 'Sparkasse Westmünsterland',
  '40154702': 'Stadtsparkasse Stadtlohn',
  '40160050': 'Vereinigte Volksbank Münster (Gf P2)',
  '40163720': 'Volksbank Nordmünsterland (Gf P2)',
  '40164024': 'Volksbank Vreden Ndl der VB Gronau-Ahaus',
  '40164256': 'Volksbank Laer-Horstmar-Leer (Gf P2)',
  '40164352': 'Volksbank Nottuln (Gf P2)',
  '40164528': 'Volksbank Südmünsterland-Mitte (Gf P2)',
  '40164618': 'Volksbank Ochtrup-Laer (Gf P2)',
  '40164901': 'Volksbank (Gf P2)',
  '40165366': 'Volksbank Selm-Bork (Gf P2)',
  '40166439': 'Volksbank Lengerich/Lotte -alt-',
  '40166800': 'Volksbank Buldern -alt-',
  '40340030': 'Commerzbank',
  '40350005': 'Stadtsparkasse Rheine',
  '40351060': 'Kreissparkasse Steinfurt',
  '40351220': 'Sparkasse Steinfurt -alt-',
  '40361627': 'Volksbank Westerkappeln-Saerbeck (Gf P2)',
  '40361906': 'Volksbank Münsterland Nord (Gf P2)',
  '40363433': 'Volksbank Hörstel -alt-',
  '40370024': 'Deutsche Bank',
  '40370079': 'Deutsche Bank',
  '41040018': 'Commerzbank',
  '41041000': 'ZTB der Commerzbank',
  '41050095': 'Sparkasse Hamm',
  '41051605': 'Stadtsparkasse Werne -alt-',
  '41051845': 'Sparkasse Bergkamen-Bönen',
  '41061011': 'Spar- und Darlehnskasse Bockum-Hövel (Gf P2)',
  '41061903': 'BAG Bankaktiengesellschaft',
  '41062215': 'Volksbank Bönen (Gf P2)',
  '41070024': 'Deutsche Bank',
  '41070049': 'Deutsche Bank',
  '41240048': 'Commerzbank',
  '41250035': 'Sparkasse Beckum-Wadersloh',
  '41260006': 'Volksbank Beckum (Gf P2)',
  '41261324': 'Volksbank Enniger-Ostenfelde-Westkirchen (Gf P2)',
  '41261419': 'Volksbank Oelde-Ennigerloh-Neubeckum (Gf P2)',
  '41262501': 'Volksbank (Gf P2)',
  '41280043': 'Commerzbank vormals Dresdner Bank',
  '41440018': 'Commerzbank',
  '41450075': 'Sparkasse SoestWerl',
  '41451750': 'Sparkasse Werl -alt-',
  '41460116': 'Volksbank Hellweg Zw Körbecke',
  '41462295': 'Volksbank Wickede (Ruhr) (Gf P2)',
  '41650001': 'Sparkasse Lippstadt',
  '41651770': 'Sparkasse Hochsauerland',
  '41651815': 'Sparkasse Erwitte-Anröchte',
  '41651965': 'Sparkasse Geseke',
  '41660124': 'Volksbank Beckum-Lippstadt Zndl',
  '41661206': 'Volksbank Rüthen Ndl der Volksbank Anröchte',
  '41661504': 'Volksbank Benninghausen (Gf P2)',
  '41661719': 'Volksbank Brilon (Gf P2)',
  '41662465': 'Volksbank Störmede-Hörste (Gf P2)',
  '41662557': 'Volksbank Warstein-Belecke -alt-',
  '41663335': 'Volksbank Hörste (Gf P2)',
  '41670024': 'Deutsche Bank',
  '41670027': 'Deutsche Bank',
  '41670028': 'Deutsche Bank',
  '41670029': 'Deutsche Bank',
  '41670030': 'Deutsche Bank',
  '42030600': 'Isbank Fil Gelsenkirchen',
  '42040040': 'Commerzbank Fil. Herten-Westerholt',
  '42050001': 'Sparkasse Gelsenkirchen',
  '42070024': 'Deutsche Bank',
  '42070062': 'Deutsche Bank',
  '42080082': 'Commerzbank vormals Dresdner Bank',
  '42260001': 'Volksbank Ruhr Mitte (Gf P2)',
  '42450040': 'Stadtsparkasse Gladbeck',
  '42451220': 'Sparkasse Bottrop',
  '42461435': 'Vereinte Volksbank (Gf P2)',
  '42640048': 'Commerzbank',
  '42650150': 'Sparkasse Vest Recklinghausen',
  '42651315': 'Stadtsparkasse Haltern am See',
  '42661008': 'Volksbank Marl-Recklinghausen Zw',
  '42661088': 'Volksbank Marl-Recklinghausen (Gf GAA)',
  '42661330': 'Volksbank Haltern (Gf P2)',
  '42661717': 'Volksbank Waltrop (Gf P2)',
  '42662320': 'Volksbank Dorsten (Gf P2)',
  '42680081': 'Commerzbank vormals Dresdner Bank',
  '42840005': 'Commerzbank',
  '42850035': 'Stadtsparkasse Bocholt',
  '42860003': 'Volksbank Bocholt (Gf P2)',
  '42861239': 'Spar- und Darlehnskasse (Gf P2)',
  '42861387': 'VR-Bank Westmünsterland (Gf P2)',
  '42861515': 'Volksbank Gemen (Gf P2)',
  '42861608': 'Volksbank Heiden (Gf P2)',
  '42861814': 'Volksbank Rhede Hzw Rhedebrügge',
  '42862451': 'Volksbank Raesfeld und Erle (Gf P2)',
  '42870024': 'Deutsche Bank',
  '42870077': 'Deutsche Bank',
  '43000000': 'Bundesbank eh Bochum',
  '43040036': 'Commerzbank',
  '43050001': 'Sparkasse Bochum',
  '43051040': 'Sparkasse Hattingen',
  '43060129': 'Volksbank Bochum Witten (Gf P2)',
  '43060967': 'GLS Gemeinschaftsbank (Gf P2)',
  '43060988': 'GLS Gemeinschaftsbank (GAA)',
  '43070024': 'Deutsche Bank',
  '43070061': 'Deutsche Bank',
  '43080083': 'Commerzbank vormals Dresdner Bank',
  '43250030': 'Herner Sparkasse',
  '44000000': 'Bundesbank eh Dortmund -alt-',
  '44010046': 'Postbank Ndl der Deutsche Bank',
  '44020090': 'UniCredit Bank - HypoVereinsbank',
  '44040037': 'Commerzbank',
  '44040060': 'Commerzbank CC',
  '44040061': 'Commerzbank CC',
  '44040085': 'Commerzbank, Gf Web-K',
  '44050000': 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
  '44050199': 'Sparkasse Dortmund',
  '44060122': 'Volksbank Dortmund-Nordwest (Gf P2)',
  '44064406': 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
  '44070024': 'Deutsche Bank',
  '44070050': 'Deutsche Bank',
  '44080050': 'Commerzbank vormals Dresdner Bank',
  '44080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '44080057': 'Commerzbank vormals Dresdner Bank Gf ZW 57',
  '44080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '44089320': 'Commerzbank vormals Dresdner Bank ITGK',
  '44090920': 'PSD Bank Dortmund -alt-',
  '44152370': 'Sparkasse an der Lippe',
  '44152490': 'Stadtsparkasse Schwerte -alt-',
  '44160014': 'Volksbank Waltrop Zndl d Dortmunder Volksbank',
  '44340037': 'Commerzbank',
  '44350060': 'Sparkasse UnnaKamen',
  '44351380': 'Sparkasse Kamen -alt-',
  '44351740': 'Sparkasse Fröndenberg -alt-',
  '44361342': 'Volksbank Kamen-Werne (Gf P2)',
  '44540022': 'Commerzbank',
  '44550045': 'Sparkasse der Stadt Iserlohn',
  '44551210': 'Sparkasse Märkisches Sauerland Hemer-Menden',
  '44570004': 'Deutsche Bank',
  '44570024': 'Deutsche Bank',
  '44580070': 'Commerzbank vormals Dresdner Bank',
  '44580085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '44761312': 'Mendener Bank (Gf P2)',
  '44761534': 'Volksbank in Südwestfalen (Gf P2)',
  '45000000': 'Bundesbank eh Hagen',
  '45040042': 'Commerzbank',
  '45050001': 'Sparkasse an Volme und Ruhr',
  '45051485': 'Stadtsparkasse Herdecke -alt-',
  '45060009': 'Volksbank Menden Zndl der Märkischen Bank',
  '45061524': 'Volksbank Hohenlimburg Zw',
  '45070002': 'Deutsche Bank',
  '45070024': 'Deutsche Bank',
  '45080060': 'Commerzbank vormals Dresdner Bank',
  '45240056': 'Commerzbank',
  '45250035': 'Sparkasse Witten',
  '45251480': 'Stadtsparkasse Wetter -alt-',
  '45251515': 'Stadtsparkasse Sprockhövel -alt-',
  '45260041': 'Volksbank Witten -alt-',
  '45260475': 'Spar- u Kreditbank d Bundes Fr ev Gemeinden (Gf P2)',
  '45261547': 'Volksbank Sprockhövel (Gf P2)',
  '45450050': 'Sparkasse an Ennepe und Ruhr',
  '45451060': 'Sparkasse Ennepetal-Breckerfeld -alt-',
  '45451555': 'Sparkasse Schwelm-Sprockhövel',
  '45660029': 'Volksbank Altena -alt-',
  '45840026': 'Commerzbank',
  '45841031': 'Commerzbank',
  '45850005': 'Sparkasse Lüdenscheid',
  '45851020': 'Vereinigte Sparkasse im Märkischen Kreis',
  '45851665': 'Sparkasse Kierspe-Meinerzhagen',
  '45860033': 'Volksbank Lüdenscheid -alt- (Gf P2)',
  '45861434': 'Volksbank Kierspe (Gf P2)',
  '45861617': 'Volksbank Meinerzhagen -alt- (Gf P2)',
  '46040033': 'Commerzbank',
  '46050001': 'Sparkasse Siegen',
  '46051240': 'Sparkasse Burbach-Neunkirchen',
  '46051733': 'Stadtsparkasse Freudenberg -alt-',
  '46051875': 'Stadtsparkasse Hilchenbach -alt-',
  '46052855': 'Stadtsparkasse Schmallenberg -alt-',
  '46053480': 'Sparkasse Wittgenstein',
  '46060040': 'Volksbank Siegerland (Gf P2)',
  '46061724': 'VR-Bank Freudenberg-Niederfischbach (Gf P2)',
  '46062817': 'Volksbank Sauerland (Gf P2)',
  '46063405': 'Volksbank Wittgenstein Zw',
  '46070024': 'Deutsche Bank',
  '46070090': 'Deutsche Bank',
  '46080010': 'Commerzbank vormals Dresdner Bank',
  '46240016': 'Commerzbank',
  '46250049': 'Sparkasse Olpe-Drolshagen-Wenden',
  '46251590': 'Sparkasse Finnentrop -alt-',
  '46251630': 'Sparkasse Attendorn-Lennestadt-Kirchhundem',
  '46260023': 'Volksbank Olpe (Gf P2)',
  '46261607': 'Volksbank Grevenbrück (Gf P2)',
  '46261822': 'Volksbank Wenden-Drolshagen',
  '46262456': 'Volksbank Bigge-Lenne (Gf P2)',
  '46441003': 'Commerzbank',
  '46451012': 'Sparkasse Mitten im Sauerland',
  '46451250': 'Sparkasse Bestwig -alt-',
  '46461126': 'Volksbank Sauerland (Gf P2)',
  '46462271': 'Spar- und Darlehnskasse Oeventrop (Gf P2)',
  '46464453': 'Volksbank Reiste-Eslohe (Gf P2)',
  '46640018': 'Commerzbank',
  '46650005': 'Sparkasse Arnsberg-Sundern',
  '46660022': 'Volksbank Sauerland (Gf P2)',
  '46670007': 'Deutsche Bank',
  '46670024': 'Deutsche Bank',
  '46670204': 'Postbank/DSL Ndl der Deutsche Bank',
  '46670224': 'Postbank/DSL Ndl der Deutsche Bank',
  '47000000': 'Bundesbank Dortmund',
  '47240047': 'Commerzbank',
  '47250101': 'Sparkasse Paderborn -alt-',
  '47251550': 'Sparkasse Höxter',
  '47251740': 'Stadtsparkasse Delbrück',
  '47260121': 'VerbundVolksbank OWL (Gf P2)',
  '47260234': 'Volksbank Elsen-Wewer-Borchen (Gf P2)',
  '47260307': 'Bank für Kirche und Caritas (Gf P2)',
  '47261429': 'Volksbank Haaren -alt-',
  '47261603': 'Volksbank Brilon-Büren-Salzkotten (Gf P2)',
  '47262626': 'Volksbank Westenholz (Gf P2)',
  '47262703': 'Volksbank Delbrück-Hövelhof (Gf P2)',
  '47264367': 'Vereinigte Volksbank (Gf P2)',
  '47265383': 'Volksbank Wewelsburg-Ahden (Gf P2)',
  '47267216': 'Volksbank Borgentreich -alt-',
  '47270024': 'Deutsche Bank',
  '47270029': 'Deutsche Bank',
  '47460028': 'Volksbank Warburger Land (Gf P2)',
  '47640051': 'Commerzbank',
  '47650130': 'Sparkasse Paderborn-Detmold',
  '47651225': 'Stadtsparkasse Blomberg -alt-',
  '47670023': 'Deutsche Bank',
  '47670024': 'Deutsche Bank',
  '47670205': 'Postbank/DSL Ndl der Deutsche Bank',
  '47670224': 'Postbank/DSL Ndl der Deutsche Bank',
  '47691200': 'Volksbank Ostlippe Zndl. Schieder-Schwalenberg',
  '47840065': 'Commerzbank',
  '47840080': 'Commerzbank Zw 80',
  '47850065': 'Sparkasse Gütersloh-Rietberg-Versmold',
  '47852760': 'Sparkasse Rietberg -alt-',
  '47853355': 'Stadtsparkasse Versmold -alt-',
  '47853520': 'Kreissparkasse Wiedenbrück',
  '47860125': 'Volksbank Bielefeld-Gütersloh Zndl',
  '47861317': 'Volksbank im Ostmünsterland -alt-',
  '47861518': 'Volksbank Harsewinkel -alt-',
  '47862261': 'Volksbank Marienfeld -alt-',
  '47862447': 'Volksbank Rietberg (Gf P2)',
  '47863373': 'Volksbank Versmold (Gf P2)',
  '47880031': 'Commerzbank vormals Dresdner Bank',
  '48000000': 'Bundesbank',
  '48020086': 'UniCredit Bank - HypoVereinsbank',
  '48020151': 'Hauck Aufhäuser Lampe Privatbank',
  '48021900': 'Bankverein Werther Zw Ndl der VerbundVolksbank OWL',
  '48040035': 'Commerzbank',
  '48040060': 'Commerzbank CC',
  '48040061': 'Commerzbank CC',
  '48050161': 'Sparkasse Bielefeld',
  '48051580': 'Kreissparkasse Halle',
  '48060036': 'Bielefelder Volksbank (Gf P2)',
  '48062051': 'Volksbank Halle/Westf (Gf P2)',
  '48062466': 'Spar-u Darlehnskasse Schloß Holte-Stukenbrock (Gf P2)',
  '48070020': 'Deutsche Bank',
  '48070024': 'Deutsche Bank',
  '48070040': 'Deutsche Bank',
  '48070042': 'Deutsche Bank',
  '48070043': 'Deutsche Bank',
  '48070044': 'Deutsche Bank',
  '48070045': 'Deutsche Bank',
  '48070050': 'Deutsche Bank',
  '48070052': 'Deutsche Bank',
  '48080020': 'Commerzbank vormals Dresdner Bank',
  '48089350': 'Commerzbank vormals Dresdner Bank ITGK',
  '48250110': 'Sparkasse Lemgo',
  '48291490': 'Volksbank Kalletal Ndl d VB Bad Salzuflen',
  '49040043': 'Commerzbank',
  '49050101': 'Sparkasse Minden-Lübbecke',
  '49051065': 'Stadtsparkasse Rahden',
  '49051285': 'Sparkasse Bad Oeynhausen-Porta Westfalica',
  '49051990': 'Stadtsparkasse Porta Westfalica -alt-',
  '49060127': 'Volksbank Mindener Land (Gf P2)',
  '49060392': 'Volksbank Minden (Gf P2)',
  '49061470': 'Volksbank Stemweder Berg -alt-',
  '49061510': 'Volksbank Eisbergen (Gf P2)',
  '49070024': 'Deutsche Bank',
  '49070028': 'Deutsche Bank',
  '49080025': 'Commerzbank vormals Dresdner Bank',
  '49092650': 'Volksbank Lübbecker Land (Gf P2)',
  '49240096': 'Commerzbank',
  '49262364': 'Volksbank Schnathorst (Gf P2)',
  '49440043': 'Commerzbank',
  '49450120': 'Sparkasse Herford',
  '49490070': 'Volksbank Herford-Mindener Land (Gf P2)',
  '50000000': 'Bundesbank',
  '50010060': 'Postbank Ndl der Deutsche Bank',
  '50010200': 'AKBANK',
  '50010300': 'First Commercial Bank, Frankfurt Branch',
  '50010424': 'Aareal Bank ehem. Filiale Frankfurt',
  '50010517': 'ING-DiBa',
  '50010700': 'Degussa Bank',
  '50010900': 'Bank of America Europe, Frankfurt Branch',
  '50010910': 'Bank of America Europe, Frankfurt Branch',
  '50012800': 'ALTE LEIPZIGER Bauspar',
  '50015001': 'J&T BANKA, Zweigniederlassung Deutschland',
  '50016600': 'Western Union Intl. Bank Niederlassung Deutschland',
  '50020000': 'SÜDWESTBANK - BAWAG Niederlassung Deutschland',
  '50020200': 'ODDO BHF',
  '50020400': 'KfW Kreditanstalt für Wiederaufbau Frankfurt',
  '50020500': 'Landwirtschaftliche Rentenbank',
  '50020700': 'Credit Europe Bank Ndl. Deutschland',
  '50020800': 'Intesa Sanpaolo Frankfurt',
  '50021000': 'ING Bank',
  '50021100': 'FIL Fondsbank',
  '50021120': 'FIL Fondsbank',
  '50022200': 'Hyundai Capital Bank Europe',
  '50024024': 'C24 Bank',
  '50025000': 'Opel Bank, Niederlassung Deutschland',
  '50030000': 'PSA Bank Deutschland',
  '50030010': 'PSA Bank Deutschland',
  '50030500': 'BNP Paribas Niederlassung Deutschland',
  '50030600': 'Deutsche WertpapierService Bank',
  '50030700': 'DenizBank (Wien) Zw Frankfurt',
  '50031000': 'Triodos Bank Deutschland (Gf P2)',
  '50033300': 'Santander Consumer Bank',
  '50038800': 'Agricultural Bank of China, Frankfurt Branch',
  '50040000': 'Commerzbank',
  '50040005': 'Commerzbank, Filiale Frankfurt 2',
  '50040010': 'Commerzbank (IPC PARIS)',
  '50040011': 'Commerzbank (IPC VIENNA)',
  '50040012': 'Commerzbank (IPC MILANO)',
  '50040013': 'Commerzbank (IPC AMSTERDAM)',
  '50040014': 'Commerzbank (IPC BRUSSEL)',
  '50040015': 'Commerzbank (IPC MADRID)',
  '50040016': 'Commerzbank (CLB New York) FFM',
  '50040017': 'Commerzbank (CLB Singapore) Frankfurt',
  '50040018': 'Commerzbank (CLB London)',
  '50040019': 'Commerzbank (CLB Zürich)',
  '50040020': 'Commerzbank (CLB Prag)',
  '50040021': 'Commerzbank (CLB Moskau)',
  '50040022': 'Commerzbank (CLB Hamburg)',
  '50040033': 'Commerzbank Gf BRS',
  '50040038': 'Commerzbank, MBP',
  '50040040': 'Commerzbank Gf ZRK',
  '50040048': 'Commerzbank',
  '50040050': 'Commerzbank, CC SP',
  '50040051': 'Commerzbank Center Dresdner Bank Frankfurt',
  '50040052': 'Commerzbank Service - BZ Frankfurt',
  '50040060': 'Commerzbank Gf 460',
  '50040061': 'Commerzbank Gf 461',
  '50040062': 'Commerzbank CC',
  '50040063': 'Commerzbank CC',
  '50040075': 'Commerzbank Gf ZCM',
  '50040084': 'Commerzbank, GF Web-K CMTS2',
  '50040085': 'Commerzbank, Gf Web-K',
  '50040086': 'Commerzbank, GF Web-K CMTS',
  '50040087': 'Commerzbank, Gf Web-K CMTS3',
  '50040088': 'Commerzbank, INT 1',
  '50040099': 'Commerzbank INT',
  '50042500': 'Commerzbank Zw 425 - keine Auslandsbanken',
  '50044444': 'Commerzbank Vermögensverwaltung',
  '50047010': 'Commerzbank Service - BZ',
  '50050000': 'Landesbank Hessen-Thür Girozentrale',
  '50050201': 'Frankfurter Sparkasse Sprendlingen',
  '50050222': 'Frankfurter Sparkasse GF 1822direkt',
  '50050999': 'DekaBank Frankfurt',
  '50060000': 'DZ Bank',
  '50060400': 'DZ BANK (Gf P2)',
  '50060412': 'DZ BANK GF DZH',
  '50060413': 'DZ BANK GF ODA',
  '50060414': 'DZ BANK für Bausparkasse Schwäbisch Hall',
  '50060415': 'DZ BANK für Bausparkasse Schwäbisch Hall',
  '50060416': 'DZ BANK GF TSG',
  '50060417': 'DZ BANK GF BMT',
  '50060418': 'DZ BANK GF BMT',
  '50060419': 'DZ BANK GF VRF',
  '50060420': 'DZ BANK GF BSH',
  '50060421': 'DZ BANK GF BSH',
  '50060422': 'DZ BANK GF GRX',
  '50060423': 'DZ BANK GF GRX',
  '50060424': 'DZ BANK GF DEV',
  '50060425': 'DZ BANK GF BSH',
  '50060474': 'DZ BANK, Deutsche Zentral-Genossenschaftsbank',
  '50061741': 'Raiffeisenbank im Hochtaunus (Gf P2)',
  '50069126': 'VR Bank Alzey-Land-Schwabenheim (Gf P2)',
  '50069146': 'Volksbank Grebenhain (Gf P2)',
  '50069187': 'Volksbank Egelsbach -alt-',
  '50069241': 'Raiffeisenkasse Erbes-Büdesheim und Umgebung (Gf P2)',
  '50069345': 'Raiffeisenbank (Gf P2)',
  '50069455': 'Hüttenberger Bank (Gf P2)',
  '50069477': 'Raiffeisenbank Kirtorf (Gf P2)',
  '50069693': 'Raiffeisenbank Bad Homburg Ndl d FrankfurterVB',
  '50069842': 'Raiffeisen Volksbank (Gf P2)',
  '50069976': 'Volksbank Wißmar (Gf P2)',
  '50070010': 'Deutsche Bank',
  '50070011': 'Deutsche Bank',
  '50070024': 'Deutsche Bank',
  '50070050': 'Deutsche Bank',
  '50070051': 'Deutsche Bank',
  '50070052': 'Deutsche Bank',
  '50070053': 'Deutsche Bank',
  '50070054': 'Deutsche Bank',
  '50070055': 'Deutsche Bank',
  '50070056': 'Deutsche Bank',
  '50070057': 'Deutsche Bank',
  '50070058': 'Deutsche Bank',
  '50070059': 'Deutsche Bank',
  '50070060': 'Deutsche Bank',
  '50070061': 'Deutsche Bank',
  '50070062': 'Deutsche Bank',
  '50070063': 'Deutsche Bank',
  '50070064': 'Deutsche Bank',
  '50070065': 'Deutsche Bank',
  '50070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '50070371': 'Postbank/DSL Ndl der Deutsche Bank',
  '50070435': 'Deutsche Bank',
  '50070436': 'Deutsche Bank',
  '50070437': 'Deutsche Bank',
  '50070438': 'Deutsche Bank',
  '50070439': 'Deutsche Bank',
  '50073019': 'Deutsche Bank',
  '50073024': 'Deutsche Bank',
  '50073081': 'Deutsche Bank Europe',
  '50080000': 'Commerzbank vormals Dresdner Bank Fil Limesstadt',
  '50080015': 'Commerzbank vormals Dresdner Bank Zw 15',
  '50080025': 'Commerzbank vormals Dresdner Bank Zw 25',
  '50080035': 'Commerzbank vormals Dresdner Bank Zw 35',
  '50080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '50080057': 'Commerzbank vormals Dresdner Bank Gf ZW 57',
  '50080060': 'Commerzbank vormals Dresdner Bank Gf DrKW',
  '50080061': 'Commerzbank vormals Dresdner Bank Gf DrKWSL',
  '50080077': 'Commerzbank, GF Wüstenrot BSPK',
  '50080079': 'Commerzbank vormals Dresdner Bank ESOP',
  '50080080': 'Commerzbank vormals Dresdner Bank Bs 80',
  '50080082': 'Commerzbank vormals Dresdner Bank Gf AVB',
  '50080086': 'Commerzbank vormals Dresdner Bank ITGK 3',
  '50080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '50080088': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '50080089': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
  '50080091': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
  '50080092': 'Commerzbank vormals Dresdner Bank Finance and Controlling',
  '50080099': 'Commerzbank vormals Dresdner Bank Zw 99',
  '50080300': 'Commerzbank vormals Dresdner Bank Private Banking Inland',
  '50083007': 'Commerzbank vormals Dresdner Bank',
  '50083838': 'Commerzbank vormals Dresdner Bank in Frankfurt MBP',
  '50089400': 'Commerzbank vormals Dresdner Bank ITGK',
  '50090500': 'Sparda-Bank Hessen',
  '50090900': 'PSD Bank Hessen-Thüringen Beratungscenter Erfurt',
  '50092100': 'Spar- u Kreditbank ev-freikirchl Gemeinden (Gf P2)',
  '50092200': 'Volksbank Main-Taunus (Gf P2)',
  '50092900': 'Volksbank Usinger Land Ndl d Frankfurter VB',
  '50093000': 'Rüsselsheimer Volksbank Ndl Raunheim',
  '50093010': 'Rüsselsheimer Volksbank GAA -alt-',
  '50093400': 'Volksbank Kelsterbach Ndl d Frankfurter VB',
  '50110200': 'Industrial and Commercial Bank of China',
  '50110400': 'AKA Ausfuhrkredit GmbH',
  '50110500': 'NATIXIS Zweigniederlassung Deutschland',
  '50110636': 'Standard Chartered Bank Frankfurt',
  '50110700': 'Frankfurter Bankgesellschaft (Deutschland)',
  '50110800': 'J.P. Morgan',
  '50110801': 'J.P. Morgan, Internal Reference',
  '50110855': 'J.P. Morgan',
  '50110900': 'Bank of America N.A. Military Bank',
  '50120000': 'Stifel Europe Bank',
  '50120100': 'ICICI Bank UK, Germany Branch',
  '50120383': 'ABN AMRO Bank Frankfurt Branch - Bethmann Bank',
  '50120500': 'Credit Suisse (Deutschland)',
  '50120600': 'Bank of Communications Frankfurt branch',
  '50120800': 'AION Bank German Branch',
  '50120900': 'VakifBank International Wien Zndl Deutschland',
  '50123400': 'VTB Bank (Europe) Zndl Frankfurt am Main (Direktbank)',
  '50127000': 'PKO Bank Polski Niederlassung Deutschland',
  '50130000': 'National Bank of Pakistan Zndl Frankfurt',
  '50130400': 'Merck Finck A Quintet Private Bank',
  '50130600': 'UBS Europe',
  '50131000': 'Vietnam Joint Stock Commercial Bk f.Industry a. T. (Gf P2)',
  '50190000': 'Frankfurter Volksbank Rhein-Main (Gf P2)',
  '50190300': 'Volksbank Höchst a.M., ZwNdl. der Frankfurter VB (Gf P2)',
  '50190400': 'Volksbank Griesheim (Gf P2)',
  '50210200': 'Rabobank International Frankfurt Branch',
  '50210295': 'Rabobank International Frankfurt Branch',
  '50210800': 'ProCredit Bank, Frankfurt am Main',
  '50210900': 'Citibank Europe Germany Branch',
  '50220085': 'UBS Europe',
  '50220101': 'Kommunalkredit Austria, Zweigstelle Deutschland',
  '50220300': 'WOORI BANK EUROPE',
  '50220500': 'Lloyds Bank',
  '50220707': 'CAIXABANK Zweigniederlassung Deutschland',
  '50220900': 'Hauck Aufhäuser Lampe Privatbank',
  '50230000': 'Arab Banking Corporation Zweigniederlassung Frankfurt',
  '50230600': 'Isbank',
  '50230700': 'Metzler, B. - seel Sohn & Co',
  '50230800': 'Ikano Bank',
  '50230888': 'Ikano Bank',
  '50234500': 'KT Bank',
  '50250200': 'Deutsche Leasing Finance',
  '50310400': 'Barclays Bank Ireland Frankfurt Branch',
  '50310900': 'China Construction Bank Ndl Frankfurt',
  '50320000': 'VTB Bank (Europe)',
  '50320191': 'UniCredit Bank - HypoVereinsbank',
  '50320500': 'Banco Santander Filiale Frankfurt',
  '50320600': 'Attijariwafa bank Europa ZNdl. Frankfurt',
  '50320900': 'Pictet & Cie (Europe) Ndl Deutschland',
  '50324000': 'ABN AMRO Bank, Frankfurt Branch',
  '50330000': 'State Bank of India',
  '50330200': 'Raisin Bank',
  '50330201': 'Raisin Bank',
  '50330300': 'The Bank of New York Mellon',
  '50330500': 'BANQUE CHAABI DU MAROC Agentur Frankfurt Ndl. Deutschland',
  '50330600': 'Bank Sepah-Iran',
  '50334400': 'The Bank of New York Mellon NL Frankfurt',
  '50339900': 'German branch of Unifiedpost Payments',
  '50400000': 'Bundesbank Zentrale',
  '50510300': 'SMBC Bank EU',
  '50520190': 'UniCredit Bank - HypoVereinsbank',
  '50522222': 'FIDOR Bank Zndl Frankfurt am Main',
  '50530000': 'Cronbank (Gf P2)',
  '50540028': 'Commerzbank',
  '50550020': 'Städtische Sparkasse Offenbach a.M.',
  '50560102': 'Raiffeisenbank Offenbach/M.-Bieber (Gf P2)',
  '50561315': 'Vereinigte Volksbank Maingau (Gf P2)',
  '50570018': 'Deutsche Bank',
  '50570024': 'Deutsche Bank',
  '50580005': 'Commerzbank vormals Dresdner Bank',
  '50580085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '50590000': 'Offenbacher Volksbank -alt-',
  '50592200': 'VR Bank Dreieich-Offenbach (Gf P2)',
  '50640015': 'Commerzbank',
  '50650023': 'SPARKASSE HANAU',
  '50652124': 'Sparkasse Langen-Seligenstadt',
  '50661639': 'VR Bank Main-Kinzig-Büdingen (Gf P2)',
  '50661816': 'Volksbank Heldenbergen Ndl d Frankfurter VB',
  '50662299': 'Raiffeisenbank Zw',
  '50662669': 'Raiffeisenbank Maintal Ndl d Frankfurter VB',
  '50663699': 'Raiffeisenbank (Gf P2)',
  '50670009': 'Deutsche Bank',
  '50670024': 'Deutsche Bank',
  '50680002': 'Commerzbank vormals Dresdner Bank',
  '50680085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '50690000': 'Volksbank Raiffeisenbank Hanau Ndl d Frankf VB',
  '50691300': 'DZB Bank (Gf P2)',
  '50692100': 'Volksbank Seligenstadt (Gf P2)',
  '50740048': 'Commerzbank',
  '50750094': 'Kreissparkasse Gelnhausen',
  '50761333': 'Volksbank (Gf P2)',
  '50763319': 'Raiffeisenbank Vogelsberg Zw Kefenrod',
  '50780006': 'Commerzbank vormals Dresdner Bank',
  '50790000': 'VR Bank Bad Orb-Gelnhausen (Gf P2)',
  '50793300': 'Birsteiner Volksbank (Gf P2)',
  '50794300': 'VR Bank Wächtersbach/Bad Soden-Salmünster -alt',
  '50810900': 'Deutsche Bausparkasse Badenia (ehem. DBS Bausparkasse)',
  '50820292': 'UniCredit Bank - HypoVereinsbank',
  '50835800': 'MCE Bank',
  '50840005': 'Commerzbank',
  '50850049': 'Landesbank Hessen-Thür Girozentrale',
  '50850150': 'Stadt- und Kreis-Sparkasse Darmstadt',
  '50851952': 'Sparkasse Odenwaldkreis',
  '50852553': 'Kreissparkasse Groß-Gerau',
  '50852651': 'Sparkasse Dieburg',
  '50861393': 'Spar- und Darlehnskasse Zell -alt-',
  '50861501': 'Raiffeisenbank Nördliche Bergstraße (Gf P2)',
  '50862311': 'Volksbank Gräfenhausen (Gf P2)',
  '50862408': 'Vereinigte Volksbank Griesheim-Weiterstadt (Gf P2)',
  '50862703': 'Volksbank Gersprenztal-Otzberg',
  '50862835': 'Raiffeisenbank Schaafheim (Gf P2)',
  '50862903': 'Volksbank Mainspitze (Gf P2)',
  '50863513': 'Vereinigte Volksbank Raiffeisenbank (Gf P2)',
  '50865224': 'VB Mörfelden-Walldorf Ndl d Frankfurter VB',
  '50865503': 'Volksbank (Gf P2)',
  '50870005': 'Deutsche Bank',
  '50870024': 'Deutsche Bank',
  '50870324': 'Postbank/DSL Ndl der Deutsche Bank',
  '50870393': 'Postbank/DSL Ndl der Deutsche Bank',
  '50880050': 'Commerzbank vormals Dresdner Bank',
  '50880085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '50880086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '50890000': 'Volksbank Darmstadt - Südhessen (Gf P2)',
  '50950068': 'Sparkasse Bensheim',
  '50951469': 'Sparkasse Starkenburg',
  '50961206': 'Raiffeisenbank Ried Bürstadt',
  '50961312': 'Raiffeisenbank Groß-Rohrheim (Gf P2)',
  '50961592': 'Volksbank Weschnitztal (Gf P2)',
  '50961685': 'Volksbank Überwald-Gorxheimertal (Gf P2)',
  '50970004': 'Deutsche Bank',
  '50970024': 'Deutsche Bank',
  '51010400': 'Aareal Bank',
  '51020000': 'BHF-BANK',
  '51020186': 'UniCredit Bank - HypoVereinsbank',
  '51040038': 'Commerzbank',
  '51050015': 'Nassauische Sparkasse',
  '51051000': 'S Broker Wiesbaden',
  '51070021': 'Deutsche Bank',
  '51070024': 'Deutsche Bank',
  '51080060': 'Commerzbank vormals Dresdner Bank',
  '51080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '51080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK2',
  '51089410': 'Commerzbank vormals Dresdner Bank ITGK',
  '51090000': 'Wiesbadener Volksbank (Gf P2)',
  '51091500': 'Rheingauer Volksbank (Gf P2)',
  '51091501': 'Rheingauer Volksbank (Gf GAA)',
  '51091700': 'vr bank Untertaunus (Gf P2)',
  '51091711': 'vr bank Untertaunus (Gf P2)',
  '51140029': 'Commerzbank',
  '51150018': 'Kreissparkasse Limburg',
  '51151919': 'Kreissparkasse Weilburg',
  '51161606': 'Volksbank Langendernbach (Gf P2)',
  '51170010': 'Deutsche Bank',
  '51170024': 'Deutsche Bank',
  '51180041': 'Commerzbank vormals Dresdner Bank',
  '51190000': 'Vereinigte Volksbank Limburg (Gf P2)',
  '51191800': 'Volksbank Schupbach (Gf P2)',
  '51192200': 'Volks- und Raiffeisenbank Weilmünster -alt-',
  '51210600': 'BNP Paribas Niederlassung Deutschland',
  '51210606': 'BNP Paribas Niederlassung Deutschland',
  '51210699': 'BNP Paribas Niederlassung Deutschland',
  '51210700': 'NIBC Bank Zndl Frankfurt am Main',
  '51210800': 'Societe Generale',
  '51210801': 'Societe Generale',
  '51210802': 'Societe Generale',
  '51210803': 'Societe Generale',
  '51211000': 'NATIXIS Pfandbriefbank',
  '51220200': 'Skandinaviska Enskilda Banken (publ) Frankfurt Branch',
  '51220400': 'Bank Saderat Iran',
  '51220700': 'ZIRAAT BANK International',
  '51220800': 'Banco do Brasil',
  '51220900': 'Morgan Stanley Bank',
  '51220910': 'Morgan Stanley Bank',
  '51230500': 'Standard Chartered Bank',
  '51230502': 'Standard Chartered Bank',
  '51230555': 'Ikano Bank',
  '51230600': 'Europe ARAB Bank',
  '51250000': 'Taunus-Sparkasse',
  '51300000': 'Bundesbank eh Gießen',
  '51340013': 'Commerzbank',
  '51343224': 'Commerzbank',
  '51350025': 'Sparkasse Gießen',
  '51351526': 'Sparkasse Grünberg',
  '51352227': 'Sparkasse Laubach-Hungen',
  '51361021': 'Volksbank Heuchelheim (Gf P2)',
  '51370008': 'Deutsche Bank',
  '51370024': 'Deutsche Bank',
  '51380040': 'Commerzbank vormals Dresdner Bank',
  '51380085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '51390000': 'Volksbank Mittelhessen (Gf P2)',
  '51410600': 'Bank of America',
  '51410700': 'Bank of China',
  '51410800': 'Commerzbank - GF onvista',
  '51420200': 'Misr Bank-Europe',
  '51420300': 'Bank Julius Bär Deutschland',
  '51430400': 'Goldman Sachs',
  '51430410': 'Goldman Sachs',
  '51432100': 'Saman Bank Niederlassung Frankfurt',
  '51540037': 'Commerzbank',
  '51550035': 'Sparkasse Wetzlar',
  '51570008': 'Deutsche Bank',
  '51570024': 'Deutsche Bank',
  '51580044': 'Commerzbank vormals Dresdner Bank',
  '51591300': 'Volksbank Brandoberndorf (Gf P2)',
  '51640043': 'Commerzbank',
  '51650045': 'Sparkasse Dillenburg',
  '51690000': 'Volksbank Dill (Gf P2)',
  '51691500': 'Volksbank Herborn-Eschenburg Zw Sinn',
  '51752267': 'Sparkasse Battenberg',
  '51762434': 'VR Bank Lahn-Dill (Gf P2)',
  '51850079': 'Sparkasse Oberhessen',
  '51861325': 'BVB Volksbank Ndl d Frankfurter Volksbank',
  '51861403': 'Volksbank Butzbach (Gf P2)',
  '51861616': 'Landbank Horlofftal (Gf P2)',
  '51861806': 'Volksbank Ober-Mörlen (Gf P2)',
  '51961023': 'Volksbank (Gf P2)',
  '51961515': 'Spar- und Darlehnskasse Stockhausen (Gf P2)',
  '51961801': 'Volksbank Feldatal (Gf P2)',
  '51990000': 'Volksbank Lauterbach-Schlitz (Gf P2)',
  '52040021': 'Commerzbank',
  '52050000': 'Landeskreditkasse Kassel',
  '52050353': 'Kasseler Sparkasse',
  '52051373': 'Stadtsparkasse Borken (Hessen)',
  '52051555': 'Stadtsparkasse Felsberg -alt-',
  '52051877': 'Stadtsparkasse Grebenstein',
  '52052154': 'Kreissparkasse Schwalm-Eder',
  '52053458': 'Stadtsparkasse Schwalmstadt',
  '52060000': 'DZ BANK',
  '52060208': 'Kurhessische Landbank (Gf P2)',
  '52060410': 'Evangelische Bank Filiale Stuttgart',
  '52060420': 'Evangelische Bank (Gf GAA)',
  '52061303': 'Raiffeisenbank Borken Nordhessen (Gf P2)',
  '52062200': 'VR-Bank Chattengau (Gf P2)',
  '52062601': 'VR PartnerBank Chattengau-Schwalm-Eder (Gf P2)',
  '52063369': 'VR-Bank Spangenberg-Morschen (Gf P2)',
  '52063550': 'Raiffeisenbank HessenNord (Gf P2)',
  '52064156': 'Raiffeisenbank Zw Schauenburg',
  '52069013': 'Raiffeisenbank Burghaun (Gf P2)',
  '52069029': 'Spar-u. Kredit-Bank (Gf P2)',
  '52069065': 'Raiffeisenbank Langenschwarz (Gf P2)',
  '52069149': 'Raiffeisenbank Volkmarsen Zw',
  '52069519': 'Frankenberger Bank Raiffeisenbank (Gf P2)',
  '52070012': 'Deutsche Bank',
  '52070024': 'Deutsche Bank',
  '52071212': 'Deutsche Bank',
  '52071224': 'Deutsche Bank',
  '52080080': 'Commerzbank vormals Dresdner Bank',
  '52080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK1',
  '52090000': 'Volksbank Kassel Göttingen (Gf P2)',
  '52240006': 'Commerzbank',
  '52250030': 'Sparkasse Werra-Meißner',
  '52260385': 'VR-Bank Mitte (Gf P2)',
  '52270012': 'Deutsche Bank',
  '52270024': 'Deutsche Bank',
  '52350005': 'Sparkasse Waldeck Frankenberg',
  '52360059': 'Waldecker Bank Zw',
  '52410300': 'ReiseBank',
  '52410310': 'ReiseBank Gf2',
  '52410400': 'KEB Hana Bank (Deutschland)',
  '52411000': 'ReiseBank Gf3 vormals Cash Express',
  '52411010': 'ReiseBank Gf4 vormals Cash Express',
  '52420000': 'Credit Agricole CIB Deutschland',
  '52420300': 'SHINHAN BANK EUROPE',
  '52420600': 'Piraeus Bank Frankfurt Branch',
  '52420700': 'SECB Swiss Euro Clearing Bank',
  '52430000': 'TARGOBANK FIRMENKUNDEN CIB',
  '53040012': 'Commerzbank',
  '53050180': 'Sparkasse Fulda',
  '53051396': 'Kreissparkasse Schlüchtern',
  '53060180': 'VR Bank Fulda (Gf P2)',
  '53061230': 'VR-Bank NordRhön (Gf P2)',
  '53061313': 'VR Bank Schlüchtern-Birstein (Gf P2)',
  '53062035': 'Raiffeisenbank im Fuldaer Land Zw Hosenfeld',
  '53062350': 'Raiffeisenbank Biebergrund-Petersberg Zw',
  '53064023': 'Raiffeisenbank (Gf P2)',
  '53070007': 'Deutsche Bank',
  '53070024': 'Deutsche Bank',
  '53070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '53070394': 'Postbank/DSL Ndl der Deutsche Bank',
  '53080030': 'Commerzbank vormals Dresdner Bank',
  '53093200': 'VR Bank HessenLand (Gf P2)',
  '53093255': 'AgrarBank',
  '53240048': 'Commerzbank',
  '53250000': 'Sparkasse Bad Hersfeld-Rotenburg',
  '53260145': 'Raiffeisenbank Asbach-Sorga (Gf P2)',
  '53261202': 'Bankverein Bebra (Gf P2)',
  '53261342': 'Raiffeisenbank Werratal-Landeck (Gf P2)',
  '53262073': 'Raiffeisenbank Haunetal Zw',
  '53262455': 'Raiffeisenbank Ronshausen-Marksuhl (Gf P2)',
  '53270012': 'Deutsche Bank',
  '53270024': 'Deutsche Bank',
  '53280081': 'Commerzbank vormals Dresdner Bank',
  '53290000': 'VR-Bankverein Bad Hersfeld-Rotenburg (Gf P2)',
  '53340024': 'Commerzbank',
  '53350000': 'Sparkasse Marburg-Biedenkopf',
  '53361724': 'Raiffeisenbank (Gf P2)',
  '53370008': 'Deutsche Bank',
  '53370024': 'Deutsche Bank',
  '53380042': 'Commerzbank vormals Dresdner Bank',
  '53381843': 'Commerzbank vormals Dresdner Bank',
  '54020090': 'UniCredit Bank - HypoVereinsbank',
  '54030011': 'Service Credit Union Overseas Headquarters',
  '54040042': 'Commerzbank',
  '54050110': 'Stadtsparkasse Kaiserslautern -alt-',
  '54050220': 'Sparkasse Kaiserslautern',
  '54051550': 'Kreissparkasse Kusel',
  '54051660': 'Stadtsparkasse Landstuhl -alt-',
  '54051990': 'Sparkasse Donnersberg',
  '54061650': 'VR-Bank Westpfalz (Gf P2)',
  '54062027': 'Raiffeisenbank Donnersberg (Gf P2)',
  '54070024': 'Deutsche Bank',
  '54070092': 'Deutsche Bank',
  '54080021': 'Commerzbank vormals Dresdner Bank',
  '54090000': 'Volksbank Kaiserslautern-Nordwestpfalz (Gf P2)',
  '54091700': 'Volksbank Lauterecken (Gf P2)',
  '54091800': 'VR Bank Nordwestpfalz -alt-',
  '54092400': 'Volksbank Glan-Münchweiler Zw Steinwenden',
  '54220091': 'UniCredit Bank - HypoVereinsbank',
  '54240032': 'Commerzbank',
  '54250010': 'Sparkasse Südwestpfalz',
  '54261700': 'VR-Bank Südwestpfalz Pirmasens-Zweibrücken (Gf P2)',
  '54270024': 'Deutsche Bank',
  '54270096': 'Deutsche Bank',
  '54280023': 'Commerzbank vormals Dresdner Bank',
  '54290000': 'VR-Bank Pirmasens (Gf P2)',
  '54291200': 'Raiffeisen- u Volksbank Dahn (Gf P2)',
  '54500000': 'Bundesbank',
  '54510067': 'Postbank Ndl der Deutsche Bank',
  '54520194': 'UniCredit Bank - HypoVereinsbank',
  '54540033': 'Commerzbank',
  '54550010': 'Sparkasse Vorderpfalz',
  '54550120': 'Kreissparkasse Rhein-Pfalz',
  '54561310': 'RV Bank Rhein-Haardt (Gf P2)',
  '54570024': 'Deutsche Bank',
  '54570094': 'Deutsche Bank',
  '54580020': 'Commerzbank vormals Dresdner Bank',
  '54620093': 'UniCredit Bank - HypoVereinsbank',
  '54640035': 'Commerzbank',
  '54651240': 'Sparkasse Rhein-Haardt',
  '54661800': 'Raiffeisenbank Freinsheim (Gf P2)',
  '54663270': 'Raiffeisenbank Friedelsheim-Rödersheim (Gf P2)',
  '54670024': 'Deutsche Bank',
  '54670095': 'Deutsche Bank',
  '54680022': 'Commerzbank vormals Dresdner Bank',
  '54691200': 'VR Bank Mittelhaardt (Gf P2)',
  '54750010': 'Kreis- und Stadtsparkasse Speyer',
  '54790000': 'Vereinigte VR Bank Kur- und Rheinpfalz (Gf P2)',
  '54850010': 'Sparkasse Südpfalz',
  '54851440': 'Sparkasse Germersheim-Kandel',
  '54861190': 'Raiffeisenbank Oberhaardt-Gäu (Gf P2)',
  '54862390': 'Raiffeisenbank (Gf P2)',
  '54862500': 'VR Bank Südpfalz (Gf P2)',
  '54891300': 'VR Bank Südliche Weinstraße-Wasgau (Gf P2)',
  '55000000': 'Bundesbank',
  '55010400': 'Aareal Bank GF - BK01 -',
  '55010424': 'Aareal Bank ehem. Filiale Mainz',
  '55010625': 'Aareal Bank Clearing Wiesbaden',
  '55010800': 'Investitions- und Strukturbank RP',
  '55020000': 'BHF-BANK',
  '55020100': 'Bausparkasse Mainz',
  '55020486': 'UniCredit Bank - HypoVereinsbank',
  '55020500': 'Bank für Sozialwirtschaft',
  '55020555': 'Bank für Sozialwirtschaft',
  '55020600': 'Aareal Bank (formerly Westdeutsche ImmobilienBank)',
  '55020700': 'Süd-West-Kreditbank Finanzierung',
  '55030500': 'TARGOBANK Gf FG',
  '55033300': 'Santander Consumer Bank',
  '55040022': 'Commerzbank',
  '55040060': 'Commerzbank CC',
  '55040061': 'Commerzbank CC',
  '55050000': 'ZV Landesbank Baden-Württemberg',
  '55050120': 'Sparkasse Mainz -alt-',
  '55060321': 'VR-Bank Mainz -alt-',
  '55060417': 'VR-Bank Mainz (Gf P2)',
  '55060611': 'Genobank Mainz (Gf P2)',
  '55061303': 'Budenheimer Volksbank (Gf P2)',
  '55061507': 'VR-Bank Mainz -alt-',
  '55061907': 'Volksbank Rhein-Selz (Gf P2)',
  '55070024': 'Deutsche Bank',
  '55070040': 'Deutsche Bank',
  '55070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '55070396': 'Postbank/DSL Ndl der Deutsche Bank',
  '55080044': 'Commerzbank, TF MZ 1',
  '55080065': 'Commerzbank vormals Dresdner Bank',
  '55080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '55080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '55080088': 'Commerzbank, TF MZ 2',
  '55090500': 'Sparda-Bank Südwest (Gf P2)',
  '55091200': 'Volksbank Alzey-Worms (Gf P2)',
  '55150098': 'Clearingkonto LRP-SI',
  '55160195': 'Pax-Bank',
  '55190000': 'Mainzer Volksbank (Gf P2)',
  '55340041': 'Commerzbank',
  '55350010': 'Rheinhessen Sparkasse',
  '55361202': 'VR Bank (Gf P2)',
  '55362071': 'Volksbank Bechtheim (Gf P2)',
  '55390000': 'Volksbank Worms-Wonnegau (Gf P2)',
  '56020086': 'UniCredit Bank - HypoVereinsbank',
  '56050180': 'Sparkasse Rhein-Nahe',
  '56051790': 'Kreissparkasse Rhein-Hunsrück',
  '56061151': 'Raiffeisenbank Kastellaun Zw',
  '56061472': 'Vereinigte Volksbank Raiffeisenbank (Gf P2)',
  '56062227': 'Volksbank (Gf P2)',
  '56070024': 'Deutsche Bank',
  '56070040': 'Deutsche Bank',
  '56090000': 'Volksbank Rhein-Nahe-Hunsrück (Gf P2)',
  '56240050': 'Commerzbank',
  '56250030': 'Kreissparkasse Birkenfeld',
  '56261735': 'Raiffeisenbank Nahe (Gf P2)',
  '56270024': 'Deutsche Bank',
  '56270044': 'Deutsche Bank',
  '56290000': 'Volksbank-Raiffeisenbank Naheland -alt-',
  '57000000': 'Bundesbank',
  '57020086': 'UniCredit Bank - HypoVereinsbank',
  '57020301': 'MMV Bank',
  '57020500': 'Oyak Anker Bank',
  '57020600': 'Debeka Bausparkasse',
  '57040044': 'Commerzbank',
  '57050120': 'Sparkasse Koblenz',
  '57051001': 'Kreissparkasse Westerwald -alt-',
  '57051870': 'Kreissparkasse Cochem-Zell -alt-',
  '57060000': 'DZ BANK',
  '57062675': 'Raiffeisenbank (Gf P2)',
  '57064221': 'Volksbank Mülheim-Kärlich (Gf P2)',
  '57069067': 'Raiffeisenbank Lutzerather Höhe (Gf P2)',
  '57069081': 'Raiffeisenbank Moselkrampen Zw',
  '57069144': 'Raiffeisenbank Eifeltor (Gf P2)',
  '57069238': 'Raiffeisenbank Neustadt (Gf P2)',
  '57069257': 'Raiffeisenbank Untermosel -alt-',
  '57069315': 'Raiffeisenbank Straßenhaus -alt-',
  '57069361': 'Raiffeisenbank Welling (Gf P2)',
  '57069727': 'Raiffeisenbank Irrel Zw',
  '57069806': 'VR-Bank Hunsrück-Mosel (Gf P2)',
  '57070024': 'Deutsche Bank',
  '57070045': 'Deutsche Bank',
  '57070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '57070395': 'Postbank/DSL Ndl der Deutsche Bank',
  '57080070': 'Commerzbank vormals Dresdner Bank',
  '57090000': 'Volksbank Koblenz Mittelrhein (Gf P2)',
  '57090010': 'Volksbank Koblenz Mittelrhein GAA -alt-',
  '57090900': 'PSD Bank Koblenz (Gf P2)',
  '57091000': 'Volksbank Montabaur-Höhr-Grenzhausen (Gf P2)',
  '57091100': 'Volksbank Höhr-Grenzhausen -alt-',
  '57092800': 'Volksbank Rhein-Lahn-Limburg (Gf P2)',
  '57263015': 'Raiffeisenbank Unterwesterwald Zw',
  '57351030': 'Sparkasse Westerwald-Sieg',
  '57361476': 'Volksbank Gebhardshain (Gf P2)',
  '57363243': 'Raiffeisenbank Niederfischbach (Gf P2)',
  '57391200': 'Volksbank Daaden Zw Weitefeld',
  '57391500': 'Volksbank Hamm/Sieg Zweigstelle Windeck-Rosbach',
  '57391800': 'Westerwald Bank (Gf P2)',
  '57450120': 'Sparkasse Neuwied',
  '57460117': 'VR Bank Rhein-Mosel (Gf P2)',
  '57461759': 'Raiffeisenbank Mittelrhein (Gf P2)',
  '57470024': 'Deutsche Bank',
  '57470047': 'Deutsche Bank',
  '57650010': 'Kreissparkasse Mayen',
  '57661253': 'Raiffeisenbank (Gf P2)',
  '57662263': 'VR Bank Rhein-Mosel (Gf P2)',
  '57751310': 'Kreissparkasse Ahrweiler',
  '57761591': 'Volksbank RheinAhrEifel (Gf P2)',
  '57761599': 'Volksbank RheinAhrEifel (Gf GAA)',
  '57762265': 'Raiffeisenbank Grafschaft-Wachtberg (Gf P2)',
  '58520086': 'UniCredit Bank - HypoVereinsbank',
  '58540035': 'Commerzbank',
  '58550130': 'Sparkasse Trier',
  '58560103': 'Volksbank Trier (Gf P2)',
  '58560294': 'Pax-Bank',
  '58561626': 'Volksbank Saarburg (Gf P2)',
  '58561771': 'Raiffeisenbank Mehring-Leiwen (Gf P2)',
  '58564788': 'Volksbank Hochwald-Saarburg (Gf P2)',
  '58570024': 'Deutsche Bank',
  '58570048': 'Deutsche Bank',
  '58580074': 'Commerzbank vormals Dresdner Bank',
  '58590900': 'PSD Bank Trier Ndl der PSD Bank West',
  '58650030': 'Kreissparkasse Bitburg-Prüm',
  '58651240': 'Kreissparkasse Vulkaneifel',
  '58660101': 'Volksbank Eifel (Gf P2)',
  '58661901': 'Raiffeisenbank Westeifel (Gf P2)',
  '58662653': 'Raiffeisenbank östl Südeifel (Gf P2)',
  '58668818': 'Raiffeisenbank Neuerburg-Land -alt-',
  '58691500': 'Volksbank Eifel Mitte (Gf P2)',
  '58751230': 'Sparkasse Mittelmosel-Eifel Mosel Hunsrück',
  '58760954': 'Vereinigte Volksbank Raiffeisenbank (Gf P2)',
  '58761343': 'Raiffeisenbank Zeller Land (Gf P2)',
  '58771224': 'Deutsche Bank',
  '58771242': 'Deutsche Bank',
  '59000000': 'Bundesbank',
  '59010011': 'Postbank Ndl der Deutsche Bank',
  '59010012': 'Postbank Ndl der Deutsche Bank',
  '59010013': 'Postbank Ndl der Deutsche Bank',
  '59010014': 'Postbank Ndl der Deutsche Bank',
  '59010015': 'Postbank Ndl der Deutsche Bank',
  '59010016': 'Postbank Ndl der Deutsche Bank',
  '59010017': 'Postbank Ndl der Deutsche Bank',
  '59010018': 'Postbank Ndl der Deutsche Bank',
  '59010019': 'Postbank Ndl der Deutsche Bank',
  '59010021': 'Postbank Ndl der Deutsche Bank',
  '59010022': 'Postbank Ndl der Deutsche Bank',
  '59010023': 'Postbank Ndl der Deutsche Bank',
  '59010024': 'Postbank Ndl der Deutsche Bank',
  '59010025': 'Postbank Ndl der Deutsche Bank',
  '59010026': 'Postbank Ndl der Deutsche Bank',
  '59010027': 'Postbank Ndl der Deutsche Bank',
  '59010028': 'Postbank Ndl der Deutsche Bank',
  '59010029': 'Postbank Ndl der Deutsche Bank',
  '59010031': 'Postbank Ndl der Deutsche Bank',
  '59010032': 'Postbank Ndl der Deutsche Bank',
  '59010033': 'Postbank Ndl der Deutsche Bank',
  '59010034': 'Postbank Ndl der Deutsche Bank',
  '59010035': 'Postbank Ndl der Deutsche Bank',
  '59010036': 'Postbank Ndl der Deutsche Bank',
  '59010037': 'Postbank Ndl der Deutsche Bank',
  '59010038': 'Postbank Ndl der Deutsche Bank',
  '59010039': 'Postbank Ndl der Deutsche Bank',
  '59010040': 'Postbank Ndl der Deutsche Bank',
  '59010041': 'Postbank Ndl der Deutsche Bank',
  '59010042': 'Postbank Ndl der Deutsche Bank',
  '59010044': 'Postbank Ndl der Deutsche Bank',
  '59010045': 'Postbank Ndl der Deutsche Bank',
  '59010047': 'Postbank Ndl der Deutsche Bank',
  '59010048': 'Postbank Ndl der Deutsche Bank',
  '59010049': 'Postbank Ndl der Deutsche Bank',
  '59010051': 'Postbank Ndl der Deutsche Bank',
  '59010052': 'Postbank Ndl der Deutsche Bank',
  '59010053': 'Postbank Ndl der Deutsche Bank',
  '59010054': 'Postbank Ndl der Deutsche Bank',
  '59010055': 'Postbank Ndl der Deutsche Bank',
  '59010056': 'Postbank Ndl der Deutsche Bank',
  '59010057': 'Postbank Ndl der Deutsche Bank',
  '59010058': 'Postbank Ndl der Deutsche Bank',
  '59010059': 'Postbank Ndl der Deutsche Bank',
  '59010061': 'Postbank Ndl der Deutsche Bank',
  '59010062': 'Postbank Ndl der Deutsche Bank',
  '59010063': 'Postbank Ndl der Deutsche Bank',
  '59010064': 'Postbank Ndl der Deutsche Bank',
  '59010065': 'Postbank Ndl der Deutsche Bank',
  '59010066': 'Postbank Ndl der Deutsche Bank',
  '59010068': 'Postbank Ndl der Deutsche Bank',
  '59010069': 'Postbank Ndl der Deutsche Bank',
  '59010071': 'Postbank Ndl der Deutsche Bank',
  '59010072': 'Postbank Ndl der Deutsche Bank',
  '59010073': 'Postbank Ndl der Deutsche Bank',
  '59010074': 'Postbank Ndl der Deutsche Bank',
  '59010400': 'Saarl Investitionskreditbank',
  '59020090': 'UniCredit Bank - HypoVereinsbank',
  '59020400': 'mediserv Bank',
  '59040000': 'Commerzbank',
  '59050000': 'Landesbank Saar',
  '59050101': 'Sparkasse Saarbrücken',
  '59051090': 'Stadtsparkasse Völklingen -alt-',
  '59070000': 'Deutsche Bank',
  '59070070': 'Deutsche Bank',
  '59080090': 'Commerzbank vormals Dresdner Bank',
  '59090900': 'PSD Bank RheinNeckarSaar',
  '59092000': 'Vereinigte Volksbank Saarlouis-Losheim-Sulzbach/S.(Gf P2)',
  '59099550': 'Volksbank Nahe-Schaumberg (Gf P2)',
  '59190000': 'Bank 1 Saar (Gf P2)',
  '59190200': 'Volksbank Westliche Saar plus (Gf P2)',
  '59251020': 'Kreissparkasse St. Wendel',
  '59252046': 'Sparkasse Neunkirchen',
  '59291000': 'Unsere Volksbank St. Wendeler Land (Gf P2)',
  '59291200': 'Volks- und Raiffeisenbank Saarpfalz (Gf P2)',
  '59320087': 'UniCredit Bank - HypoVereinsbank',
  '59350110': 'Kreissparkasse Saarlouis',
  '59351040': 'Sparkasse Merzig-Wadern',
  '59390100': 'Volksbank Saarlouis (Gf P2)',
  '59391200': 'Volksbank (Gf P2)',
  '59392000': 'Volksbank Dillingen (Gf P2)',
  '59392200': 'Volksbank Untere Saar (Gf P2)',
  '59393000': 'levoBank (Gf P2)',
  '59450010': 'Kreissparkasse Saarpfalz',
  '59491300': 'VR Bank Saarpfalz (Gf P2)',
  '60000000': 'Bundesbank',
  '60010070': 'Postbank Ndl der Deutsche Bank',
  '60010424': 'Aareal Bank ehem. Filiale Stuttgart',
  '60010700': 'Landeskreditbank Baden-Württemberg Förderbank -alt-',
  '60020030': 'Baden-Württembergische Bank',
  '60020100': 'M.M. Warburg & Co (vormals Schwäbische Bank)',
  '60020290': 'UniCredit Bank - HypoVereinsbank',
  '60030000': 'Mercedes-Benz Bank',
  '60030100': 'Bankhaus Bauer, Essen',
  '60030200': 'Bankhaus Ellwanger & Geiger',
  '60030600': 'CreditPlus Bank',
  '60030666': 'CreditPlus Bank',
  '60030700': 'AKTIVBANK (Gf P2)',
  '60030900': 'Isbank Fil Stuttgart',
  '60031000': 'TRUMPF Financial Services',
  '60033000': 'Wüstenrot Bausparkasse',
  '60035810': 'IBM Deutschland Kreditbank',
  '60040060': 'Commerzbank CC',
  '60040061': 'Commerzbank CC',
  '60040071': 'Commerzbank',
  '60040075': 'Commerzbank, Filiale Stuttgart 2',
  '60050000': 'Landesbank Baden-Württemberg',
  '60050009': 'ZV Landesbank Baden-Württemberg ISE',
  '60050101': 'Landesbank Baden-Württemberg/RLP Bank',
  '60060000': 'DZ BANK',
  '60060202': 'DZ PRIVATBANK Ndl. Stuttgart',
  '60060396': 'Volksbank am Württemberg (Gf P2)',
  '60062775': 'Echterdinger Bank (Gf P2)',
  '60062909': 'Volksbank Strohgäu (Gf P2)',
  '60069017': 'Raiffeisenbank Dellmensingen Zw Stetten',
  '60069066': 'Raiffeisenbank Niedere Alb (Gf P2)',
  '60069075': 'Raiffeisenbank Bühlertal (Gf P2)',
  '60069147': 'Raiffeisenbank Sondelfingen (Gf P2)',
  '60069158': 'Raiffeisenbank Steinheim (Gf P2)',
  '60069206': 'Raiffeisenbank Aidlingen (Gf P2)',
  '60069224': 'Genossenschaftsbank Weil im Schönbuch (Gf P2)',
  '60069239': 'Bopfinger Bank Sechta-Ries (Gf P2)',
  '60069242': 'Raiffeisenbank Gruibingen (Gf P2)',
  '60069245': 'Raiffeisenbank Bühlertal Bühlerzell',
  '60069251': 'Raiffeisenbank Donau-Iller (Gf P2)',
  '60069302': 'Raiffeisenbank Erlenmoos (Gf P2)',
  '60069303': 'Raiffeisenbank Bad Schussenried-Aulendorf (Gf P2)',
  '60069308': 'Raiffeisenbank (Gf P2)',
  '60069315': 'Volksbank Freiberg und Umgebung -alt-',
  '60069336': 'Raiffeisenbank Maitis (Gf P2)',
  '60069343': 'Raiffeisenbank Rißtal -alt-',
  '60069346': 'VR-Bank Alb-Blau-Donau (Gf P2)',
  '60069350': 'Raiffeisenbank Reute-Gaisbeuren (Gf P2)',
  '60069355': 'VR-Bank Ehningen-Nufringen (Gf P2)',
  '60069378': 'Volksbank Dettenhausen (Gf P2)',
  '60069387': 'Dettinger Bank (Gf P2)',
  '60069417': 'Raiffeisenbank Kirchheim-Walheim (Gf P2)',
  '60069419': 'Uhlbacher Bank (Gf P2)',
  '60069420': 'Raiffeisenbank Mittelbiberach -alt-',
  '60069431': 'Raiffeisenbank Oberessendorf (Gf P2)',
  '60069442': 'Raiffeisenbank Frankenhardt-Stimpfach (Gf P2)',
  '60069455': 'Raiffeisenbank Vordersteinenberg (Gf P2)',
  '60069457': 'Raiffeisenbank Ottenbach (Gf P2)',
  '60069461': 'Raiffeisenbank Rottumtal (Gf P2)',
  '60069462': 'Winterbacher Bank (Gf P2)',
  '60069463': 'Raiffeisenbank Geislingen-Rosenfeld',
  '60069476': 'Raiffeisenbank Heidenheimer Alb (Gf P2)',
  '60069485': 'Raiffeisenbank Oberer Wald (Gf P2)',
  '60069505': 'Volksbank Murgtal Baiersbr-Klosterreichenbach (Gf P2)',
  '60069517': 'Scharnhauser Bank (Gf P2)',
  '60069527': 'Volksbank Brenztal (Gf P2)',
  '60069538': 'Löchgauer Bank Gs',
  '60069544': 'Raiffeisenbank Westhausen (Gf P2)',
  '60069545': 'Nufringer Bank -Raiffeisen- (Gf P2)',
  '60069553': 'Raiffeisenbank Aichhalden-Hardt-Sulgen (Gf P2)',
  '60069564': 'Raiffeisenbank Vordere Alb (Gf P2)',
  '60069595': 'Raiffeisenbank Schrozberg-Rot am See (Gf P2)',
  '60069639': 'Raiffeisenbank Ingersheim (Gf P2)',
  '60069648': 'Raiffeisenbank (Gf P2)',
  '60069669': 'Erligheimer Bank -alt-',
  '60069673': 'Fachsenfelder Bank',
  '60069680': 'Raiffeisenbank (Gf P2)',
  '60069685': 'Raiffeisenbank (Gf P2)',
  '60069705': 'Raiffeisenbank Schlat -alt-',
  '60069706': 'Raiffeisenbank Mehrstetten',
  '60069710': 'Raiffeisenbank Gammesfeld (Gf P2)',
  '60069714': 'Raiffeisenbank Hohenloher Land (Gf P2)',
  '60069727': 'Raiffeisenbank (Gf P2)',
  '60069738': 'Volksbank Freiberg und Umgebung -alt-',
  '60069766': 'Volks- und Raiffeisenbank Boll -alt-',
  '60069795': 'Volksbank Freiberg und Umgebung -alt-',
  '60069798': 'Raiffeisenbank Horb Gs Börstingen',
  '60069817': 'Raiffeisenbank (Gf P2)',
  '60069832': 'Raiffeisenbank Urbach (Gf P2)',
  '60069842': 'Darmsheimer Bank (Gf P2)',
  '60069858': 'Enztalbank -alt-',
  '60069860': 'Federseebank (Gf P2)',
  '60069876': 'Raiffeisenbank Oberes Gäu Ergenzingen (Gf P2)',
  '60069896': 'Volksbank Freiberg und Umgebung -alt-',
  '60069904': 'VR-Bank Alb (Gf P2)',
  '60069905': 'Volksbank Remseck (Gf P2)',
  '60069911': 'Raiffeisenbank (Gf P2)',
  '60069926': 'Volksbank Glatten-Wittendorf (Gf P2)',
  '60069927': 'Berkheimer Bank (Gf P2)',
  '60069931': 'Raiffeisenbank (Gf P2)',
  '60069950': 'Raiffeisenbank Tüngental (Gf P2)',
  '60069976': 'Raiffeisenbank Böllingertal (Gf P2)',
  '60069980': 'Raiffeisenbank Maselheim-Äpfingen -alt-',
  '60070024': 'Deutsche Bank',
  '60070070': 'Deutsche Bank',
  '60070214': 'Postbank/DSL Ndl der Deutsche Bank',
  '60070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '60080000': 'Commerzbank vormals Dresdner Bank',
  '60080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '60080057': 'Commerzbank vormals Dresdner Bank Gf Zw 57',
  '60080085': 'Commerzbank vormals Dresdner Bank ITGK 2',
  '60080086': 'Commerzbank vormals Dresdner Bank Gf PCC-ITGK 3',
  '60080087': 'Commerzbank vormals Dresdner Bank, PCC DC-ITGK 4',
  '60080088': 'Commerzbank vormals Dresdner Bank, PCC DC-ITGK 5',
  '60089450': 'Commerzbank vormals Dresdner Bank ITGK',
  '60090100': 'Volksbank Stuttgart (Gf P2)',
  '60090133': 'Volksbank Stuttgart GAA',
  '60090300': 'Volksbank Zuffenhausen m Zndl Stammheimer VB (Gf P2)',
  '60090700': 'Südwestbank (Gf P2)',
  '60090800': 'Sparda-Bank Baden-Württemberg',
  '60090900': 'PSD Bank RheinNeckarSaar (Gf P2)',
  '60120200': 'BHF-BANK',
  '60120500': 'Bank für Sozialwirtschaft',
  '60133300': 'Santander Consumer Bank',
  '60241074': 'Commerzbank',
  '60250010': 'Kreissparkasse Waiblingen',
  '60261329': 'Fellbacher Bank (Gf P2)',
  '60261622': 'VR-Bank Weinstadt (Gf P2)',
  '60261818': 'Raiffeisenbank Weissacher Tal Gs Cottenweiler',
  '60262063': 'Korber Bank (Gf P2)',
  '60262693': 'Kerner Volksbank (Gf P2)',
  '60270024': 'Deutsche Bank',
  '60270073': 'Deutsche Bank',
  '60290110': 'Volksbank Rems (Gf P2)',
  '60291120': 'Volksbank Backnang Zw Weissach',
  '60320291': 'UniCredit Bank - HypoVereinsbank',
  '60340071': 'Commerzbank',
  '60350130': 'Kreissparkasse Böblingen',
  '60361923': 'Raiffeisenbank Weissach (Gf P2)',
  '60380002': 'Commerzbank vormals Dresdner Bank',
  '60390000': 'Vereinigte Volksbanken (Gf P2)',
  '60390300': 'Volksbank Leonberg-Strohgäu (Gf P2)',
  '60391310': 'Volksbank in der Region (Gf P2)',
  '60391420': 'VR-Bank Magstadt-Weissach (Gf P2)',
  '60420000': 'Oldenburgische Landesbank',
  '60420020': 'Oldenburgische Landesbank',
  '60420021': 'Oldenburgische Landesbank',
  '60420186': 'UniCredit Bank - HypoVereinsbank',
  '60422000': 'RSB Retail+Service Bank',
  '60440073': 'Commerzbank',
  '60450050': 'Kreissparkasse Ludwigsburg',
  '60460142': 'Volksbank Freiberg und Umgebung -alt-',
  '60462808': 'VR-Bank Asperg-Markgröningen (Gf P2)',
  '60470024': 'Deutsche Bank',
  '60470082': 'Deutsche Bank',
  '60480008': 'Commerzbank vormals Dresdner Bank',
  '60490150': 'Volksbank Ludwigsburg (Gf P2)',
  '60491430': 'VR-Bank Ludwigsburg (Gf P2)',
  '60661906': 'Raiffeisenbank Wimsheim-Mönsheim (Gf P2)',
  '60663084': 'Raiffeisenbank im Kreis Calw (Gf P2)',
  '60670024': 'Deutsche Bank',
  '60670070': 'Deutsche Bank',
  '61030000': 'Bankhaus Gebr. Martin',
  '61040014': 'Commerzbank',
  '61050000': 'Kreissparkasse Göppingen',
  '61060500': 'Volksbank Göppingen (Gf P2)',
  '61070024': 'Deutsche Bank',
  '61070078': 'Deutsche Bank',
  '61080006': 'Commerzbank vormals Dresdner Bank',
  '61091200': 'Volksbank-Raiffeisenbank Deggingen (Gf P2)',
  '61120286': 'UniCredit Bank - HypoVereinsbank',
  '61140071': 'Commerzbank',
  '61150020': 'Kreissparkasse Esslingen-Nürtingen',
  '61161696': 'Volksbank Filder (Gf P2)',
  '61170024': 'Deutsche Bank',
  '61170076': 'Deutsche Bank',
  '61180004': 'Commerzbank vormals Dresdner Bank',
  '61190110': 'Volksbank Esslingen (Gf P2)',
  '61191310': 'Zeller Bank Ndl d Volksbank Plochingen',
  '61240048': 'Commerzbank',
  '61261213': 'Raiffeisenbank Teck (Gf P2)',
  '61261339': 'VR Bank Hohenneuffen-Teck (Gf P2)',
  '61262258': 'Genossenschaftsbank Wolfschlugen (Gf P2)',
  '61262345': 'Bernhauser Bank (Gf P2)',
  '61281007': 'Commerzbank vormals Dresdner Bank',
  '61290120': 'Volksbank Mittlerer Neckar (Gf P2)',
  '61340079': 'Commerzbank',
  '61361722': 'Raiffeisenbank Rosenstein (Gf P2)',
  '61361975': 'Raiffeisenbank Mutlangen Ndl Großdeinbach',
  '61370024': 'Deutsche Bank',
  '61370086': 'Deutsche Bank',
  '61390140': 'Volksbank Schwäbisch Gmünd (Gf P2)',
  '61391410': 'VR Bank Schwäbischer Wald (Gf P2)',
  '61420086': 'UniCredit Bank - HypoVereinsbank',
  '61440086': 'Commerzbank',
  '61450050': 'Kreissparkasse Ostalb',
  '61480001': 'Commerzbank vormals Dresdner Bank',
  '61490150': 'VR-Bank Ostalb (Gf P2)',
  '61491010': 'VR-Bank Ellwangen (Gf P2)',
  '62020000': 'Hoerner-Bank',
  '62040060': 'Commerzbank',
  '62050000': 'Kreissparkasse Heilbronn',
  '62061991': 'Volksbank Sulmtal (Gf P2)',
  '62062215': 'Volksbank Beilstein-Ilsfeld-Abstatt Zw Gronau',
  '62062643': 'Volksbank Flein-Talheim (Gf P2)',
  '62063263': 'VBU Volksbank im Unterland (Gf P2)',
  '62070024': 'Deutsche Bank',
  '62070081': 'Deutsche Bank',
  '62080012': 'Commerzbank vormals Dresdner Bank',
  '62090100': 'Volksbank Heilbronn (Gf P2)',
  '62091400': 'Volksbank Brackenheim-Güglingen (Gf P2)',
  '62091600': 'Volksbank Möckmühl (Gf P2)',
  '62091800': 'Volksbank Hohenlohe Gs Weißbach',
  '62220000': 'Bausparkasse Schwäbisch Hall',
  '62240048': 'Commerzbank',
  '62250030': 'Sparkasse Schwäbisch Hall-Crailsheim',
  '62251550': 'Sparkasse Hohenlohekreis',
  '62280012': 'Commerzbank vormals Dresdner Bank',
  '62290110': 'VR Bank Heilbronn Schwäbisch Hall (Gf P2)',
  '62291020': 'Crailsheimer Volksbank (Gf P2)',
  '62391420': 'Volksbank Vorbach-Tauber (Gf P2)',
  '63000000': 'Bundesbank',
  '63020086': 'UniCredit Bank - HypoVereinsbank',
  '63040053': 'Commerzbank',
  '63050000': 'Sparkasse Ulm',
  '63061486': 'VR-Bank Langenau-Ulmer Alb (Gf P2)',
  '63070024': 'Deutsche Bank',
  '63070088': 'Deutsche Bank',
  '63080015': 'Commerzbank vormals Dresdner Bank',
  '63080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '63090100': 'Volksbank Ulm-Biberach (Gf P2)',
  '63091010': 'Donau-Iller Bank (Gf P2)',
  '63091200': 'Volksbank Blaubeuren (Gf P2)',
  '63091300': 'Volksbank Alb (Gf P2)',
  '63220090': 'UniCredit Bank - HypoVereinsbank',
  '63240016': 'Commerzbank',
  '63250030': 'Kreissparkasse Heidenheim',
  '63290110': 'Heidenheimer Volksbank Zw Steinheim',
  '64000000': 'Bundesbank',
  '64020186': 'UniCredit Bank - HypoVereinsbank',
  '64040033': 'Commerzbank',
  '64040045': 'Commerzbank',
  '64050000': 'Kreissparkasse Reutlingen',
  '64061854': 'VR Bank Tübingen (Gf P2)',
  '64070024': 'Deutsche Bank',
  '64070085': 'Deutsche Bank',
  '64070215': 'Postbank/DSL Ndl der Deutsche Bank',
  '64070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '64080014': 'Commerzbank vormals Dresdner Bank',
  '64090100': 'Volksbank Reutlingen (Gf P2)',
  '64091200': 'Volksbank Ermstal-Alb (Gf P2)',
  '64091300': 'Volksbank Münsingen (Gf P2)',
  '64140036': 'Commerzbank Tübingen',
  '64150020': 'Kreissparkasse Tübingen',
  '64161397': 'Volksbank Ammerbuch Zw',
  '64161608': 'Raiffeisenbank Härten (Gf P2)',
  '64161956': 'Volksbank (Gf P2)',
  '64163225': 'Volksbank Hohenzollern-Balingen (Gf P2)',
  '64180014': 'Commerzbank vormals Dresdner Bank',
  '64190110': 'Volksbank Tübingen (Gf P2)',
  '64191030': 'Volksbank Nagoldtal (Gf P2)',
  '64191700': 'Volksbank Horb (Gf P2)',
  '64240048': 'Commerzbank',
  '64240071': 'Commerzbank',
  '64250040': 'Kreissparkasse Rottweil',
  '64251060': 'Kreissparkasse Freudenstadt',
  '64261363': 'Volksbank Baiersbronn Murgtal (Gf P2)',
  '64261626': 'Murgtalbank Mitteltal - Obertal -alt-',
  '64261853': 'Volksbank Nordschwarzwald (Gf P2)',
  '64262408': 'VR-Bank Dornstetten-Horb (Gf P3)',
  '64290120': 'Volksbank Rottweil (Gf P2)',
  '64291010': 'Volksbank im Kreis Freudenstadt (Gf P2)',
  '64291420': 'Volksbank Deisslingen (Gf P2)',
  '64292020': 'Volksbank Schwarzwald-Neckar (Gf P2)',
  '64292310': 'Volksbank Trossingen (Gf P2)',
  '64350070': 'Kreissparkasse Tuttlingen',
  '64361359': 'Raiffeisenbank Donau-Heuberg (Gf P2)',
  '64380011': 'Commerzbank vormals Dresdner Bank',
  '64390130': 'Volksbank Schwarzwald-Donau-Neckar (Gf P2)',
  '65020186': 'UniCredit Bank - HypoVereinsbank',
  '65040073': 'Commerzbank',
  '65050110': 'Kreissparkasse Ravensburg',
  '65061219': 'Raiffeisenbank Aulendorf (Gf P2)',
  '65062577': 'VR Bank Ravensburg-Weingarten (Gf P2)',
  '65063086': 'Raiffeisenbank Bad Saulgau (Gf P2)',
  '65070024': 'Deutsche Bank',
  '65070084': 'Deutsche Bank',
  '65080009': 'Commerzbank vormals Dresdner Bank',
  '65090100': 'Volksbank Ulm-Biberach -alt-',
  '65091040': 'Volksbank Allgäu-Oberschwaben (Gf P2)',
  '65091300': 'Bad Waldseer Bank (Gf P2)',
  '65091600': 'Volksbank Weingarten (Gf P2)',
  '65092010': 'Volksbank Allgäu-West (Gf P2)',
  '65092200': 'Volksbank Altshausen (Gf P2)',
  '65093020': 'Volksbank Bad Saulgau (Gf P2)',
  '65110200': 'Internationales Bankhaus Bodensee',
  '65140072': 'Commerzbank',
  '65161497': 'Genossenschaftsbank Meckenbeuren (Gf P2)',
  '65162832': 'Raiffeisenbank Oberteuringen-Meckenbeuren (Gf P2)',
  '65180005': 'Commerzbank vormals Dresdner Bank',
  '65190110': 'Volksbank Friedrichshafen Ndl Langenargen',
  '65191500': 'Volksbank Friedrichshafen-Tettnang (Gf P2)',
  '65340004': 'Commerzbank',
  '65341204': 'Commerzbank',
  '65351050': 'Hohenz Landesbank Kreissparkasse Sigmaringen',
  '65351260': 'Sparkasse Zollernalb',
  '65361469': 'Volksbank Heuberg (Gf P2)',
  '65361898': 'VR Bank Heuberg-Winterlingen (Gf P2)',
  '65361989': 'Onstmettinger Bank (Gf P2)',
  '65362499': 'Raiffeisenbank Geislingen-Rosenfeld (Gf P2)',
  '65370024': 'Deutsche Bank',
  '65370075': 'Deutsche Bank',
  '65380003': 'Commerzbank vormals Dresdner Bank',
  '65390120': 'Volksbank Albstadt (Gf P2)',
  '65391210': 'Volksbank Balingen (Gf P2)',
  '65392030': 'Volksbank Tailfingen (Gf P2)',
  '65440087': 'Commerzbank',
  '65450070': 'Kreissparkasse Biberach',
  '65461878': 'Raiffeisenbank Biberach (Gf P2)',
  '65462231': 'Raiffeisenbank Illertal (Gf P2)',
  '65491320': 'Volksbank Raiffeisenbank Laupheim-Illertal (Gf P2)',
  '65491510': 'VR Bank Riedlingen-Federsee GS Uttenweiler',
  '66000000': 'Bundesbank',
  '66010075': 'Postbank Ndl der Deutsche Bank',
  '66010200': 'Deutsche Bausparkasse Badenia',
  '66010700': 'Landeskreditbank Baden-Württemberg Förderbank',
  '66020020': 'Baden-Württembergische Bank',
  '66020286': 'UniCredit Bank - HypoVereinsbank',
  '66020500': 'Bank für Sozialwirtschaft',
  '66020566': 'Bank für Sozialwirtschaft',
  '66030600': 'Isbank eh Filiale Mannheim',
  '66040018': 'Commerzbank',
  '66040026': 'Commerzbank/Kreditcenter Badenia',
  '66050000': 'Landesbank Baden-Württemberg',
  '66050101': 'Sparkasse Karlsruhe',
  '66051220': 'Sparkasse Ettlingen -alt-',
  '66060000': 'DZ BANK (Gf P2)',
  '66060300': 'Spar- und Kreditbank (Gf P2)',
  '66061407': 'Spar- und Kreditbank (Gf P2)',
  '66061724': 'Volksbank Stutensee-Weingarten (Gf P2)',
  '66062138': 'Spar- und Kreditbank Hardt (Gf P2)',
  '66062366': 'Raiffeisenbank Hardt-Bruhrain (Gf P2)',
  '66069103': 'Raiffeisenbank Elztal (Gf P2)',
  '66069104': 'Spar- und Kreditbank (Gf P2)',
  '66069342': 'Volksbank Krautheim (Gf P2)',
  '66070004': 'Deutsche Bank',
  '66070024': 'Deutsche Bank',
  '66070213': 'Postbank/DSL Ndl der Deutsche Bank',
  '66070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '66080052': 'Commerzbank vormals Dresdner Bank',
  '66090800': 'BBBank (Gf P2)',
  '66090900': 'PSD Bank Karlsruhe-Neustadt (Gf P2)',
  '66091200': 'Volksbank Ettlingen Zw Reichenbach',
  '66190000': 'Volksbank pur (Gf P2)',
  '66240002': 'Commerzbank',
  '66250030': 'Sparkasse Baden-Baden Gaggenau',
  '66251434': 'Sparkasse Bühl',
  '66261092': 'Spar- und Kreditbank (Gf P2)',
  '66261416': 'Raiffeisenbank Altschweier (Gf P2)',
  '66270001': 'Deutsche Bank',
  '66270024': 'Deutsche Bank',
  '66280053': 'Commerzbank vormals Dresdner Bank',
  '66290000': 'Volksbank Baden-Baden Rastatt (Gf P2)',
  '66291300': 'Volksbank Achern (Gf P2)',
  '66291400': 'Volksbank Bühl Zw Steinbach',
  '66340018': 'Commerzbank',
  '66350036': 'Sparkasse Kraichgau Bruchsal-Bretten-Sinsheim',
  '66391200': 'Volksbank Bruchsal-Bretten (Gf P2)',
  '66391600': 'Volksbank Bruhrain-Kraich-Hardt (Gf P2)',
  '66432700': 'Bankhaus J. Faißt',
  '66440084': 'Commerzbank',
  '66450050': 'Sparkasse Offenburg/Ortenau',
  '66451346': 'Sparkasse Gengenbach -alt-',
  '66451548': 'Sparkasse Kinzigtal',
  '66451862': 'Sparkasse Hanauerland',
  '66452776': 'Sparkasse Wolfach',
  '66470024': 'Deutsche Bank',
  '66470035': 'Deutsche Bank',
  '66490000': 'Volksbank (Gf P2)',
  '66491800': 'Volksbank Bühl Zw Willstätt',
  '66492600': 'Volksbank Appenweier-Urloffen Appenweier -alt-',
  '66492700': 'Volksbank Mittlerer Schwarzwald (Gf P2)',
  '66550070': 'Sparkasse Rastatt-Gernsbach',
  '66562053': 'Raiffeisenbank Südhardt Durmersheim (Gf P2)',
  '66562300': 'VR-Bank in Mittelbaden (Gf P2)',
  '66640035': 'Commerzbank',
  '66650085': 'Sparkasse Pforzheim Calw',
  '66661244': 'Raiffeisenbank Bauschlott (Gf P2)',
  '66661329': 'Raiffeisenbank Kieselbronn (Gf P2)',
  '66661454': 'VR Bank im Enzkreis (Gf P2)',
  '66662155': 'Raiffeisenbank Ersingen (Gf P2)',
  '66662220': 'Volksbank Stein Eisingen (Gf P2)',
  '66670006': 'Deutsche Bank',
  '66670024': 'Deutsche Bank',
  '66680013': 'Commerzbank vormals Dresdner Bank',
  '66690000': 'Volksbank Pforzheim Zw Wildbad',
  '66692300': 'VR Bank Enz plus (Gf P2)',
  '66762332': 'Raiffeisenbank Kraichgau (Gf P2)',
  '66762433': 'Raiffeisenbank Neudenau-Stein-Herbolzheim (Gf P2)',
  '67020190': 'UniCredit Bank - HypoVereinsbank',
  '67040031': 'Commerzbank',
  '67040060': 'Commerzbank CC',
  '67040061': 'Commerzbank CC',
  '67040085': 'Commerzbank, Gf Web-K',
  '67050505': 'Sparkasse Rhein Neckar Nord',
  '67051203': 'Sparkasse Hockenheim',
  '67060031': 'Volksbank Sandhofen (Gf P2)',
  '67070010': 'Deutsche Bank',
  '67070024': 'Deutsche Bank',
  '67080050': 'Commerzbank vormals Dresdner Bank',
  '67080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '67080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
  '67089440': 'Commerzbank vormals Dresdner Bank ITGK',
  '67090000': 'VR Bank Rhein-Neckar (Gf P2)',
  '67092300': 'Volksbank Kurpfalz (Gf P2)',
  '67220286': 'UniCredit Bank - HypoVereinsbank',
  '67230000': 'MLP Banking',
  '67230001': 'MLP Banking Zw CS (Gf P2)',
  '67240039': 'Commerzbank',
  '67250020': 'Sparkasse Heidelberg',
  '67262243': 'Raiffeisenbank Baiertal (Gf P2)',
  '67262550': 'Volksbank Rot (Gf P2)',
  '67270003': 'Deutsche Bank',
  '67270024': 'Deutsche Bank',
  '67280051': 'Commerzbank vormals Dresdner Bank',
  '67290000': 'Heidelberger Volksbank (Gf P2)',
  '67290100': 'Volksbank Kurpfalz (Gf P2)',
  '67291700': 'Volksbank Neckartal (Gf P2)',
  '67291900': 'Volksbank Kraichgau (Gf P2)',
  '67292200': 'Volksbank Kraichgau (Gf P2)',
  '67352565': 'Sparkasse Tauberfranken',
  '67390000': 'Volksbank Main-Tauber (Gf P2)',
  '67450048': 'Sparkasse Neckartal-Odenwald',
  '67460041': 'Volksbank Mosbach Zw',
  '67461424': 'Volksbank Franken Zw',
  '67461733': 'Volksbank Kirnau (Gf P2)',
  '67462368': 'Volksbank Limbach (Gf P2)',
  '68000000': 'Bundesbank',
  '68020186': 'UniCredit Bank - HypoVereinsbank',
  '68030000': 'Bankhaus E. Mayer',
  '68040007': 'Commerzbank',
  '68050101': 'Sparkasse Freiburg-Nördlicher Breisgau',
  '68051004': 'Sparkasse Hochschwarzwald',
  '68051207': 'Sparkasse Bonndorf-Stühlingen',
  '68052230': 'Sparkasse St. Blasien',
  '68052328': 'Sparkasse Staufen-Breisach',
  '68052863': 'Sparkasse Schönau-Todtnau',
  '68061505': 'Volksbank Breisgau-Markgräflerland (Gf P2)',
  '68062105': 'Raiffeisenbank Denzlingen-Sexau (Gf P2)',
  '68062730': 'Raiffeisenbank Wyhl (Gf P2)',
  '68063479': 'Raiffeisenbank Kaiserstuhl Vogtsburg Zw',
  '68064222': 'Raiffeisenbank im Breisgau (Gf P2)',
  '68070024': 'Deutsche Bank',
  '68070030': 'Deutsche Bank',
  '68070212': 'Postbank/DSL Ndl der Deutsche Bank',
  '68070224': 'Postbank/DSL Ndl der Deutsche Bank',
  '68080030': 'Commerzbank vormals Dresdner Bank',
  '68080031': 'Commerzbank vormals Dresdner Bank Zw Münsterstraße',
  '68080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '68080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '68090000': 'Volksbank Todtnau Zndl d Volksbank Freiburg',
  '68090900': 'PSD Bank RheinNeckarSaar',
  '68091900': 'Volksbank Müllheim (Gf P2)',
  '68092000': 'Volksbank Breisgau Nord (Gf P2)',
  '68092300': 'Volksbank Staufen Zw Sulzburg',
  '68270024': 'Deutsche Bank',
  '68270033': 'Deutsche Bank',
  '68290000': 'Volksbank Lahr (Gf P2)',
  '68340058': 'Commerzbank',
  '68350048': 'Sparkasse Lörrach-Rheinfelden',
  '68351557': 'Sparkasse Wiesental',
  '68351865': 'Sparkasse Markgräflerland',
  '68370024': 'Deutsche Bank',
  '68370034': 'Deutsche Bank',
  '68390000': 'Volksbank Dreiländereck (Gf P2)',
  '68391500': 'VR Bank (Gf P2)',
  '68452290': 'Sparkasse Hochrhein',
  '68462427': 'Volksbank Klettgau-Wutöschingen (Gf P2)',
  '68490000': 'Volksbank Rhein-Wehra (Gf P2)',
  '68492200': 'Volksbank Hochrhein (Gf P2)',
  '69020190': 'UniCredit Bank - HypoVereinsbank',
  '69040045': 'Commerzbank',
  '69050001': 'Sparkasse Bodensee',
  '69051410': 'Bezirkssparkasse Reichenau',
  '69051620': 'Sparkasse Pfullendorf-Meßkirch',
  '69051725': 'Sparkasse Salem-Heiligenberg',
  '69061800': 'Volksbank Überlingen (Gf P2)',
  '69070024': 'Deutsche Bank',
  '69070032': 'Deutsche Bank',
  '69091200': 'Hagnauer Volksbank Zw Immenstaad',
  '69091600': 'Volksbank Pfullendorf Fil',
  '69220186': 'UniCredit Bank - HypoVereinsbank',
  '69240075': 'Commerzbank',
  '69250035': 'Sparkasse Hegau-Bodensee',
  '69251445': 'Sparkasse Engen-Gottmadingen',
  '69251755': 'Sparkasse Stockach',
  '69270024': 'Deutsche Bank',
  '69270038': 'Deutsche Bank',
  '69280035': 'Commerzbank vorm. Dresdner Bk Fil Villingen u Schwenningen',
  '69290000': 'Volksbank Hegau (Gf P2)',
  '69291000': 'Volksbank (Gf P2)',
  '69291099': 'Volksbank Gf GA',
  '69362032': 'Volksbank Meßkirch Raiffeisenbank (Gf P2)',
  '69400000': 'Bundesbank',
  '69440007': 'Commerzbank',
  '69440060': 'Commerzbank CC',
  '69450065': 'Sparkasse Schwarzwald-Baar',
  '69470024': 'Deutsche Bank',
  '69470039': 'Deutsche Bank',
  '69490000': 'Volksbank Schwarzwald Baar Hegau (Gf P2)',
  '69491700': 'Volksbank Triberg (Gf P2)',
  '70000000': 'Bundesbank',
  '70010080': 'Postbank Ndl der Deutsche Bank',
  '70010424': 'Aareal Bank ehem. Filiale München',
  '70010500': 'Deutsche Pfandbriefbank',
  '70010555': 'Deutsche Pfandbriefbank',
  '70010570': 'Deutsche Pfandbriefbank',
  '70010800': 'Middle East Bank, Munich Branch',
  '70011100': 'Deutsche Handelsbank',
  '70011110': 'Deutsche Handelsbank',
  '70011200': 'Bank Vontobel Europe',
  '70011300': 'Autobank',
  '70011400': 'BfW - Bank für Wohnungswirtschaft',
  '70011500': 'SIEMENS BANK',
  '70011600': 'TEN31 Bank',
  '70011700': 'Bankhaus von der Heydt',
  '70011900': 'InterCard',
  '70012000': 'UniCredit Family Financing Bank, Ndl der UniCredit',
  '70012300': 'V-Bank',
  '70012400': 'Bankhaus Obotritia',
  '70012600': 'Südtiroler Sparkasse Niederlassung München',
  '70012700': 'VZ Depotbank Deutschland',
  '70012900': 'IBAN FIRST, Zweigniederlassung München',
  '70013000': 'European Bank for Financial Services',
  '70013010': 'European Bank for Financial Services',
  '70013400': 'IC Cash Services',
  '70013500': 'Bankhaus Herzogpark',
  '70015000': 'transact Elektronische Zahlungssysteme',
  '70015015': 'transact Elektronische Zahlungssysteme',
  '70015025': 'transact Elektronische Zahlungssysteme',
  '70015035': 'transact Elektronische Zahlungssysteme',
  '70017000': 'PayCenter',
  '70020270': 'UniCredit Bank - HypoVereinsbank',
  '70020300': 'BNP Paribas NL Deutschland (Gf P3)',
  '70020500': 'Bank für Sozialwirtschaft',
  '70020570': 'Bank für Sozialwirtschaft',
  '70020800': 'INTESA SANPAOLO',
  '70021180': 'UniCredit Bank - HypoVereinsbank',
  '70022200': 'Fidor Bank',
  '70025175': 'UniCredit Bank - HypoVereinsbank',
  '70030014': 'Fürst Fugger Privatbank',
  '70030300': 'Bankhaus Reuschel & Co -alt-',
  '70030400': 'Merck Finck A Quintet Private Bank',
  '70031000': 'Bankhaus Ludwig Sperrer',
  '70032500': 'St.Galler Kantonalbank Deutschland',
  '70033100': 'Baader Bank',
  '70035000': 'Oldenburgische Landesbank',
  '70040041': 'Commerzbank',
  '70040045': 'Commerzbank, Filiale München 2',
  '70040048': 'Commerzbank',
  '70040060': 'Commerzbank Gf 860',
  '70040061': 'Commerzbank Gf 861',
  '70040062': 'Commerzbank CC',
  '70040063': 'Commerzbank CC',
  '70040070': 'Commerzbank, CC SP',
  '70045050': 'Commerzbank Service-BZ',
  '70050000': 'Bayerische Landesbank',
  '70051003': 'Sparkasse Freising Moosburg',
  '70051540': 'Sparkasse Dachau',
  '70051995': 'Kreis- und Stadtsparkasse Erding-Dorfen',
  '70052060': 'Sparkasse Landsberg-Dießen',
  '70053070': 'Sparkasse Fürstenfeldbruck',
  '70054306': 'Sparkasse Bad Tölz-Wolfratshausen',
  '70070010': 'Deutsche Bank',
  '70070024': 'Deutsche Bank',
  '70070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '70070362': 'Postbank/DSL Ndl der Deutsche Bank',
  '70080000': 'Commerzbank vormals Dresdner Bank',
  '70080056': 'Commerzbank vormals Dresdner Bank Zw 56',
  '70080057': 'Commerzbank vormals Dresdner Bank Gf ZW 57',
  '70080085': 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 3',
  '70080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
  '70080087': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
  '70080088': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
  '70089470': 'Commerzbank vormals Dresdner Bank ITGK',
  '70089472': 'Commerzbank vormals Dresdner Bank ITGK',
  '70090100': 'Hausbank München (Gf P2)',
  '70090124': 'Hausbank München',
  '70090500': 'Sparda-Bank München',
  '70091500': 'Volksbank Raiffeisenbank Dachau (Gf P2)',
  '70091510': 'Volksbank Raiffeisenbank Dachau (Gf GAA)',
  '70091600': 'VR-Bank Landsberg-Ammersee (Gf P2)',
  '70091900': 'VR-Bank Erding (Gf P2)',
  '70093200': 'VR-Bank Starnberg-Herrsching-Landsberg (Gf P2)',
  '70093400': 'VR-Bank Ismaning Hallbergmoos Neufahrn (Gf P2)',
  '70110088': 'Postbank Ndl der Deutsche Bank',
  '70110500': 'Münchener Hypothekenbank',
  '70120100': 'State Street Bank International',
  '70120400': 'BNP Paribas Niederlassung Deutschland',
  '70120500': 'CACEIS Bank, Germany Branch',
  '70120600': 'Airbus Bank',
  '70120700': 'Oberbank Ndl Deutschland',
  '70130800': 'MERKUR PRIVATBANK',
  '70133300': 'Santander Consumer Bank',
  '70150000': 'Stadtsparkasse München',
  '70160000': 'DZ BANK',
  '70163370': 'Volksbank Raiffeisenbank Fürstenfeldbruck (Gf P2)',
  '70166486': 'VR Bank München Land (Gf P2)',
  '70169132': 'Raiffeisenbank Griesstätt-Halfing (Gf P2)',
  '70169165': 'Raiffeisenbank Chiemgau-Nord - Obing (Gf P2)',
  '70169168': 'VR-Bank Chiemgau-Süd -alt-',
  '70169186': 'Raiffeisenbank Pfaffenhofen a d Glonn (Gf P2)',
  '70169190': 'Raiffeisenbank Tattenh-Großkarolinenf (Gf P2)',
  '70169191': 'Raiffeisenbank Rupertiwinkel (Gf P2)',
  '70169195': 'Raiffeisenbank Trostberg-Traunreut (Gf P2)',
  '70169310': 'Alxing-Brucker Genossenschaftsbank (Gf P2)',
  '70169331': 'Raiffeisenbank südöstl. Starnberger See (Gf P2)',
  '70169333': 'Raiffeisenbank Beuerberg-Eurasburg (Gf P2)',
  '70169351': 'Raiffeisenbank Lechrain (Gf P2)',
  '70169356': 'Raiffeisenbank Erding Zw',
  '70169382': 'Raiffeisenbank (Gf P2)',
  '70169383': 'Raiffeisenbank Gmund am Tegernsee (Gf P2)',
  '70169388': 'Raiffeisenbank Haag-Gars-Maitenbeth (Gf P2)',
  '70169402': 'Raiffeisenbank Höhenkirchen und Umgebung (Gf P2)',
  '70169410': 'Raiffeisenbank Holzkirchen-Otterfing (Gf P2)',
  '70169413': 'Raiffeisenbank Singoldtal (Gf P2)',
  '70169450': 'Raiffeisen-Volksbank Ebersberg (Gf P2)',
  '70169459': 'Raiffeisenbank (Gf P2)',
  '70169460': 'Raiffeisenbank Westkreis Fürstenfeldbruck Zw',
  '70169464': 'Genossenschaftsbank München (Gf P2)',
  '70169465': 'Raiffeisenbank München-Nord (Gf P2)',
  '70169466': 'Raiffeisenbank München-Süd (Gf P2)',
  '70169470': 'Raiffeisenbank München-Süd Gf GA',
  '70169474': 'Raiffbk Neumarkt-St. Veit - Niederbergkirchen (Gf P2)',
  '70169476': 'Raiffeisenbank -alt-',
  '70169509': 'Raiffeisenbank Pfaffenwinkel (Gf P2)',
  '70169521': 'Raiffeisenbank Raisting (Gf P2)',
  '70169524': 'Bankhaus RSA (Gf P2)',
  '70169530': 'Raiffeisenbank Neumarkt-St. Veit - Reischach (Gf P2)',
  '70169538': 'Raiffeisenbank St. Wolfgang-Schwindkirchen (Gf P2)',
  '70169541': 'Raiffeisenbank Lech-Ammersee (Gf P2)',
  '70169543': 'Raiffeisenbank Isar-Loisachtal (Gf P2)',
  '70169558': 'Raiffeisenbank (Gf P2)',
  '70169566': 'VR-Bank Taufkirchen-Dorfen (Gf P2)',
  '70169568': 'Raiffeisenbank Taufkirchen-Oberneukirchen (Gf P2)',
  '70169571': 'Raiffeisenbank Tölzer Land (Gf P2)',
  '70169575': 'Raiffeisenbank Wiedergelt Ndl d Rbk Türkheim',
  '70169576': 'Raiffeisen-Volksbank (Gf P2)',
  '70169585': 'Raiffeisenbank Unterschleißheim-Haimhn -alt-',
  '70169598': 'Raiffeisenbank im Oberland (Gf P2)',
  '70169599': 'Raiffeisenbank Weil u Umgebung (Gf P2)',
  '70169602': 'Raiffeisenbank Weilheim und Volksbank Bavaria',
  '70169605': 'VR-Bank Erding (Gf P2)',
  '70169614': 'Freisinger Bank Volksbank-Raiffeisenbank (Gf P2)',
  '70169619': 'Raiffeisenbank Zorneding (Gf P2)',
  '70169653': 'Raiffeisenbank Aiglsbach (Gf P2)',
  '70169693': 'Raiffeisenbank Hallertau (Gf P2)',
  '70190000': 'Münchner Bank, Zndl Raiffeisenbank Oberschleißheim',
  '70190010': 'Münchner Bank',
  '70220000': 'LfA Förderbank Bayern',
  '70220200': 'BHF-BANK',
  '70220300': 'BMW Bank',
  '70220900': 'Wüstenrot Bausparkasse',
  '70230600': 'Isbank Fil München',
  '70250150': 'Kreissparkasse München Starnberg Ebersberg',
  '70320090': 'UniCredit Bank - HypoVereinsbank',
  '70321194': 'UniCredit Bank - HypoVereinsbank',
  '70322192': 'UniCredit Bank - HypoVereinsbank',
  '70350000': 'Kreissparkasse Garmisch-Partenkirchen -alt-',
  '70351030': 'Sparkasse Oberland',
  '70362595': 'Raiffeisenbank Wallgau-Krün (Gf P2)',
  '70380006': 'Commerzbank vormals Dresdner Bank',
  '70390000': 'VR-Bank Werdenfels (Gf P2)',
  '70390010': 'VR-Bank Werdenfels',
  '70391800': 'Volksbank-Raiffeisenbank Penzberg (Gf P2)',
  '71020072': 'UniCredit Bank - HypoVereinsbank',
  '71021270': 'UniCredit Bank - HypoVereinsbank',
  '71022182': 'UniCredit Bank - HypoVereinsbank',
  '71023173': 'UniCredit Bank - HypoVereinsbank',
  '71050000': 'Sparkasse Berchtesgadener Land',
  '71051010': 'Kreissparkasse Altötting-Burghausen -alt-',
  '71052050': 'Kreissparkasse Traunstein-Trostberg',
  '71061009': 'VR meine Raiffeisenbank (Gf P2)',
  '71062802': 'Raiffeisenbank (Gf P2)',
  '71090000': 'Volksbank Raiffeisenbank Oberbayern Südost (Gf P2)',
  '71120077': 'UniCredit Bank - HypoVereinsbank',
  '71120078': 'UniCredit Bank - HypoVereinsbank',
  '71121176': 'UniCredit Bank - HypoVereinsbank',
  '71122183': 'UniCredit Bank - HypoVereinsbank',
  '71140041': 'Commerzbank Rosenheim',
  '71141041': 'Commerzbank',
  '71142041': 'Commerzbank',
  '71150000': 'Sparkasse Rosenheim-Bad Aibling',
  '71151020': 'Sparkasse Altötting-Mühldorf',
  '71152570': 'Kreissparkasse Miesbach-Tegernsee',
  '71152680': 'Kreis- und Stadtsparkasse Wasserburg',
  '71160000': 'meine Volksbank Raiffeisenbank (Gf P2)',
  '71160161': 'VR Bank Rosenheim-Chiemsee (Gf P2)',
  '71161964': 'Volksbank-Raiffeisenbank Chiemsee -alt- (Gf P2)',
  '71162355': 'Raiffeisenbank Oberaudorf (Gf P2)',
  '71162804': 'Raiffeisenbank Aschau-Samerberg (Gf P2)',
  '71165150': 'Raiffeisenbank Mangfalltal -alt-',
  '71180005': 'Commerzbank vormals Dresdner Bank',
  '71190000': 'Volksbank Rosenheim -alt-',
  '71191000': 'VR-Bank Burghausen-Mühldorf (Gf P2)',
  '72000000': 'Bundesbank',
  '72012300': 'Bank für Tirol und Vorarlberg Deutschland',
  '72020070': 'UniCredit Bank - HypoVereinsbank',
  '72020700': 'Augsburger Aktienbank',
  '72021271': 'UniCredit Bank - HypoVereinsbank',
  '72021876': 'UniCredit Bank - HypoVereinsbank',
  '72030014': 'Fürst Fugger Privatbank',
  '72030227': 'Bankhaus Anton Hafner (Gf P2)',
  '72030260': 'Bankhaus Anton Hafner (Gf PayCenter)',
  '72040046': 'Commerzbank',
  '72050000': 'Stadtsparkasse Augsburg',
  '72050101': 'Kreissparkasse Augsburg',
  '72051210': 'Sparkasse Aichach-Schrobenhausen',
  '72051840': 'Sparkasse Günzburg-Krumbach',
  '72062152': 'VR-Bank Handels- und Gewerbebank (Gf P2)',
  '72069002': 'Raiffeisenbank Adelzhausen-Sielenbach (Gf P2)',
  '72069005': 'Raiffeisenbank Aindling Zw Alsmoos-Petersdorf',
  '72069034': 'Raiffeisenbank (Gf P2)',
  '72069036': 'Raiffeisenbank Bobingen (Gf P2)',
  '72069043': 'VR-Bank Donau-Mindel (Gf P2)',
  '72069105': 'Raiffeisenbank Hiltenfingen zgl f Ndl Gennach',
  '72069113': 'Raiffeisenbank Aschberg (Gf P2)',
  '72069114': 'Raiffeisenbank (Gf P2)',
  '72069119': 'Raiffeisenbank (Gf P2)',
  '72069123': 'Raiffeisenbank Jettingen-Scheppach (Gf P2)',
  '72069126': 'Raiffeisenbank Mittelschwaben (Gf P2)',
  '72069132': 'Raiffeisenbank Krumbach/Schwaben (Gf P2)',
  '72069135': 'Raiffeisenbank Stauden (Gf P2)',
  '72069155': 'Raiffeisenbank Wittelsbacher Land (Gf P2)',
  '72069179': 'Raiffeisenbank Unteres Zusamtal (Gf P2)',
  '72069181': 'Raiffeisenbank (Gf P2)',
  '72069193': 'Raiffeisenbank (Gf P2)',
  '72069209': 'Raiffeisenbank (Gf P2)',
  '72069220': 'Raiffeisenbank Schwabmünchen-Stauden (Gf P2)',
  '72069235': 'Raiffeisenbank Thannhausen',
  '72069263': 'Raiffeisenbank Wittislingen (Gf P2)',
  '72069274': 'Raiffeisenbank Augsburger Land West (Gf P2)',
  '72069308': 'Raiffeisen-Volksbank Wemding (Gf P2)',
  '72069329': 'Raiffeisen-Volksbank Ries (Gf P2)',
  '72069736': 'Raiffeisenbank Schwaben Mitte (Gf P2)',
  '72069789': 'Raiffeisenbank Pfaffenhausen (Gf P2)',
  '72070001': 'Deutsche Bank',
  '72070024': 'Deutsche Bank',
  '72070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '72070365': 'Postbank/DSL Ndl der Deutsche Bank',
  '72080001': 'Commerzbank vormals Dresdner Bank',
  '72090000': 'VR Bank Augsburg-Ostallgäu (Gf P2)',
  '72090500': 'Sparda-Bank Augsburg',
  '72090900': 'PSD Bank München (Gf P2)',
  '72091800': 'Volksbank Günzburg (Gf P2)',
  '72120078': 'UniCredit Bank - HypoVereinsbank',
  '72122181': 'UniCredit Bank - HypoVereinsbank',
  '72140052': 'Commerzbank',
  '72150000': 'Sparkasse Ingolstadt Eichstätt',
  '72151340': 'Sparkasse Eichstätt -alt-',
  '72151650': 'Sparkassen Pfaffenhofen',
  '72151880': 'Sparkasse Aichach-Schrobenhausen -alt-',
  '72152070': 'Sparkasse Neuburg-Rain',
  '72160818': 'Volksbank Raiffeisenbank Bayern Mitte (Gf P2)',
  '72169013': 'Raiffeisenbank Aresing-Hörzhausen-Schiltberg (Gf P2)',
  '72169080': 'Raiffeisenbank Aresing-Gerolsbach (GF P2)',
  '72169218': 'Schrobenhausener Bank (Gf P2)',
  '72169246': 'Raiffeisenbank Schrobenhausener Land (Gf P2)',
  '72169380': 'Raiffeisenbank Beilngries (Gf P2)',
  '72169745': 'Raiffeisenbank Ehekirchen-Weidorf (Gf P2)',
  '72169756': 'VR Bank Neuburg-Rain (Gf P2)',
  '72169764': 'Raiffeisenbank Donaumooser Land (Gf P2)',
  '72169812': 'Raiffeisenbank im Donautal (Gf P2)',
  '72169831': 'Raiffeisenbank Riedenburg-Lobsing (Gf P2)',
  '72170007': 'Deutsche Bank',
  '72170024': 'Deutsche Bank',
  '72170324': 'Postbank/DSL Ndl der Deutsche Bank',
  '72170363': 'Postbank/DSL Ndl der Deutsche Bank',
  '72180002': 'Commerzbank vormals Dresdner Bank',
  '72191600': 'Hallertauer Volksbank (Gf P2)',
  '72220074': 'UniCredit Bank - HypoVereinsbank',
  '72223182': 'UniCredit Bank - HypoVereinsbank',
  '72250000': 'Sparkasse Nördlingen -alt-',
  '72250160': 'Sparkasse Donauwörth',
  '72251520': 'Sparkasse Dillingen-Nördlingen',
  '72261754': 'Raiffeisenbank Rain am Lech (Gf P2)',
  '72262401': 'Raiffeisen-Volksbank Dillingen (Gf P2)',
  '72290100': 'Raiffeisen-Volksbank Donauwörth (Gf P2)',
  '73050000': 'Sparkasse Neu-Ulm-Illertissen',
  '73061191': 'VR-Bank Neu-Ulm (Gf P2)',
  '73090000': 'Volksbank Neu-Ulm (Gf P2)',
  '73120075': 'UniCredit Bank - HypoVereinsbank',
  '73140046': 'Commerzbank Memmingen',
  '73150000': 'Sparkasse Schwaben-Bodensee',
  '73160000': 'Genossenschaftsbank Unterallgäu (Gf P2)',
  '73180011': 'Commerzbank vormals Dresdner Bank',
  '73190000': 'VR-Bank Memmingen (Gf P2)',
  '73191500': 'Volksbank Ulm-Biberach -alt-',
  '73311600': 'Hypo Vorarlberg Bank',
  '73320073': 'UniCredit Bank - HypoVereinsbank',
  '73321177': 'UniCredit Bank - HypoVereinsbank',
  '73322380': 'UniCredit Bank - HypoVereinsbank',
  '73331700': 'Gabler Saliter Bankgeschäft (GF P3)',
  '73340046': 'Commerzbank Kempten Allgäu',
  '73350000': 'Sparkasse Allgäu',
  '73369264': 'Raiffeisenbank im Allgäuer Land (Gf P2)',
  '73369821': 'BodenseeBank (Gf P2)',
  '73369823': 'Raiffeisenbank Westallgäu (Gf P2)',
  '73369826': 'Volksbank Lindenberg',
  '73369851': 'Raiffeisenbank Aitrang-Ruderatshofen (Gf P2)',
  '73369854': 'Raiffeisenbank Fuchstal-Denklingen (Gf P2)',
  '73369859': 'Raiffeisenbank Bidingen (Gf P2)',
  '73369871': 'Raiffeisenbank Baisweil-Eggenthal-Friesenried (Gf P2)',
  '73369902': 'Raiffeisenbank (Gf P2)',
  '73369918': 'Raiffeisenbank Kirchweihtal (Gf P2)',
  '73369920': 'Raiffeisenbank Kempten-Oberallgäu (Gf P2)',
  '73369933': 'Raiffeisenbank Südliches Ostallgäu (Gf P2)',
  '73369936': 'Raiffeisenbank Seeg -alt-',
  '73369954': 'Raiffeisenbank Wald-Görisried (Gf P2)',
  '73370008': 'Deutsche Bank',
  '73370024': 'Deutsche Bank',
  '73380004': 'Commerzbank vormals Dresdner Bank',
  '73390000': 'Allgäuer Volksbank Kempten-Sonthofen Zw',
  '73392000': 'Volksbank Immenstadt (Gf P2)',
  '73420071': 'UniCredit Bank - HypoVereinsbank',
  '73421478': 'UniCredit Bank - HypoVereinsbank',
  '73440048': 'Commerzbank',
  '73450000': 'Kreis- und Stadtsparkasse Kaufbeuren',
  '73451450': 'Kreissparkasse Schongau -alt-',
  '73460046': 'VR Bank Augsburg-Ostallgäu (Gf P2)',
  '73480013': 'Commerzbank vormals Dresdner Bank',
  '74020074': 'UniCredit Bank - HypoVereinsbank',
  '74020100': 'Raiffeisenlandesbank OÖ Zndl Süddeutschland',
  '74020150': 'Raiffeisenlandesbank OÖ Zndl Südde - für interne Zwecke',
  '74040082': 'Commerzbank Passau',
  '74050000': 'Sparkasse Passau',
  '74051230': 'Sparkasse Freyung-Grafenau',
  '74061101': 'Raiffeisenbank Am Goldenen Steig Gs Haus, Wald',
  '74061564': 'Raiffeisenbank Unteres Inntal Zw Würding',
  '74061670': 'Raiffeisenbank Ortenburg Zw Sandbach',
  '74061813': 'VR-Bank Rottal-Inn (Gf P2)',
  '74061814': 'VR-Bank Rottal-Inn Gf GAA',
  '74062490': 'Volksbank - Raiffeisenbank Vilshofen (GF P2)',
  '74062786': 'Raiffeisenbank i Lkr Passau-Nord (Gf P2)',
  '74064593': 'Raiffeisenbank Wegscheid',
  '74065782': 'Raiffeisenbank Salzweg-Thyrnau (Gf P2)',
  '74066749': 'Raiffeisenbank Südl. Bayerischer Wald (Gf P2)',
  '74067000': 'Rottaler Raiffeisenbank (Gf P2)',
  '74069744': 'Raiffeisenbank (Gf P2)',
  '74069752': 'Raiffeisenbank (Gf P2)',
  '74069758': 'Raiffeisenbk Kirchbg v Wald Zw Passau-Schaldg',
  '74069768': 'Raiffeisenbank am Dreisessel (Gf P2)',
  '74090000': 'VR-Bank Passau (Gf P2)',
  '74092400': 'Volksbank Vilshofen (Gf P2)',
  '74120071': 'UniCredit Bank - HypoVereinsbank',
  '74131000': 'TEBA Kreditbank',
  '74140048': 'Commerzbank',
  '74150000': 'Sparkasse Deggendorf',
  '74151450': 'Sparkasse Regen-Viechtach',
  '74160025': 'Raiffeisenbank Deggendorf-Plattling-Sonnenwald (Gf P2)',
  '74161608': 'Raiffeisenbank Hengersberg-Schöllnach (Gf P2)',
  '74164149': 'VR-Bank (Gf P2)',
  '74165013': 'Raiffeisenbank Sonnenwald (Gf P2)',
  '74180009': 'Commerzbank vormals Dresdner Bank',
  '74190000': 'VR GenoBank DonauWald (Gf P2)',
  '74191000': 'VR-Bank Landau-Mengkofen (Gf P2)',
  '74220075': 'UniCredit Bank - HypoVereinsbank',
  '74221170': 'UniCredit Bank - HypoVereinsbank',
  '74240062': 'Commerzbank Straubing',
  '74250000': 'Sparkasse Niederbayern-Mitte',
  '74251020': 'Sparkasse im Landkreis Cham',
  '74260110': 'Raiffeisenbank Straubing (Gf P2)',
  '74261024': 'Raiffeisenbank Chamer Land (Gf P2)',
  '74290000': 'VR-Bank Ostbayern-Mitte (Gf P2)',
  '74290100': 'CB Bank',
  '74320073': 'UniCredit Bank - HypoVereinsbank',
  '74340077': 'Commerzbank',
  '74350000': 'Sparkasse Landshut',
  '74351430': 'Sparkasse Rottal-Inn',
  '74351740': 'Stadt- und Kreissparkasse Moosburg -alt-',
  '74361211': 'Raiffeisenbank Arnstorf (Gf P2)',
  '74362663': 'Raiffeisenbank Landshuter Land (Gf P2)',
  '74364689': 'Raiffeisenbank Pfeffenhausen-Rottenburg-Wildenberg (Gf P2)',
  '74366666': 'Raiffeisenbank Geisenhausen (Gf P2)',
  '74369068': 'Raiffeisenbank Hofkirchen-Bayerbach (Gf P2)',
  '74369088': 'Raiffeisenbank Geiselhöring-Pfaffenberg (Gf P2)',
  '74369130': 'Raiffeisenbank (Gf P2)',
  '74369146': 'Raiffeisenbank Rattiszell-Konzell (Gf P2)',
  '74369656': 'Raiffeisenbank Essenbach (Gf P2)',
  '74369662': 'Raiffeisenbank Buch-Eching (Gf P2)',
  '74369704': 'Raiffeisenbank Mengkofen-Loiching (Gf P2)',
  '74380007': 'Commerzbank vormals Dresdner Bank',
  '74390000': 'VR-Bank Landshut (Gf P2)',
  '74391300': 'Volksbank-Raiffeisenbank Dingolfing (Gf P2)',
  '74391400': 'VR-Bank Rottal-Inn (Gf P2)',
  '74392300': 'VR-Bank Isar-Vils (Gf P2)',
  '75000000': 'Bundesbank',
  '75020073': 'UniCredit Bank - HypoVereinsbank',
  '75021174': 'UniCredit Bank - HypoVereinsbank',
  '75040062': 'Commerzbank Regensburg',
  '75050000': 'Sparkasse Regensburg',
  '75051040': 'Sparkasse im Landkreis Schwandorf',
  '75051565': 'Kreissparkasse Kelheim',
  '75060150': 'Raiffeisenbank Regensburg-Wenzenbach (Gf P2)',
  '75061168': 'Raiffeisenbank Schwandorf-Nittenau (Gf P2)',
  '75061851': 'Raiffeisenbank (Gf P2)',
  '75062026': 'Raiffeisenbank Oberpfalz Süd (Gf P2)',
  '75069014': 'Raiffeisenbank Kreis Kelheim (Gf P2)',
  '75069015': 'Raiffeisenbank Bad Gögging (Gf P2)',
  '75069020': 'Raiffeisenbank Bruck (Gf P2)',
  '75069038': 'Raiffeisenbank Falkenstein-Wörth Zw Süssenbach',
  '75069050': 'Raiffeisenbank Grafenwöhr-Kirchenthumbach (Gf P2)',
  '75069055': 'Raiffeisenbank Alteglofsheim-Hagelstadt (Gf P2)',
  '75069061': 'Raiffeisenbank im Oberpfälzer Jura (Gf P2)',
  '75069078': 'Raiffeisenbank Sinzing Gs Gundelshausen',
  '75069081': 'Raiffeisenbank Bad Kötzting (Gf P2)',
  '75069094': 'Raiffeisenbank Parsberg-Velburg (Gf P2)',
  '75069110': 'Raiffeisenbank Eschlkam-Lam-Lohberg-Neukirchen (Gf P2)',
  '75069171': 'VR Bank Mittlere Oberpfalz (Gf P2)',
  '75070013': 'Deutsche Bank',
  '75070024': 'Deutsche Bank',
  '75080003': 'Commerzbank vormals Dresdner Bank',
  '75090000': 'Volksbank Raiffeisenbank Regensburg-Schwandorf (Gf P2)',
  '75090300': 'LIGA Bank (Gf P2)',
  '75090500': 'Sparda-Bank Ostbayern',
  '75090900': 'VR Bank Niederbayern-Oberpfalz (Gf P2)',
  '75091400': 'VR Bank Burglengenfeld (Gf P2)',
  '75220070': 'UniCredit Bank - HypoVereinsbank',
  '75240000': 'Commerzbank',
  '75250000': 'Sparkasse Amberg-Sulzbach',
  '75261700': 'Raiffeisenbank Sulzbach-Rosenberg (Gf P2)',
  '75290000': 'VR Bank Amberg-Sulzbach (Gf P2)',
  '75320075': 'UniCredit Bank - HypoVereinsbank',
  '75340090': 'Commerzbank',
  '75350000': 'Sparkasse Oberpfalz Nord',
  '75351960': 'Vereinigte Sparkassen Eschenbach i d Opf',
  '75360011': 'Raiffeisenbank Weiden (Gf P2)',
  '75362039': 'Raiffeisenbank Floß (Gf P2)',
  '75363189': 'Raiffeisenbank Neustadt-Vohenstrauß (Gf P2)',
  '75390000': 'Volksbank Raiffeisenbank Nordoberpfalz (Gf P2)',
  '76000000': 'Bundesbank',
  '76010085': 'Postbank Ndl der Deutsche Bank',
  '76020070': 'UniCredit Bank - HypoVereinsbank',
  '76020099': 'UniCredit Bank - HypoVereinsbank Prepaid Card',
  '76026000': 'norisbank Gf P2',
  '76030080': 'BNP Paribas Niederlassung Deutschland',
  '76030600': 'Isbank Fil Nürnberg',
  '76030800': 'flatexDEGIRO Bank',
  '76032000': 'TeamBank Nürnberg',
  '76032001': 'TeamBank Nürnberg GF Austria',
  '76035000': 'UmweltBank',
  '76040060': 'Commerzbank CC',
  '76040061': 'Commerzbank',
  '76040062': 'Commerzbank CC',
  '76040065': 'Commerzbank, Filiale Nürnberg 2',
  '76050000': 'Bayerische Landesbank',
  '76050101': 'Sparkasse Nürnberg',
  '76052080': 'Sparkasse Neumarkt i d OPf-Parsberg',
  '76060000': 'DZ BANK',
  '76060618': 'Volksbank Raiffeisenbank (Gf P2)',
  '76060620': 'Volksbank Raiffeisenbank Nürnberg (Gf GAA)',
  '76061025': 'Raiffeisen Spar+Kreditbank Zw Schnaittach',
  '76061482': 'Raiffeisenbank im Nürnberger Land (Gf P2)',
  '76069369': 'Raiffeisenbank Auerbach-Freihung (Gf P2)',
  '76069372': 'Raiffeisenbank Bad Windsheim (Gf P2)',
  '76069378': 'Raiffeisenbank (Gf P2)',
  '76069404': 'Raiffeisenbank Uehlfeld-Dachsbach (Gf P2)',
  '76069409': 'Raiffeisenbank (Gf P2)',
  '76069410': 'Raiffeisenbank Dietersheim und Umgebung (Gf P2)',
  '76069440': 'Raiffeisenbank Altdorf-Feucht (Gf P2)',
  '76069441': 'VR-Bank Feuchtwangen-Limes (Gf P2)',
  '76069448': 'Raiffeisenbank -alt-',
  '76069449': 'Raiffeisen - meine Bank (Gf P2)',
  '76069462': 'Raiffeisenbank Altmühl-Jura (Gf P2)',
  '76069468': 'Raiffeisenbank Weißenburg-Gunzenhausen (Gf P2)',
  '76069486': 'Raiffeisenbank Hirschau (Gf P2)',
  '76069512': 'Raiffeisenbank Knoblauchsland Nürnberg-Buch (Gf P2)',
  '76069553': 'Raiffeisenbank Neumarkt Zw Trautmannshofen',
  '76069559': 'VR Bank Metropolregion Nürnberg (Gf P2)',
  '76069564': 'Raiffeisenbank Oberferrieden-Burgthann (Gf P2)',
  '76069576': 'Raiffeisenbank Plankstetten (Gf P2)',
  '76069598': 'Raiffeisenbank Großhabersdorf-Roßtal (Gf P2)',
  '76069602': 'Raiffeisenbank DreiFranken (Gf P2)',
  '76069611': 'Raiffeisenbank Unteres Vilstal (Gf P2)',
  '76069635': 'Raiffeisenbank Ursensollen-Ammerthal-Hohenburg -alt-',
  '76069663': 'Raiffeisenbank Heilsbronn-Windsbach (Gf P2)',
  '76069669': 'Raiffeisenbank Bibertgrund (Gf P2)',
  '76070012': 'Deutsche Bank',
  '76070024': 'Deutsche Bank',
  '76070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '76070361': 'Postbank/DSL Ndl der Deutsche Bank',
  '76080040': 'Commerzbank vormals Dresdner Bank',
  '76080053': 'Commerzbank vormals Dresdner Bank Zw 53',
  '76080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '76080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '76080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '76089480': 'Commerzbank vormals Dresdner Bank ITGK',
  '76089482': 'Commerzbank vormals Dresdner Bank ITGK',
  '76090400': 'Evenord-Bank (Gf P2)',
  '76090500': 'Sparda-Bank Nürnberg',
  '76090900': 'PSD Bank (Gf P2)',
  '76211900': 'CVW-Privatbank (Gf P2)',
  '76220073': 'UniCredit Bank - HypoVereinsbank',
  '76230000': 'BSQ Bauspar',
  '76240011': 'Commerzbank',
  '76250000': 'Sparkasse Fürth',
  '76251020': 'Sparkasse im Landkreis Neustadt a.d. Aisch - Bad Windsheim',
  '76260451': 'Raiffeisen-Volksbank Fürth (Gf P2)',
  '76320072': 'UniCredit Bank - HypoVereinsbank',
  '76340061': 'Commerzbank Erlangen',
  '76350000': 'Stadt- u. Kreissparkasse Erlangen Höchstadt Herzogenaurach',
  '76351040': 'Sparkasse Forchheim',
  '76351560': 'Kreissparkasse Höchstadt',
  '76360033': 'VR-Bank Erlangen-Höchstadt-Herzogenaurach (Gf P2)',
  '76391000': 'VR Bank Bamberg-Forchheim Volks- Raiffeisenbank (Gf P2)',
  '76420080': 'UniCredit Bank - HypoVereinsbank',
  '76450000': 'Sparkasse Mittelfranken-Süd',
  '76460015': 'Raiffeisenbank Roth-Schwabach (Gf P2)',
  '76461485': 'Raiffeisenbank am Rothsee (Gf P2)',
  '76520071': 'UniCredit Bank - HypoVereinsbank',
  '76550000': 'Sparkasse Ansbach',
  '76551020': 'Kreis- und Stadtsparkasse Dinkelsbühl -alt-',
  '76551540': 'Vereinigte Sparkassen Gunzenhausen',
  '76551860': 'Stadt- und Kreissparkasse Rothenburg -alt-',
  '76560060': 'VR-Bank Mittelfranken Mitte (Gf P2)',
  '76591000': 'VR-Bank Feuchtwangen-Dinkelsbühl (Gf P2)',
  '77020070': 'UniCredit Bank - HypoVereinsbank',
  '77040080': 'Commerzbank Bamberg',
  '77050000': 'Sparkasse Bamberg',
  '77060100': 'VR Bank Bamberg Raiffeisen-Volksbank (Gf P2)',
  '77061004': 'Raiffeisenbank Obermain Nord (Gf P2)',
  '77061425': 'Raiffeisen-Volksbank (Gf P2)',
  '77062014': 'Raiffeisenbank Burgebrach-Stegaurach (Gf P2)',
  '77062139': 'Raiffeisen-Volksbank Bad Staffelstein (Gf P2)',
  '77069044': 'Raiffeisenbank Küps-Mitwitz-Stockheim (Gf P2)',
  '77069051': 'Raiffeisenbank (Gf P2)',
  '77069052': 'Raiffeisenbank (Gf P2)',
  '77069091': 'Raiffeisenbank Ebrachgrund (Gf P2)',
  '77069461': 'Vereinigte Raiffeisenbanken (Gf P2)',
  '77069739': 'Raiffeisenbank Thurnauer Land (Gf P2)',
  '77069746': 'Raiffeisenbank (Gf P2)',
  '77069764': 'Raiffeisenbank Oberpfalz NordWest (Gf P2)',
  '77069782': 'Raiffeisenbank am Kulm (Gf P2)',
  '77069836': 'Raiffeisenbank Berg-Bad Steben (Gf P2)',
  '77069868': 'Raiffeisenbank Oberland (Gf P2)',
  '77069870': 'Raiffeisenbank Hochfranken West (Gf P2)',
  '77069906': 'Raiffeisenbank Wüstenselbitz (Gf P2)',
  '77069908': 'Raiffeisenbank Sparneck-Stammbach-Zell (Gf P2)',
  '77091800': 'VR-Bank Lichtenfels-Ebern (Gf P2)',
  '77120073': 'UniCredit Bank - HypoVereinsbank',
  '77140061': 'Commerzbank',
  '77150000': 'Sparkasse Kulmbach-Kronach',
  '77190000': 'VR Bank Oberfranken Mitte (Gf P2)',
  '77300000': 'Bundesbank eh Bayreuth',
  '77320072': 'UniCredit Bank - HypoVereinsbank',
  '77322200': 'Fondsdepot Bank',
  '77340076': 'Commerzbank',
  '77350110': 'Sparkasse Bayreuth',
  '77363749': 'Raiffeisenbank (Gf P2)',
  '77365792': 'Raiffeisenbank Fränkische Schweiz (Gf P2)',
  '78020070': 'UniCredit Bank - HypoVereinsbank',
  '78040081': 'Commerzbank',
  '78050000': 'Sparkasse Hochfranken',
  '78055050': 'Sparkasse Hochfranken -alt-',
  '78060896': 'VR Bank Bayreuth-Hof (Gf P2)',
  '78140000': 'Commerzbank',
  '78160069': 'VR-Bank Fichtelgebirge-Frankenwald (Gf P2)',
  '78161575': 'Raiffeisenbank im Stiftland HGs Tirschenreuth',
  '78320076': 'UniCredit Bank - HypoVereinsbank',
  '78340091': 'Commerzbank',
  '78350000': 'Sparkasse Coburg-Lichtenfels',
  '78360000': 'VR-Bank Coburg (Gf P2)',
  '79000000': 'Bundesbank',
  '79020076': 'UniCredit Bank - HypoVereinsbank',
  '79030001': 'Fürstlich Castellsche Bank (Gf P2)',
  '79032038': 'MERKUR PRIVATBANK(Gf P2)',
  '79040047': 'Commerzbank',
  '79050000': 'Sparkasse Mainfranken Würzburg',
  '79063060': 'Raiffeisenbank Estenfeld-Bergtheim (Gf P2)',
  '79063122': 'Raiffeisenbank Höchberg (Gf P2)',
  '79065028': 'VR-Bank Bad Kissingen (Gf P2)',
  '79069001': 'Raiffeisenbank Mainschleife - Steigerwald (Gf P2)',
  '79069010': 'VR-Bank Schweinfurt (Gf P2)',
  '79069031': 'Raiffeisenbank Bütthard-Gaukönigshofen (Gf P2)',
  '79069150': 'Raiffeisenbank Main-Spessart (Gf P2)',
  '79069165': 'VR-Bank Main-Rhön (Gf P2)',
  '79069181': 'Raiffeisenbank (Gf P2)',
  '79069188': 'Raiffeisenbank im Grabfeld (Gf P2)',
  '79069213': 'Raiffeisenbank Maßbach (Gf P2)',
  '79070016': 'Deutsche Bank',
  '79070024': 'Deutsche Bank',
  '79080052': 'Commerzbank vormals Dresdner Bank',
  '79080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '79090000': 'Volksbank Raiffeisenbank Würzburg (Gf P2)',
  '79161058': 'Raiffeisenbank Fränkisches Weinland (Gf P2)',
  '79161499': 'Raiffeisenbank Kitzinger Land (Gf P2)',
  '79190000': 'VR Bank Kitzingen (Gf P2)',
  '79320075': 'UniCredit Bank - HypoVereinsbank',
  '79330111': 'Bankhaus Max Flessa (Gf P2)',
  '79340054': 'Commerzbank',
  '79350000': 'Städtische Sparkasse Schweinfurt -alt-',
  '79350101': 'Sparkasse Schweinfurt-Haßberge',
  '79351010': 'Sparkasse Bad Kissingen',
  '79351730': 'Sparkasse Ostunterfranken',
  '79353090': 'Sparkasse Bad Neustadt a d Saale',
  '79362081': 'VR-MainBank (Gf P2)',
  '79363016': 'Volksbank Raiffeisenbank Rhön-Grabfeld (Gf P2)',
  '79363151': 'Raiffeisen-Volksbank Haßberge (Gf P2)',
  '79364069': 'Raiffeisenbank Frankenwinheim und Umgebung (Gf P2)',
  '79380051': 'Commerzbank vormals Dresdner Bank',
  '79520070': 'UniCredit Bank - HypoVereinsbank',
  '79540049': 'Commerzbank',
  '79550000': 'Sparkasse Aschaffenburg Alzenau',
  '79561348': 'Raiffeisenbank Bachgau (Gf P2)',
  '79562514': 'Raiffeisen-Volksbank Aschaffenburg (Gf P2)',
  '79565568': 'Raiffeisenbank Waldaschaff-Heigenbrücken (Gf P2)',
  '79567531': 'VR-Bank (Gf P2)',
  '79568518': 'Raiffeisenbank Haibach-Obernau (Gf P2)',
  '79570024': 'Deutsche Bank',
  '79570051': 'Deutsche Bank',
  '79570324': 'Postbank/DSL Ndl der Deutsche Bank',
  '79570364': 'Postbank/DSL Ndl der Deutsche Bank',
  '79580099': 'Commerzbank vormals Dresdner Bank',
  '79589402': 'Commerzbank vormals Dresdner Bank ITGK',
  '79590000': 'Volksbank Aschaffenburg (Gf P2)',
  '79650000': 'Sparkasse Miltenberg-Obernburg',
  '79665540': 'Raiffeisenbank Elsavatal (Gf P2)',
  '79666548': 'Raiffeisenbank Großostheim-Obernburg (Gf P2)',
  '79668509': 'Raiffeisenbank Eichenbühl und Umgebung (Gf P2)',
  '79690000': 'Raiffeisen-Volksbank Miltenberg (Gf P2)',
  '80020086': 'UniCredit Bank - HypoVereinsbank',
  '80020087': 'UniCredit Bank - HypoVereinsbank',
  '80040000': 'Commerzbank',
  '80050500': 'Kreissparkasse Merseburg-Querfurt -alt-',
  '80053000': 'Sparkasse Burgenlandkreis',
  '80053572': 'Stadtsparkasse Dessau',
  '80053622': 'Kreissparkasse Köthen -alt-',
  '80053722': 'Kreissparkasse Anhalt-Bitterfeld',
  '80053762': 'Saalesparkasse',
  '80054000': 'Kreissparkasse Weißenfels -alt-',
  '80055008': 'Sparkasse Mansfeld-Südharz',
  '80055500': 'Salzlandsparkasse',
  '80062608': 'Volksbank Elsterland (Gf P2)',
  '80063508': 'Harzer Volksbank (Gf P2)',
  '80063558': 'Volksbank (Gf P2)',
  '80063598': 'Volksbank Wittenberg (Gf P2)',
  '80063628': 'Volksbank (Gf P2)',
  '80063648': 'Volks- und Raiffeisenbank Saale-Unstrut (Gf P2)',
  '80063678': 'VR-Bank Zeitz (Gf P2)',
  '80063718': 'Volks- und Raiffeisenbank Eisleben (Gf P2)',
  '80080000': 'Commerzbank vormals Dresdner Bank',
  '80093574': 'Volksbank Dessau-Anhalt (Gf P2)',
  '80093784': 'Volksbank Halle, Saale (Gf P2)',
  '80550101': 'Sparkasse Wittenberg',
  '80550200': 'Kreissparkasse Anhalt-Zerbst -alt-',
  '81000000': 'Bundesbank',
  '81010400': 'Investitionsbank Sachsen-Anhalt',
  '81020500': 'Bank für Sozialwirtschaft',
  '81040000': 'Commerzbank',
  '81050555': 'Kreissparkasse Stendal',
  '81052000': 'Harzsparkasse',
  '81053272': 'Sparkasse MagdeBurg',
  '81054000': 'Sparkasse Jerichower Land -alt-',
  '81055000': 'Kreissparkasse Börde',
  '81055555': 'Sparkasse Altmark West',
  '81063028': 'Raiffeisenbank Kalbe-Bismark Kalbe',
  '81063238': 'Volksbank Jerichower Land (Gf P2)',
  '81068106': 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
  '81069052': 'Volksbank Börde-Bernburg (Gf P2)',
  '81070000': 'Deutsche Bank',
  '81070024': 'Deutsche Bank',
  '81080000': 'Commerzbank vormals Dresdner Bank',
  '81093034': 'Volksbank (Gf P2)',
  '81093054': 'Volksbank Stendal (Gf P2)',
  '81093274': 'Volksbank Magdeburg (Gf P2)',
  '82000000': 'Bundesbank',
  '82020086': 'UniCredit Bank - HypoVereinsbank',
  '82020087': 'UniCredit Bank - HypoVereinsbank',
  '82020088': 'UniCredit Bank - HypoVereinsbank',
  '82040000': 'Commerzbank',
  '82040085': 'Commerzbank, Gf Web-K',
  '82050000': 'Landesbank Hessen-Thür Girozentrale Erfurt',
  '82051000': 'Sparkasse Mittelthüringen',
  '82052020': 'Kreissparkasse Gotha',
  '82054052': 'Kreissparkasse Nordhausen',
  '82055000': 'Kyffhäusersparkasse',
  '82056060': 'Sparkasse Unstrut-Hainich',
  '82057070': 'Kreissparkasse Eichsfeld',
  '82060197': 'Pax-Bank',
  '82064038': 'VR Bank Westthüringen (Gf P2)',
  '82064088': 'VR Bank Ihre Heimatbank (Gf P2)',
  '82064168': 'Raiffeisenbank Gotha (Gf P2)',
  '82064188': 'VR Bank Weimar (Gf P2)',
  '82064228': 'Erfurter Bank (Gf P2)',
  '82070000': 'Deutsche Bank',
  '82070024': 'Deutsche Bank',
  '82070324': 'Postbank/DSL Ndl der Deutsche Bank',
  '82070366': 'Postbank/DSL Ndl der Deutsche Bank',
  '82080000': 'Commerzbank vormals Dresdner Bank',
  '82094004': 'Volksbank Heiligenstadt (Gf P2)',
  '82094054': 'Nordthüringer Volksbank (Gf P2)',
  '83020086': 'UniCredit Bank - HypoVereinsbank',
  '83020087': 'UniCredit Bank - HypoVereinsbank',
  '83020088': 'UniCredit Bank - HypoVereinsbank',
  '83040000': 'Commerzbank',
  '83050000': 'Sparkasse Gera-Greiz Gs',
  '83050200': 'Sparkasse Altenburger Land Gs',
  '83050303': 'Kreissparkasse Saalfeld-Rudolstadt Zw',
  '83050505': 'Kreissparkasse Saale-Orla Gs',
  '83053030': 'Sparkasse Jena-Saale-Holzland Gs',
  '83064488': 'Raiffeisen-Volksbank Hermsdorfer Kreuz Zw',
  '83064568': 'Geraer Bank (Gf P2)',
  '83065408': 'VR-Bank Altenburger Land / Skatbank (Gf P2)',
  '83080000': 'Commerzbank vormals Dresdner Bank',
  '83094444': 'Raiffeisen-Volksbank Saale-Orla (Gf P2)',
  '83094454': 'Volksbank Gera-Jena-Rudolstadt (Gf P2)',
  '83094494': 'Volksbank Eisenberg Zw',
  '83094495': 'EthikBank, Zndl der Volksbank Eisenberg (Gf P2)',
  '84020087': 'UniCredit Bank - HypoVereinsbank',
  '84040000': 'Commerzbank',
  '84050000': 'Rhön-Rennsteig-Sparkasse',
  '84051010': 'Sparkasse Arnstadt-Ilmenau',
  '84054040': 'Kreissparkasse Hildburghausen',
  '84054722': 'Sparkasse Sonneberg',
  '84055050': 'Wartburg-Sparkasse',
  '84064798': 'Genobank Rhön-Grabfeld',
  '84069065': 'Raiffeisenbank Schleusingen (Gf P2)',
  '84080000': 'Commerzbank vormals Dresdner Bank',
  '84094754': 'VR-Bank Bad Salzungen Schmal (Gf P2)',
  '84094755': 'VR-Bank Bad Salzungen Schmalkalden GAA',
  '84094814': 'Volksbank Thüringen Mitte (Gf P2)',
  '85000000': 'Bundesbank eh Dresden',
  '85010500': 'Sächsische Aufbaubank -Förderbank-',
  '85020086': 'UniCredit Bank - HypoVereinsbank',
  '85020500': 'Bank für Sozialwirtschaft',
  '85040000': 'Commerzbank',
  '85040060': 'Commerzbank CC',
  '85040061': 'Commerzbank CC',
  '85050100': 'Sparkasse Oberlausitz-Niederschlesien',
  '85050300': 'Ostsächsische Sparkasse Dresden',
  '85055000': 'Sparkasse Meißen',
  '85060000': 'Volksbank Pirna (Gf P2)',
  '85065028': 'Raiffeisenbank Neustadt, Sachs (Gf P2)',
  '85080000': 'Commerzbank vormals Dresdner Bank',
  '85080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '85080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '85080200': 'Commerzbank vormals Dresdner Bank',
  '85089270': 'Commerzbank vormals Dresdner Bank ITGK',
  '85090000': 'Volksbank Dresden-Bautzen (Gf P2)',
  '85094984': 'Volksbank Riesa Gs',
  '85095004': 'Volksbank Raiffeisenbank Meißen Großenhain (Gf P2)',
  '85550000': 'Kreissparkasse Bautzen',
  '85590000': 'Volksbank Bautzen Zw',
  '85590100': 'Volksbank Löbau-Zittau (Gf P2)',
  '85591000': 'Volksbank Raiffeisenbank Niederschlesien (Gf P2)',
  '86000000': 'Bundesbank',
  '86010090': 'Postbank Ndl der Deutsche Bank',
  '86010424': 'Aareal Bank ehem. Filiale Leipzig',
  '86020086': 'UniCredit Bank - HypoVereinsbank',
  '86020500': 'Bank für Sozialwirtschaft',
  '86033300': 'Santander Consumer Bank',
  '86040000': 'Commerzbank',
  '86040060': 'Commerzbank CC',
  '86040061': 'Commerzbank CC',
  '86050000': 'ZV Landesbank Baden-Württemberg',
  '86050200': 'Sparkasse Muldental',
  '86050600': 'Kreissparkasse Torgau-Oschatz -alt-',
  '86055002': 'Sparkasse Delitzsch-Eilenburg',
  '86055462': 'Kreissparkasse Döbeln',
  '86055592': 'Stadt- und Kreissparkasse Leipzig',
  '86065448': 'VR Bank Leipziger Land (Gf P2)',
  '86065468': 'VR-Bank Mittelsachsen (Gf P2)',
  '86065483': 'Raiffeisenbank Grimma (Gf P2)',
  '86069070': 'Raiffeisenbank (Gf P2)',
  '86070000': 'Deutsche Bank',
  '86070024': 'Deutsche Bank',
  '86070407': 'Postbank/DSL Ndl der Deutsche Bank',
  '86070424': 'Postbank/DSL Ndl der Deutsche Bank',
  '86080000': 'Commerzbank vormals Dresdner Bank',
  '86080055': 'Commerzbank vormals Dresdner Bank Zw 55',
  '86080057': 'Commerzbank vormals Dresdner Bank Gf ZW 57',
  '86080085': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
  '86080086': 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
  '86089280': 'Commerzbank vormals Dresdner Bank ITGK',
  '86095484': 'Volks- und Raiffeisenbank Muldental (Gf P2)',
  '86095554': 'Volksbank Delitzsch (Gf P2)',
  '86095604': 'Leipziger Volksbank (Gf P2)',
  '87000000': 'Bundesbank',
  '87020086': 'UniCredit Bank - HypoVereinsbank',
  '87020087': 'UniCredit Bank - HypoVereinsbank',
  '87020088': 'UniCredit Bank - HypoVereinsbank',
  '87040000': 'Commerzbank',
  '87050000': 'Sparkasse Chemnitz',
  '87051000': 'Sparkasse Mittelsachsen',
  '87052000': 'Sparkasse Mittelsachsen',
  '87054000': 'Erzgebirgssparkassee',
  '87055000': 'Sparkasse Zwickau',
  '87058000': 'Sparkasse Vogtland',
  '87069075': 'Volksbank Mittleres Erzgebirge (Gf P2)',
  '87069077': 'Vereinigte Raiffeisenbank Burgstädt (Gf P2)',
  '87070000': 'Deutsche Bank',
  '87070024': 'Deutsche Bank',
  '87070406': 'Postbank/DSL Ndl der Deutsche Bank',
  '87070424': 'Postbank/DSL Ndl der Deutsche Bank',
  '87080000': 'Commerzbank vormals Dresdner Bank',
  '87095824': 'Volksbank Vogtland-Saale-Orla (Gf P2)',
  '87095899': 'Volksbank Vogtland GAA',
  '87095934': 'Volksbank Zwickau (Gf P2)',
  '87095974': 'Volksbank-Raiffeisenbank Glauchau (Gf P2)',
  '87096124': 'Volksbank (Gf P2)',
  '87096214': 'Volksbank Chemnitz (Gf P2)',
};

export default BANK;
